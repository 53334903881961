import React, { useState } from 'react';
import Joyride from 'react-joyride';

import { Tabs } from 'components/ui';
import { AddPatientIcon } from 'assets/icons';
import { AppRoutes } from 'routes/app-routes';
import { useNavigate } from 'react-router-dom';
import { BaseButton } from 'components/ui/buttons';
import { useAuth } from 'contexts/AuthContextProvider';
import { Tooltip } from 'components/roster/TooltipTour';
import { Header } from 'components/details-pages/Header';
import { rosterTour, TourStepStatus } from 'types/tours';
import { PatientList } from 'components/roster/PatientList';
import { SearchInputField } from 'components/ui/form-fields';
import { SortBySelector } from 'components/ui/SortBySelector';
import { StartTourModal } from 'components/roster/StartTourModal';
import { LocalStorageService } from 'services/localStorageService';
import { rosterTourSteps } from 'components/roster/rosterTourSteps';
import { rosterSortByOptions, usePatientsData } from 'hooks/usePatientsData';

const MY_PATIENTS_TAB_INDEX = 1;

export const Roster: React.FC = () => {
  const tabTitles = ['All patients', 'My patients'];

  const [isShowTourModal, setIsShowTourModal] = useState<boolean>(
    LocalStorageService.getLocalStorage(rosterTour) === null,
  );
  const [isStartTour, setIsStartTour] = useState<boolean>(false);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const {
    setSearchValue,
    searchValue,
    sortBy,
    arePatientsEmpty,
    setSortBy,
    activePatients,
    notActivePatients,
    practitioners,
    fetch,
    submitting,
  } = usePatientsData();

  const handleTabChange = async (index: number) => {
    await fetch(index === MY_PATIENTS_TAB_INDEX);
    setActiveTabIndex(index);
  };

  const navigate = useNavigate();
  const { user, isLoading } = useAuth();

  const steps = rosterTourSteps.filter((step) => document.querySelector(step.target));

  const startTour = () => {
    setIsShowTourModal(false);
    setIsStartTour(true);
  };

  const skippedTour = () => {
    setIsStartTour(false);
    setIsShowTourModal(false);
    LocalStorageService.setLocalStorage(rosterTour, TourStepStatus.SKIPPED);
  };

  const patientListProps = {
    activePatients,
    notActivePatients,
    practitioners,
    search: searchValue,
    fetch,
    isPatientsEmpty: arePatientsEmpty,
    isSubmitting: submitting,
  };

  return (
    <div className="flex flex-col content-between">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <Header />
          <div className="flex flex-col content-between w-full max-w-[969px] mx-auto min-h-screen">
            <div className="py-6 px-4 sm:px-10">
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-6 sm:p-10 bg-sea-200 sm:bg-add-patient bg-center bg-no-repeat dark:sm:bg-add-patient-dark dark:sm:bg-eastern-blue w-full h-[180px] rounded-2xl">
                <div className="flex flex-row gap-4">
                  {user?.avatarUrl && (
                    <div className="flex w-16 h-16 justify-center items-center bg-white-400 dark:bg-white-400/[.05] rounded-2xl">
                      <img
                        src={user.avatarUrl}
                        alt="User avatar"
                        className="w-full h-full rounded-2xl"
                      />
                    </div>
                  )}
                  <div className="pb-[21px] sm:pb-0">
                    <h2 className="text-indigo text-[28px] font-semibold leading-[normal]">Hi, {user?.name}</h2>
                    <p className="text-indigo text-sm font-normal leading-[130%]">Track and assess your patients</p>
                  </div>
                </div>
                <div>
                  <BaseButton
                    className="add-patient w-full sm:w-auto"
                    scheme="cta-primary"
                    size="xl"
                    icon={AddPatientIcon}
                    isIconOnLeftSide
                    onClick={() => navigate(AppRoutes.Onboarding)}
                  >
                    Add patient
                  </BaseButton>
                </div>
              </div>

              <div className="flex w-full flex-col mt-6 items-start bg-white-400 shadow-section rounded-2xl overflow-hidden">
                <Tabs
                  titles={tabTitles}
                  contents={[<PatientList {...patientListProps} />, <PatientList {...patientListProps} />]}
                  activeTab={activeTabIndex}
                  setActiveTab={handleTabChange}
                  additionalControls={
                    <SortBySelector options={rosterSortByOptions} value={sortBy} onChange={setSortBy} />
                  }
                  searchSlot={
                    <SearchInputField
                      value={searchValue}
                      onChange={(event) => setSearchValue(event.target.value)}
                      clearSearchInput={() => setSearchValue('')}
                      placeholder="Search for patient"
                      isError={!!searchValue && arePatientsEmpty}
                    />
                  }
                />
              </div>
            </div>
          </div>

          <Joyride
            steps={steps}
            run={isStartTour}
            spotlightPadding={2}
            styles={{
              spotlight: {
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
              },
            }}
            tooltipComponent={(props) => (
              <Tooltip {...props} tourName={rosterTour} stepsCount={steps.length} skipTour={skippedTour} />
            )}
          />
          {isShowTourModal && (
            <StartTourModal
              title="This is your patient roster page."
              description="Since it's your first log-in, we'll show you around."
              onStartTourClick={startTour}
              onCancelClick={skippedTour}
            />
          )}
        </>
      )}
    </div>
  );
};
