import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { LocalStorageService } from 'services/localStorageService';

type ThemeContextType = {
  theme: string;
  toggleTheme: () => void;
};
type ThemeProviderProps = {
  children: ReactNode;
};

export const DARK_MODE_CLASS = 'dark';

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<string>('');

  useEffect(() => {
    const localTheme =
      LocalStorageService.getLocalStorage('theme') ||
      (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

    setTheme(localTheme);
    LocalStorageService.setLocalStorage('theme', localTheme);
  }, []);

  const toggleTheme = () => {
    setTheme((prevTheme) => {
      const changedTheme = prevTheme === 'light' ? 'dark' : 'light';
      LocalStorageService.setLocalStorage('theme', changedTheme);
      return changedTheme;
    });
  };

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
