import React from 'react';

import cn from 'classnames';
import parse from 'html-react-parser';

import { BaseButton } from 'components/ui/buttons';

type OptionType = { label: string; value: string };

type QuestionInfoProps = {
  title: string;
  description?: string | null;
  options?: OptionType[];
  selectedOption?: string;
  optional?: boolean;
  changeOption?: (value: string) => void;
};

export const QuestionInfo: React.FC<QuestionInfoProps> = ({
  title,
  description,
  options,
  selectedOption,
  optional,
  changeOption,
}) => (
  <div className="pb-6">
    <div className="flex flex-col md:flex-row items-start md:justify-between">
      <h2
        className={cn(
          'text-gray-900 dark:text-white-400 text-lg md:text-[28px] font-semibold leading-[normal]',
          {
            'mb-2 ': description,
          },
          options?.length ? 'max-w-[80%]' : '',
        )}
      >
        {parse(title)}
      </h2>
      {optional && <p className="text-gray-800 text-sm font-medium leading-[130%]">*Optional</p>}
      {options?.length && (
        <div className="flex items-center gap-2 mt-2 md:mt-0">
          {options.map((option, idx) => (
            <BaseButton
              key={`${option.value + idx}`}
              scheme="secondary"
              className={`hover:border-sea-serpent/50 ${option.value === selectedOption ? '!bg-mint/60 border-sea-serpent/50' : '!bg-transparent'}`}
              onClick={() => changeOption?.(option.value)}
            >
              {option.label}
            </BaseButton>
          ))}
        </div>
      )}
    </div>
    {description && (
      <p className="text-gray-500 dark:text-silver-400 text-sm font-medium leading-[130%]">{parse(description)}</p>
    )}
  </div>
);
