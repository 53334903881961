import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CloseIcon } from 'assets/icons';
import { IconButton } from 'components/ui/buttons';
import { useTheme } from 'contexts';
import cn from 'classnames';

type ModalScheme = 'primary' | 'secondary';

type BaseModalProps = {
  children: React.ReactNode;
  scheme?: ModalScheme;
  isShownCloseButton?: boolean;
  isUseOverflowHidden?: boolean;
  onCloseClick?: () => void;
};

const modalSchemeClassMap: Record<ModalScheme, string> = {
  primary: 'p-6 bg-white-400 border border-transparent dark:bg-gray-900 dark:border-gray-500 rounded-2xl',
  secondary: '',
};

export const BaseModal: React.FC<BaseModalProps> = ({
  children,
  scheme = 'primary',
  isUseOverflowHidden = true,
  onCloseClick,
  isShownCloseButton = false,
}) => {
  const typeClass = modalSchemeClassMap[scheme];
  const { theme } = useTheme();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return ReactDOM.createPortal(
    <div
      className={cn(
        'fixed z-10 inset-0 bg-gray-500/50 py-4 px-4 overflow-y-auto flex items-center justify-center min-h-screen text-center',
        theme === 'dark' ? 'dark' : '',
      )}
    >
      <div
        className={cn(typeClass, 'relative flex transform transition-all dark:shadow-dark-modal', {
          'overflow-hidden ': isUseOverflowHidden,
        })}
      >
        {isShownCloseButton && (
          <IconButton
            scheme={scheme === 'primary' ? 'transparent' : 'primary'}
            onClick={onCloseClick}
            className={cn(scheme === 'primary' ? 'absolute top-1 right-1' : 'absolute bottom-1 left-[45%]')}
            icon={CloseIcon}
            iconProps={{ className: '[&>g>path]:fill-gray-800' }}
          />
        )}
        {children}
      </div>
    </div>,
    document.body,
  );
};
