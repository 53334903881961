import dayjs from 'dayjs';
import { changeDirection } from 'types/assessment';

const MAX_HOURS_BEFORE_DAYS: number = 96;
const DAY_IN_HOURS: number = 24;

interface PatientRecordType {
  ref_id: number;
  id: string;
  assigned_user_first_name: string | null;
  assigned_user_id: string | null;
  assigned_user_last_name: string | null;
  datetime_of_birth: string | null;
  family_name: string;
  given_name: string;
  overall_score_from_before_last_assessment: number | null;
  overall_score_from_last_assessment: number | null;
  assessments_stopped_at: string | null;
}

export class PatientRecord {
  _record: any;
  refId: number;
  id: string | null;
  assignedUserFirstName: string | null;
  assignedUserId: string | null;
  assignedUserLastName: string | null;
  datetimeOfBirth: string;
  familyName: string;
  givenName: string;
  overallScoreFromBeforeLastAssessment: number | null;
  overallScoreFromLastAssessment: number | null;
  assessments_stopped_at: string | null;

  constructor(record: Partial<PatientRecordType> = {}) {
    this._record = record;
    this.refId = record.ref_id || 0;
    this.id = record.id || '';
    this.datetimeOfBirth = record.datetime_of_birth || dayjs().format();
    this.assignedUserFirstName = record.assigned_user_first_name || null;
    this.assignedUserId = record.assigned_user_id || null;
    this.assignedUserLastName = record.assigned_user_last_name || null;
    this.familyName = record.family_name || '';
    this.givenName = record.given_name || '';
    this.overallScoreFromBeforeLastAssessment = record.overall_score_from_before_last_assessment || null;
    this.overallScoreFromLastAssessment = record.overall_score_from_last_assessment || null;
    this.assessments_stopped_at = record.assessments_stopped_at || null;
  }

  getAge(): string {
    const currentDate = dayjs();
    const patientDate = dayjs(this.datetimeOfBirth);

    const diffInHours = currentDate.diff(patientDate, 'hour', true);
    const diffInHoursRounded = diffInHours.toFixed(2);

    if (diffInHours > MAX_HOURS_BEFORE_DAYS) {
      const diffInDays = Math.floor(diffInHours / DAY_IN_HOURS);
      const remainingHours = diffInHours % DAY_IN_HOURS;
      const remainingHoursRounded = remainingHours.toFixed(2);
      return `${diffInDays} days ${remainingHoursRounded} hours old`;
    } else {
      return `${diffInHoursRounded} hours old`;
    }
  }

  getAssessmentLevelDirection(): string | null {
    const { overallScoreFromBeforeLastAssessment, overallScoreFromLastAssessment } = this;

    if (overallScoreFromBeforeLastAssessment === null || overallScoreFromLastAssessment === null) {
      return null;
    }

    const isNoChange = overallScoreFromBeforeLastAssessment === overallScoreFromLastAssessment;
    const isIncrease = overallScoreFromBeforeLastAssessment > overallScoreFromLastAssessment;

    return isNoChange ? changeDirection.noChange : isIncrease ? changeDirection.increase : changeDirection.decrease;
  }
}
