import React from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { ActionModal } from 'components/ui';
import { PlayIcon, UndoIcon } from 'assets/icons';
import { BaseButton } from 'components/ui/buttons';
import { PatientRecord } from 'record/patient.record';
import { useRestorePatient } from 'hooks/useRestorePatient';
import { StabilityLevel } from 'components/ui/StabilityLevel';
import { AppRoutes, replaceRouteParamWithValue } from 'routes/app-routes';
import { StabilityIcon } from 'components/assessment-details/StabilityIcon';
import { useLoadingWithErrorHandling } from 'hooks/useLoadingWithErrorHandling';
import { StopAssessmentDate } from 'components/assessment/common/StopAssessmentDate';
import { AssignPatientButton } from 'components/assessment-details/AssignPatientButton';

type PatientCardProps = {
  patient: PatientRecord;
  isActive?: boolean;
  isYourPatient?: boolean;
  fetchPatients?: () => void;
};

export const PatientCard: React.FC<PatientCardProps> = ({ patient, isActive = true, isYourPatient, fetchPatients }) => {
  const { restorePatient, isShowRestoreModal, setIsShowRestoreModal, submitting } = useRestorePatient(
    patient,
    fetchPatients,
  );
  const { ErrorModalComponent } = useLoadingWithErrorHandling();
  const navigate = useNavigate();

  const routeToPatientAssessmentDetails = () => {
    if (!patient.id) return;

    const route = replaceRouteParamWithValue(AppRoutes.CurrentPatient, 'id', patient.id);
    navigate(route);
  };

  const handleRouteAssessment = () => {
    if (!patient.id) return;

    const route = replaceRouteParamWithValue(
      patient.overallScoreFromLastAssessment ? AppRoutes.CurrentPatient : AppRoutes.Assessment,
      'id',
      patient.id,
    );

    navigate(route, { state: { isCallAssessment: !!patient.overallScoreFromLastAssessment } });
  };

  return (
    <div className="w-full min-h-[100px] flex items-center justify-between py-3 px-4 bg-white-400 dark:bg-gray-900 border-b-[1px] last:border-none border-white-400/10">
      <div className="flex w-1/3 gap-2.5">
        <div className="flex w-12 h-12 justify-center items-center shrink-0 bg-white-500 dark:bg-white-400/[.05] rounded-2xl">
          <StabilityIcon levelDirection={patient.getAssessmentLevelDirection()} />
        </div>
        <div className="flex flex-col justify-center">
          <h3
            className={cn(
              'text-sm font-semibold leading-[140%]',
              isActive ? 'text-gray-900 dark:text-white-400' : 'text-sky dark:text-gray-800',
            )}
          >
            {patient.refId}
          </h3>
          <p className="text-sky dark:text-gray-800 text-xs font-medium leading-[17px]">{patient.getAge()}</p>
        </div>
      </div>

      {patient.overallScoreFromLastAssessment && (
        <div className="stability-level w-1/3">
          <StabilityLevel
            isActive={isActive}
            level={patient.overallScoreFromLastAssessment}
            levelDirection={patient.getAssessmentLevelDirection()}
          />
        </div>
      )}

      <div className="flex w-1/3 gap-3 justify-end">
        <BaseButton
          scheme="secondary"
          disabled={!patient.overallScoreFromLastAssessment}
          onClick={() => routeToPatientAssessmentDetails()}
        >
          Details
        </BaseButton>
        {isActive ? (
          isYourPatient ? (
            <BaseButton icon={PlayIcon} isIconOnLeftSide onClick={() => handleRouteAssessment()}>
              Start
            </BaseButton>
          ) : (
            <AssignPatientButton patient={patient} buttonScheme="stroke" afterAssign={fetchPatients} />
          )
        ) : (
          <BaseButton
            className="restore-patient border-2"
            scheme="secondary"
            onClick={() => setIsShowRestoreModal(true)}
            icon={UndoIcon}
            isIconOnLeftSide
          >
            Restore
          </BaseButton>
        )}
      </div>
      {isShowRestoreModal && (
        <ActionModal
          title={`Are you sure you want to restore patient ID: ${patient.refId}?`}
          description="After restoring, the patient will re-appear in your active roster."
          additionalComponent={<StopAssessmentDate date={patient.assessments_stopped_at} />}
          onCancelClick={() => setIsShowRestoreModal(false)}
          onSuccessClick={restorePatient}
          successText="Yes, restore and assign"
          isSubmitting={submitting}
        />
      )}

      {ErrorModalComponent}
    </div>
  );
};
