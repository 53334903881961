import React from 'react';
import { TooltipRenderProps } from 'react-joyride';

import { RightIcon } from 'assets/icons';
import { TourStepStatus } from 'types/tours';
import { BaseButton } from 'components/ui/buttons';
import { useTheme } from 'contexts/ThemeContextProvider';
import { LocalStorageService } from 'services/localStorageService';

type TooltipProps = {
  skipTour: () => void;
  stepsCount: number;
  tourName: string;
};

export const Tooltip: React.FC<TooltipRenderProps & TooltipProps> = ({
  index,
  step,
  primaryProps,
  tooltipProps,
  skipTour,
  stepsCount,
  tourName,
}) => {
  const { theme } = useTheme();
  const currentStep = index + 1;

  const handleNextClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (currentStep === stepsCount) {
      LocalStorageService.setLocalStorage(tourName, TourStepStatus.COMPLETED);
    }
    primaryProps.onClick(event);
  };

  return (
    <div className={theme}>
      <div
        className="flex flex-col bg-white-400 dark:bg-gray-900 dark:border-gray-500 p-6 rounded-2xl max-w-[345px]"
        {...tooltipProps}
      >
        {step.title && (
          <div className="text-gray-900 dark:text-white-400 text-base font-semibold leading-6">{step.title}</div>
        )}
        <div className="pt-3 text-gray-900 dark:text-gray-200 text-sm font-normal leading-[140%]">{step.content}</div>
        <div className="mt-6 pt-6 flex gap-4 border-t-[1px] border-gray-200 dark:border-gray-200/10 justify-between items-center">
          <div className="text-sky dark:text-gray-200 text-xs font-medium leading-[17px]">
            Step {currentStep} of {stepsCount}
          </div>
          {currentStep !== stepsCount && (
            <BaseButton scheme="secondary" onClick={skipTour}>
              Skip tour
            </BaseButton>
          )}
          <BaseButton
            scheme="primary"
            onClick={(event) => handleNextClick(event)}
            icon={currentStep !== stepsCount ? RightIcon : undefined}
            iconProps={{ className: 'w-[18px] h-[18px]' }}
          >
            {currentStep === stepsCount ? 'Complete' : 'Next'}
          </BaseButton>
        </div>
      </div>
    </div>
  );
};
