import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { RightIcon } from 'assets/icons';
import { BaseModal } from 'components/ui';
import { BaseButton } from 'components/ui/buttons';
import { InputField } from 'components/ui/form-fields';
import { AppRoutes, replaceRouteParamWithValue } from 'routes/app-routes';

type PatientIdFormModalProps = {
  onCancelClick: () => void;
};

export const PatientIdFormModal: React.FC<PatientIdFormModalProps> = ({ onCancelClick }) => {
  const [id, setId] = useState('');

  const navigate = useNavigate();

  const handleRouteToAssessment = () => {
    const route = replaceRouteParamWithValue(AppRoutes.Assessment, 'id', id);
    navigate(route);
  };

  return (
    <BaseModal isShownCloseButton onCloseClick={onCancelClick}>
      <div className="max-w-[500px] px-12 flex flex-col text-left">
        <h2 className="mb-3 flex gap-2 text-gray-900 dark:text-white-400 text-[23px] font-semibold leading-[140%]">
          To begin, please enter patient ID
        </h2>
        <InputField
          label="Patient ID"
          name="id"
          type="number"
          value={id}
          onChange={(event) => setId(event.target.value)}
        />

        <div className="flex w-full justify-end mt-5 gap-4">
          <BaseButton scheme="transparent" onClick={onCancelClick}>
            Cancel
          </BaseButton>
          <BaseButton icon={RightIcon} scheme="primary" disabled={!id} onClick={handleRouteToAssessment}>
            Next
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
};
