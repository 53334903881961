import React from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';

import { BaseButton } from 'components/ui/buttons';
import { dateTimeFormats } from 'types/date-time-formats';
import { AssessmentResultType, badStabilityLevelLowerBound } from 'types/assessment';

type PatientHistoryAssessmentType = {
  isLatest: boolean;
  isSelected: boolean;
  assessmentResult: AssessmentResultType;
  onChangeResult: (resultId: string) => void;
};

export const PatientHistoryAssessmentItem: React.FC<PatientHistoryAssessmentType> = ({
  isLatest,
  isSelected,
  assessmentResult,
  onChangeResult,
}) => {
  const isGoodStabilityLevel = () => {
    return assessmentResult.overall_score < badStabilityLevelLowerBound;
  };

  const stabilityLevelColor = isGoodStabilityLevel() ? 'green-400' : 'red-400';

  return (
    <div
      className={cn('flex items-center py-3 px-4', {
        'border-b border-gray-200 dark:border-white-400/10': !isSelected,
      })}
    >
      <div className="w-1/2 pl-4 flex items-center gap-4">
        <div className={cn('w-1 h-10 rounded-full', isSelected ? 'bg-teal-300' : 'bg-gray-200 dark:bg-gray-500')} />
        <div className="flex gap-[6px] items-start">
          <div className="flex flex-col gap-0.5">
            <p className="py-1 text-sm font-semibold leading-tight tracking-wide dark:text-white-400">
              {dayjs(assessmentResult.created).format(dateTimeFormats.historyAssessment)}
            </p>
            <p className="text-gray-600 dark:text-silver-400 text-xs font-medium leading-none">
              {assessmentResult.hours_old} hrs old
            </p>
          </div>
          {isLatest && (
            <div className="flex items-center h-6 px-2.5 py-1 text-xs text-green-800 dark:text-white-400 font-medium leading-none rounded-full bg-sea-serpent/25 dark:bg-sea-serpent/50">
              Last assessment
            </div>
          )}
        </div>
      </div>
      <div className="w-1/2 flex justify-between">
        <div
          className={cn(
            `w-[32px] h-[32px] flex items-center justify-center rounded-full border border-${stabilityLevelColor} text-${stabilityLevelColor} text-xs font-semibold leading-none`,
          )}
        >
          S{assessmentResult.overall_score}
        </div>
        {!isSelected && (
          <div className="flex w-[30%] justify-end">
            <BaseButton scheme="secondary" size="md" onClick={() => onChangeResult(assessmentResult.id)}>
              Details
            </BaseButton>
          </div>
        )}
      </div>
    </div>
  );
};
