import React from 'react';

import { BaseModal } from 'components/ui';
import { BaseButton } from 'components/ui/buttons';
import successImage from 'assets/images/bg_success_add.png';

type ThanksModalProps = {
  onStartAssessment: () => void;
  onCloseClick: () => void;
};

export const ThanksModal: React.FC<ThanksModalProps> = ({ onCloseClick, onStartAssessment }) => {
  return (
    <BaseModal isShownCloseButton onCloseClick={onCloseClick}>
      <div className="flex flex-col mt-4 items-center max-w-[500px] text-center">
        <img src={successImage} alt="stop-assessment" className="pb-4 h-auto max-w-[180px]" />
        <h2 className="mt-4 mb-1.5 text-gray-900 dark:text-white-400 text-center text-[19px] font-semibold leading-7">
          Thank you!
        </h2>
        <p className="text-gray-900 dark:text-white-400 text-center text-sm font-normal leading-[130%]">
          You can now begin to review the details of the patient's patient assessment.
        </p>
        <div>
          <BaseButton scheme="cta-primary" className="w-full mt-8 mb-2" size="xl" onClick={onStartAssessment}>
            Assessment details
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
};
