import React, { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'routes/app-routes';
import { BaseButton } from 'components/ui/buttons';
import { createNotification } from 'components/ui';
import { LockXLIcon, WifiOffIcon } from 'assets/icons';
import { LogoDarkIcon, LogoIcon } from 'assets/images';
import { InputField } from 'components/ui/form-fields';
import { useAuth } from 'contexts/AuthContextProvider';
import { useTenancy } from 'contexts/TenantContextProvider';
import { DARK_MODE_CLASS, useTheme } from 'contexts/ThemeContextProvider';
import { useUsersnapApi } from 'contexts/UsersnapContextProvider';
import { minAssessmentsForFirstSurvey } from 'types/assessment';
import { useNetworkCheck } from 'contexts/NetworkContextProvider';
import { getUserTotalCompletedAssessments, loginAuth } from 'services/api';
import { PatientIdFormModal } from 'components/offline/PatientIdFormModal';

type LoginFormValues = {
  id: string;
};

export const Login: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const [isShowPatientIdFormModal, setShowPatientIdFormModal] = useState(false);

  const { theme } = useTheme();
  const navigate = useNavigate();
  const { isOnline } = useNetworkCheck();
  const tenantKey = useTenancy();
  const usersnapContext = useUsersnapApi();
  const { token, setAuthToken, user } = useAuth();

  const validationSchema = Yup.object().shape({
    id: Yup.string().min(2, 'Id must be minimum 2').required('ID is required'),
  });

  useEffect(() => {
    if (token) {
      navigate(AppRoutes.Roster);
      createNotification({
        type: 'info',
        title: `You are signed in as ${user?.name}`,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, navigate]);

  const handleSubmit = async (values: LoginFormValues) => {
    if (!tenantKey) return;

    try {
      setSubmitting(true);

      const response = await loginAuth({ loginId: formik.values.id, tenantKey });
      await setAuthToken(response.data.token);
      await trySetUsersnapInitParams();

      setSubmitting(false);
    } catch (error: any) {
      const errorData = error.response?.data;
      if (errorData && errorData.status === 401) {
        createNotification({
          type: 'error',
          title: 'Authentication Error',
          description: 'You are not authorized. Please check your credentials and try again.',
        });
      } else {
        console.error('Failed to login', error);
      }
      await formik.setFieldValue('id', '');
      setSubmitting(false);
    }
  };

  const trySetUsersnapInitParams = async () => {
    if (!usersnapContext) return;

    try {
      const response = await getUserTotalCompletedAssessments();
      const qualifiesForFirstSurvey = response.data >= minAssessmentsForFirstSurvey;

      usersnapContext.setInitParams({
        user: { userId: String(user?.userId), qualifiesForFirstSurvey: qualifiesForFirstSurvey },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="w-screen bg-cyan">
      <div className="w-full min-h-dvh flex flex-col items-center mx-auto bg-login-pattern bg-no-repeat bg-contain bg-bottom">
        <div className="flex w-full h-[74px] mb-[39px] p-4 justify-center items-center bg-white-400 dark:bg-gray-900 shadow-header">
          {theme === DARK_MODE_CLASS ? <LogoDarkIcon /> : <LogoIcon />}
        </div>

        {isOnline && (
          <div className="px-12 py-14 flex w-[92%] max-w-[448px] flex-col justify-center items-start gap-8 bg-white-400 dark:border-gray-500 dark:bg-gray-900 shadow-login rounded-2xl">
            <div className="w-full">
              <h2 className="mb-3 flex gap-2 items-center text-gray-900 dark:text-white-400 text-[23px] font-semibold leading-[140%]">
                <LockXLIcon className="[&>g>path]:stroke-sea-serpent" />
                Enter your ID
              </h2>
              <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
                <InputField
                  schema="primary"
                  size="lg"
                  isPasswordInput
                  label="Please enter your ID to begin"
                  className="mb-4 tracking-widest"
                  name="id"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                  errorMessage={formik.errors.id}
                />
                <BaseButton
                  disabled={!formik.values.id || submitting}
                  type="submit"
                  scheme="cta-primary"
                  size="xl"
                  className="w-full"
                  isLoading={submitting}
                >
                  Continue
                </BaseButton>
              </form>
            </div>
          </div>
        )}

        {!isOnline && (
          <div className="pt-4 pb-12 px-4 flex flex-col items-center max-w-[500px] bg-white-400 dark:bg-gray-900 shadow-login rounded-2xl text-center">
            <WifiOffIcon className="mt-4" />
            <h2 className="mt-6 mb-4 text-gray-600 dark:text-white-400 text-[19px] font-semibold leading-7">
              Your device is currently offline
            </h2>
            <p className="mb-8 px-10 text-gray-600 dark:text-gray-200 text-sm font-normal leading-[140%]">
              Please check your device settings, or click below to run an offline assessment.
            </p>

            <div className="flex gap-4 w-full justify-center">
              <BaseButton scheme="primary" onClick={() => setShowPatientIdFormModal(true)}>
                Begin
              </BaseButton>
            </div>
          </div>
        )}

        {isShowPatientIdFormModal && <PatientIdFormModal onCancelClick={() => setShowPatientIdFormModal(false)} />}
      </div>
    </div>
  );
};
