import React, { useImperativeHandle, useState } from 'react';

import parse from 'html-react-parser';

import { AlertIcon } from 'assets/icons';
import { PriorOpiates } from 'types/assessment';
import { OptionType } from 'components/ui/form-fields';
import { MessageModal } from 'components/ui/modal/MessageModal';
import { QuestionInfo } from 'components/assessment/common/QuestionInfo';
import { DrugInputField } from 'components/assessment/common/DrugInputField';
import {
  NORMAL_CLONIDINE_MAX_DOSE_MCG_KG,
  NORMAL_CLONIDINE_MIN_DOSE_MCG_KG,
  NORMAL_MORPHINE_MAX_DOSE_MG_KG,
  NORMAL_MORPHINE_MIN_DOSE_MG_KG,
} from 'record/onboarding-result.record';

type DrugsDetailStepProps = {
  data: { transferred: boolean | null; hasEverHadMorphine: boolean | null; hasEverHadClonidine: boolean | null };
  onUpdate: (
    hasEverHadMorphine: boolean | null,
    hasEverHadClonidine: boolean | null,
    morphineDosage: number | null,
    clonidine_dosage: number | null,
    clonidineFrequency: number | null,
  ) => void;
  onContinue?: () => void;
};

const frequencyOptions: OptionType[] = [
  { label: 'Not Taking/Not Applicable', value: 0 },
  { label: 'Every 3 hours', value: 3 },
  { label: 'Every 6 hours', value: 6 },
];

export const DrugsDetailStep = React.forwardRef<unknown, DrugsDetailStepProps>(
  ({ data, onUpdate, onContinue }, ref) => {
    const [isShowDosageRangeModal, setShowDosageRangeModal] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        showWarning: () => {
          setShowDosageRangeModal(true);
        },
      }),
      [],
    );

    const handleChangeOpiate = (opiate: string, status: boolean) => {
      onUpdate(
        opiate === PriorOpiates.Morphine ? status : null,
        opiate === PriorOpiates.Clonidine ? status : null,
        null,
        null,
        null,
      );
    };

    const handleOpiateDoseChange = (opiate: string, dose: number | null) => {
      onUpdate(
        null,
        null,
        opiate === PriorOpiates.Morphine ? dose : null,
        opiate === PriorOpiates.Clonidine ? dose : null,
        null,
      );
    };

    return (
      <div>
        <QuestionInfo
          title={
            data.transferred
              ? "At the patient's previous hospital, were they given morphine or clonidine?"
              : 'Was patient given opiates prior to this assessment?'
          }
        />
        <DrugInputField
          drugName={PriorOpiates.Morphine}
          isHadEverDrug={data.hasEverHadMorphine}
          onChanges={handleChangeOpiate}
          onDoseChange={handleOpiateDoseChange}
        />
        <DrugInputField
          drugName={PriorOpiates.Clonidine}
          isHadEverDrug={data.hasEverHadClonidine}
          onChanges={handleChangeOpiate}
          onDoseChange={handleOpiateDoseChange}
          onChangeFrequency={(value) => onUpdate(null, null, null, null, Number(value))}
          options={frequencyOptions}
        />

        {isShowDosageRangeModal && (
          <MessageModal
            icon={AlertIcon}
            iconProps={{ className: 'text-silver-400' }}
            successText="Continue"
            cancelText="Change"
            title={'This value above/below the expected range'}
            description={parse(
              `Normal ranges for the drugs you selected:<br/> <strong>Morphine ${NORMAL_MORPHINE_MIN_DOSE_MG_KG}-${NORMAL_MORPHINE_MAX_DOSE_MG_KG} mg/kg,</strong></br> <strong>Clonidine ${NORMAL_CLONIDINE_MIN_DOSE_MCG_KG}–${NORMAL_CLONIDINE_MAX_DOSE_MCG_KG} mcg/kg.</strong>`,
            )}
            onCancelClick={() => setShowDosageRangeModal(false)}
            onSuccessClick={async () => {
              setShowDosageRangeModal(false);
              onContinue && onContinue();
            }}
          />
        )}
      </div>
    );
  },
);
