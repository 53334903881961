import { useEffect, useRef } from 'react';

type AnyEvent = MouseEvent | TouchEvent;

const useClickOutside = <T extends HTMLElement = HTMLElement>(
  isOpen: boolean,
  onClickOutside: (event: AnyEvent) => void,
): React.RefObject<T> => {
  const ref = useRef<T>(null);
  const event = 'mousedown';

  useEffect(() => {
    const handler = (event: AnyEvent) => {
      const el = ref?.current;

      if (!el || !isOpen || el.contains(event.target as Node)) {
        return;
      }

      onClickOutside(event);
    };

    document.addEventListener(event, handler);

    return () => {
      document.removeEventListener(event, handler);
    };
  }, [isOpen, onClickOutside]);

  return ref;
};

export default useClickOutside;
