import React from 'react';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import cn from 'classnames';
import {
  CloseIcon,
  LockIcon,
  NotificationErrorIcon,
  NotificationInfoIcon,
  NotificationSuccessIcon,
} from 'assets/icons';
export type NotificationType = 'error' | 'warning' | 'info' | 'success' | 'default';

type Notification = {
  type: NotificationType;
  title: string;
  description?: string;
};
type NotificationToastProps = {
  notification: Notification;
};

type CloseButtonProps = {
  closeToast: (e: React.MouseEvent<HTMLElement>) => void;
  type: NotificationType;
};
export const bgToastClass = {
  success: 'bg-green-300 border-green-300 dark:bg-pine dark:border-cactus',
  error: 'bg-red-100 border-red-200 dark:bg-red-600 dark:border-red-500',
  info: 'bg-blue-100 border-blue-200 dark:bg-blue-700 dark:border-blue-600',
  warning: 'bg-indigo dark:bg-blue-800 dark:border-sea-100',
  default: 'bg-white-400',
};

const getIconByType = (type: NotificationType) => {
  switch (type) {
    case 'error':
      return <NotificationErrorIcon />;
    case 'warning':
      return <LockIcon />;
    case 'info':
      return <NotificationInfoIcon />;
    case 'success':
      return <NotificationSuccessIcon />;
    default:
      return null;
  }
};

const CloseButton: React.FC<CloseButtonProps> = ({ closeToast, type }) => (
  <button type="button" onClick={closeToast}>
    <CloseIcon
      className={cn(
        type === 'warning' ? '[&>g>path]:fill-white-400' : '[&>g>path]:fill-gray-500 dark:[&>g>path]:fill-white-400',
      )}
    />
  </button>
);

const CustomToast: React.FC<NotificationToastProps> = ({ notification }) => (
  <div>
    <div className="flex items-start gap-2 min-w-min sm:min-w-[420px]">
      {getIconByType(notification.type)}
      <div>
        {notification.title && (
          <h4
            className={cn(
              'text-sm font-semibold leading-[140%]',
              notification.type === 'warning' ? 'text-white-400' : 'text-gray-600 dark:text-white-400',
            )}
          >
            {notification.title}
          </h4>
        )}
        {notification.description && (
          <p
            className={cn(
              'text-xs font-medium leading-[17px]',
              notification.type === 'warning' ? 'text-white-400' : 'text-gray-500 dark:text-white-300',
            )}
          >
            {notification.description}
          </p>
        )}
      </div>
    </div>
  </div>
);
export function createNotification(props: Notification) {
  toast(<CustomToast notification={props} />, {
    type: props.type || 'info',
    position: 'bottom-center',
    icon: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
  });
}

export const WrapperNotification: React.FC = () => {
  return (
    <div>
      <ToastContainer
        className="!w-fit"
        toastClassName={(context) =>
          cn(
            bgToastClass[context?.type || 'default'],
            'relative flex p-1 my-1 min-h-10 border rounded-lg justify-between overflow-hidden cursor-pointer',
            { 'w-[690px] text-white-400': context?.type === 'warning' },
          )
        }
        closeButton={(context) => <CloseButton closeToast={context.closeToast} type={context?.type || 'default'} />}
        newestOnTop
        limit={4}
        autoClose={3000}
        transition={Zoom}
      />
    </div>
  );
};
