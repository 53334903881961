export const LocalStorageService = {
  setLocalStorage(key: string, value: any, isJSON?: boolean): void {
    if (!value || value === 'undefined') return;

    if (!isJSON) localStorage.setItem(key, value);
    else localStorage.setItem(key, JSON.stringify(value));
  },

  removeLocalStorage(key: string): void {
    localStorage.removeItem(key);
  },

  getLocalStorage(key: string, isJSON?: boolean): string | any {
    if (!isJSON) return localStorage.getItem(key);
    else {
      const storedItem = localStorage.getItem(key);

      if (storedItem && storedItem !== 'null') {
        let parsedItem = {};

        try {
          parsedItem = JSON.parse(storedItem);
        } catch (error) {
          console.warn('Issue with parsing stored value: ', error);
        }

        return parsedItem;
      } else return null;
    }
  },
};
