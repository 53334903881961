import React from 'react';

export const Footer: React.FC = () => {
  return (
    <div className="flex w-full justify-center items-center gap-2.5 px-0 py-8">
      <p className="text-sky text-center text-xs font-medium leading-[17px]">
        By using the application you acknowledge you have read and agree to our&nbsp;
        <a href="/" className="text-indigo underline">
          Terms of Services
        </a>
      </p>
    </div>
  );
};
