import React from 'react';

import { CheckCircleIcon, RemoveCircleIcon } from 'assets/icons';
import { QuestionInfo } from 'components/assessment/common/QuestionInfo';
import { AnswerButton } from 'components/assessment/common/AnswerButton';

type YesNoQuestionStepProps = {
  title: string;
  currentAnswer: boolean | null;
  onSelect: (isTransferred: boolean) => void;
};

export const YesNoQuestionStep: React.FC<YesNoQuestionStepProps> = ({ title, onSelect, currentAnswer }) => {
  return (
    <div>
      <QuestionInfo title={title} />
      <AnswerButton
        currentAnswer={currentAnswer}
        onSelect={() => onSelect(true)}
        icon={CheckCircleIcon}
        buttonText="Yes"
      />
      <AnswerButton
        currentAnswer={!currentAnswer && currentAnswer !== null}
        onSelect={() => onSelect(false)}
        icon={RemoveCircleIcon}
        buttonText="No"
      />
    </div>
  );
};
