import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import cn from 'classnames';
import Joyride from 'react-joyride';
import { AxiosResponse } from 'axios';
import { useLiveQuery } from 'dexie-react-hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { db } from '../db';
import { Loader } from 'components/ui/Loader';
import { BaseButton } from 'components/ui/buttons';
import { Tooltip } from 'components/roster/TooltipTour';
import { detailTour, TourStepStatus } from 'types/tours';
import { Header } from 'components/details-pages/Header';
import { Footer } from 'components/details-pages/Footer';
import { StabilityLevel } from 'components/ui/StabilityLevel';
import { ActionModal, createNotification } from 'components/ui';
import { useNetworkCheck } from 'contexts/NetworkContextProvider';
import { StartTourModal } from 'components/roster/StartTourModal';
import { LocalStorageService } from 'services/localStorageService';
import { PatientMedicalRecord } from 'record/patient-medical.record';
import { ThanksModal } from 'components/assessment-details/ThanksModal';
import { AppRoutes, replaceRouteParamWithValue } from 'routes/app-routes';
import { getComfortMeasures } from 'algorithms/comfort-measures-algorithm';
import { StabilityChart } from 'components/assessment-details/StabilityChart';
import { Recommendation } from 'components/assessment-details/Recommendation';
import { useLoadingWithErrorHandling } from 'hooks/useLoadingWithErrorHandling';
import { assessmentTourSteps } from 'components/assessment-details/assessmentTourSteps';
import { StopAssessmentModal } from 'components/assessment-details/StopAssessmentModal';
import { RecommendationsModal } from 'components/assessment-details/RecommendationsModal';
import { FollowRecommendationModal } from 'components/assessment-details/FollowRecommendationModal';
import { PatientHistoryAssessments } from 'components/history-assessment/PatientHistoryAssessments';
import {
  changePatientStatus,
  getAssessment,
  getAssessmentResultsByPatientId,
  getPatientInformation,
} from 'services/api';
import {
  CalendarIcon,
  ClockIcon,
  FeedingIcon,
  TemperatureIcon,
  StoolingIcon,
  CryingIcon,
  SleepingIcon,
  TremorsIcon,
  MailIcon,
  PrintIcon,
  LeftIcon,
  RightIcon,
  WarningCubeIcon,
  InfoIcon,
} from 'assets/icons';
import {
  AssessmentResultsType,
  AssessmentResultType,
  AssessmentType,
  changeDirection,
  COMFORT_RECOMMENDATIONS,
  ComfortMeasures,
  Drugs,
  Symptoms,
  TREATMENT_RECOMMENDATIONS,
} from 'types/assessment';
import { useTenancy } from 'contexts/TenantContextProvider';
import { useAuth } from 'contexts/AuthContextProvider';
import { ClonidineRecommendation } from 'algorithms/clonidine-algorithm';

type DiscontinueAssessmentOptions = {
  isShowDiscontinueModal: boolean;
  isDiscontinueAssessments: boolean;
  isShowConfirmationDiscontinueModal: boolean;
  isContinueAssessments: boolean;
};

export type DosesDataType = {
  currentMorphineDose: number;
  currentClonidineDose: number;
  currentClonidineFrequency: number;
};

const STABILITY_INDICATORS = [
  {
    title: 'Feeding',
    icon: FeedingIcon,
    symptomId: Symptoms.Feeding,
    checkingScore: 0,
    stableClass: '[&>g>rect]:fill-green-900/15 [&>g>path]:stroke-green-900',
    unstableClass: '[&>g>rect]:fill-red-400 [&>g>path]:stroke-white-400',
  },
  {
    title: 'Stooling',
    icon: StoolingIcon,
    symptomId: Symptoms.Stools,
    checkingScore: 0,
    stableClass: '[&>g>rect]:fill-green-900/15 [&>g>path]:stroke-green-900',
    unstableClass: '[&>g>rect]:fill-red-400 [&>g>path]:stroke-white-400',
  },
  {
    title: 'Crying',
    icon: CryingIcon,
    symptomId: Symptoms.Crying,
    checkingScore: 0,
    stableClass: '[&>g>rect]:fill-green-900/15 [&>g>g>path]:fill-green-900 [&>g>g>g>path]:fill-green-900',
    unstableClass: '[&>g>rect]:fill-red-400 [&>g>g>path]:fill-white-400 [&>g>g>g>path]:fill-white-400',
  },
  {
    title: 'Sleeping',
    icon: SleepingIcon,
    symptomId: Symptoms.Sleep,
    checkingScore: 0,
    stableClass:
      '[&>g>rect]:fill-green-900/15 [&>g>path]:stroke-green-900 [&>g>g>path]:stroke-green-900 [&>g>ellipse]:fill-green-900',
    unstableClass:
      '[&>g>rect]:fill-red-400 [&>g>path]:stroke-white-400 [&>g>g>path]:stroke-white-400 [&>g>ellipse]:fill-white-400',
  },
  {
    title: 'Temperature',
    icon: TemperatureIcon,
    symptomId: Symptoms.Temperature,
    checkingScore: 0,
    stableClass: '[&>rect]:fill-green-900/15 [&>g>path]:stroke-green-900',
    unstableClass: '[&>rect]:fill-red-400 [&>g>path]:stroke-white-400',
  },
  {
    title: 'Tremors',
    icon: TremorsIcon,
    symptomId: Symptoms.Tremors,
    checkingScore: 3,
    stableClass: '[&>g>rect]:fill-green-900/15 [&>g>g>path]:stroke-green-900',
    unstableClass: '[&>g>rect]:fill-red-400 [&>g>g>path]:stroke-white-400',
  },
];
const MAX_RESULTS = 6;

export const AssessmentDetails: React.FC = () => {
  const [patientMedicalData, setPatientMedicalData] = useState<PatientMedicalRecord | null>(null);
  const [assessmentResults, setAssessmentResults] = useState<AssessmentResultType[]>([]);
  const [currentAssessmentIndex, setCurrentAssessmentIndex] = useState<number>(0);
  const [currentDosesData, setCurrentDosesData] = useState<DosesDataType | null>(null);
  const [isShowTourModal, setIsShowTourModal] = useState<boolean>(
    LocalStorageService.getLocalStorage(detailTour) === null,
  );
  const [isShowRecommendationsModal, setShowRecommendationsModal] = useState<boolean>(false);
  const [isShowThanksModal, setShowThanksModal] = useState<boolean>(false);
  const [isShowFollowRecommendationsModal, setShowFollowRecommendationsModal] = useState<boolean>(false);
  const [isStartTour, setIsStartTour] = useState<boolean>(false);
  const [assessment, setAssessment] = useState<AssessmentType | null>(null);

  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { isOnline } = useNetworkCheck();
  const tenantKey = useTenancy();

  const { id: patientId } = useParams();

  const assessmentResultFromDB = useLiveQuery(() => db.assessmentResults.where({ ref_id: patientId }).toArray());

  const mapDatabaseResultsToAssessmentResults = useCallback((): AssessmentResultType[] => {
    if (!assessmentResultFromDB) return [];

    return assessmentResultFromDB.map((result) => {
      return {
        id: '0',
        version: result.version,
        hours_old: result.hours_old,
        created: result.created.toISOString(),
        overall_score: result.overall_score,
        assessment_result_symptom: result.assessment_result_symptom,
        assessment_result_drug: result.assessment_result_drug,
        comfortRecommendations: [],
      };
    });
  }, [assessmentResultFromDB]);

  let isCallAssessment: boolean = false;

  if (location.state) {
    ({ isCallAssessment } = location.state as { isCallAssessment: boolean });
  }

  const [isStartAssessment, setStartAssessment] = useState<boolean>(isCallAssessment || false);

  const [discontinueAssessmentOptions, setDiscontinueAssessmentOptions] = useState<DiscontinueAssessmentOptions>({
    isShowDiscontinueModal: false,
    isDiscontinueAssessments: false,
    isShowConfirmationDiscontinueModal: false,
    isContinueAssessments: false,
  });

  const { submitting, setSubmitting, showErrorModal, ErrorModalComponent } = useLoadingWithErrorHandling();

  const currentResult = useMemo(
    () => assessmentResults[currentAssessmentIndex],
    [assessmentResults, currentAssessmentIndex],
  );

  const priorResult = assessmentResults[assessmentResults.length - 1];

  const generateCSIComfortMeasures = useMemo(() => {
    if (!currentResult || !assessment) return [];
    return getComfortMeasures(currentResult.assessment_result_symptom, assessment.comfort_recommendations);
  }, [currentResult, assessment]);

  const isRecommendationsNotConfirmed = useMemo(() => {
    if (!priorResult) return;

    const areDrugsRecommendationsNotAccepted = priorResult.assessment_result_drug.some(
      (drug) => drug.has_accepted_recommendation === null,
    );

    const isStandartComfortRecommendationNotAccepted =
      priorResult.comfortRecommendations &&
      (priorResult.comfortRecommendations.find(
        (measure) => measure.type_id === ComfortMeasures['Standard Comfort Measures'],
      )?.has_accepted_recommendation === null ??
        true);

    const isCsiComfortRecommendationNotAccepted =
      generateCSIComfortMeasures.length > 0 &&
      priorResult.comfortRecommendations &&
      (priorResult.comfortRecommendations.find((measure) => measure.type_id === ComfortMeasures['CSI Comfort Measures'])
        ?.has_accepted_recommendation === null ??
        true);

    return (
      areDrugsRecommendationsNotAccepted ||
      isStandartComfortRecommendationNotAccepted ||
      isCsiComfortRecommendationNotAccepted
    );
  }, [generateCSIComfortMeasures.length, priorResult]);

  const fetchAssessment = useCallback(async () => {
    const defaultAssessmentVersion = 1;
    const version = user?.tenants.find((t) => t.key === tenantKey)?.assessment_version ?? defaultAssessmentVersion;
    const { data }: AxiosResponse<AssessmentType> = await getAssessment(version);
    setAssessment(data);
  }, [setAssessment, user, tenantKey]);

  const fetchPatientAssessmentResults = useCallback(async () => {
    if (!patientId) return;

    setSubmitting(true);

    try {
      const { data }: AxiosResponse<AssessmentResultsType> = await getAssessmentResultsByPatientId(patientId);
      const sortedResults = sortedByDate(data.result);
      const lastFewResults = sortedResults.slice(-MAX_RESULTS);
      setAssessmentResults(lastFewResults);
      setCurrentAssessmentIndex(lastFewResults.length - 1);
    } catch (error) {
      console.error(error);
      showErrorModal();
    } finally {
      setSubmitting(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  const fetchPatientData = useCallback(async () => {
    if (!patientId) return;

    setSubmitting(true);

    try {
      const { data }: AxiosResponse<PatientMedicalRecord> = await getPatientInformation(patientId);
      setPatientMedicalData(new PatientMedicalRecord(data));
    } catch (error) {
      console.error(error);
      showErrorModal();
    } finally {
      setSubmitting(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  useEffect(() => {
    if (isStartAssessment && priorResult) {
      handleAssessmentAction();
      setStartAssessment(false);
    }
  }, [isStartAssessment, priorResult]);

  useEffect(() => {
    if (assessmentResultFromDB?.length) {
      setAssessmentResults(mapDatabaseResultsToAssessmentResults());
    }
  }, [assessmentResultFromDB, mapDatabaseResultsToAssessmentResults]);

  useEffect(() => {
    if (isOnline) {
      Promise.all([fetchPatientAssessmentResults(), fetchPatientData(), fetchAssessment()]);
    }
  }, [isOnline, fetchPatientAssessmentResults, fetchPatientData, fetchAssessment]);

  useEffect(() => {
    if (!priorResult) return;

    const clonidineDrug = priorResult.assessment_result_drug.find((drug) => drug.drug_id === Drugs.Clonidine);

    if (clonidineDrug) {
      const clonidineRecommendationKey = clonidineDrug.recommendation_coded as keyof typeof ClonidineRecommendation;
      const clonidineRecommendation = ClonidineRecommendation[clonidineRecommendationKey];

      setDiscontinueAssessmentOptions((prev) => ({
        ...prev,
        isDiscontinueAssessments:
          !patientMedicalData?.stop_assessment_date && clonidineRecommendation === ClonidineRecommendation.Discharge,
      }));
    }
  }, [priorResult, patientMedicalData]);

  const handleRouteToAssessment = () => {
    const route = replaceRouteParamWithValue(AppRoutes.Assessment, 'id', patientId);
    navigate(route, { state: currentDosesData });
  };

  const sortedByDate = (data: AssessmentResultType[]) => {
    return data.sort((a, b) => {
      const dateA = dayjs(a.created);
      const dateB = dayjs(b.created);
      return dateA.isAfter(dateB) ? 1 : -1;
    });
  };

  const noDecisionsOnTreatmentRecommendation = useMemo(() => {
    if (!priorResult) return [];

    return priorResult.assessment_result_drug.filter((drug) => drug.has_accepted_recommendation === null);
  }, [priorResult]);

  const tourSteps = assessmentTourSteps.filter((step) => document.querySelector(step.target));

  const startTour = () => {
    setIsShowTourModal(false);
    setIsStartTour(true);
  };

  const skippedTour = () => {
    setIsStartTour(false);
    setIsShowTourModal(false);
    LocalStorageService.setLocalStorage(detailTour, TourStepStatus.SKIPPED);
  };

  const isSymptomScoreNotBelowThreshold = (symptomId: string, checkingScore: number): boolean => {
    const symptom = currentResult.assessment_result_symptom.find((s) => s.symptom_id === symptomId);
    return !(!symptom || symptom.score <= checkingScore);
  };

  const determineDirection = useMemo(() => {
    if (assessmentResults.length < 2 || currentAssessmentIndex === 0) return changeDirection.noChange;

    const currentLevel = assessmentResults[currentAssessmentIndex].overall_score;
    const previousLevel = assessmentResults[currentAssessmentIndex - 1].overall_score;

    if (currentLevel > previousLevel) return changeDirection.decrease;
    if (currentLevel < previousLevel) return changeDirection.increase;

    return changeDirection.noChange;
  }, [assessmentResults, currentAssessmentIndex]);

  const selectResultById = (resultId: string) => {
    if (!resultId) return;

    const index = assessmentResults.findIndex((result) => result.id === resultId);
    if (index !== -1) setCurrentAssessmentIndex(index);
  };

  const handleActionInDiscontinueModal = (isRouteToAssessment: boolean = false) => {
    setDiscontinueAssessmentOptions((prev) => ({
      ...prev,
      isShowDiscontinueModal: false,
    }));

    if (isRouteToAssessment) {
      handleAssessmentAction();
    }
  };

  const handleConfirmationModal = () => {
    setDiscontinueAssessmentOptions((prev) => ({
      ...prev,
      isShowDiscontinueModal: false,
      isShowConfirmationDiscontinueModal: true,
    }));
  };

  const handleAssessmentAction = () => {
    setShowRecommendationsModal(true);
  };

  const stopAssessment = async () => {
    if (!patientId) return;

    try {
      setSubmitting(true);
      await changePatientStatus(patientId, false);
      navigate(AppRoutes.Roster);
      createNotification({
        type: 'success',
        title: `Assessment for patient ID: ${patientMedicalData?.ref_id} successfully discontinued`,
      });
    } catch (error) {
      console.error(error);
      showErrorModal();
    } finally {
      setSubmitting(false);
      setDiscontinueAssessmentOptions((prev) => ({
        ...prev,
        isShowConfirmationDiscontinueModal: false,
      }));
    }
  };

  if (submitting)
    return (
      <div className="flex items-center h-[100vh]">
        <Loader />
      </div>
    );

  if (!submitting && !assessmentResults.length)
    return <div className="flex w-full text-center font-semibold">No results</div>;

  return (
    <div className="flex flex-col content-between min-h-screen">
      <Header
        patientData={patientMedicalData}
        isDiscontinue={discontinueAssessmentOptions.isDiscontinueAssessments}
        onDiscontinue={() =>
          setDiscontinueAssessmentOptions((prev) => ({
            ...prev,
            isContinueAssessments: true,
            isShowDiscontinueModal: true,
          }))
        }
        onBeforeAssessmentAction={handleAssessmentAction}
      />

      <div className="flex flex-col content-between w-full max-w-[768px] mx-auto">
        <div>
          <div className="toggle-assessment flex justify-between items-center py-6">
            <div className="flex items-end">
              <div>
                <p className="text-gray-500 dark:text-silver-400 text-sm font-normal leading-[130%]">Assessment:</p>
                <div className="flex pt-1 gap-6">
                  <p className="relative flex items-center gap-1.5 text-gray-900 dark:text-silver-400 text-[19px] font-semibold leading-7 ">
                    <span className="absolute top-1 -right-[12px] w-[1px] h-[20px] bg-mirage/30" />
                    <CalendarIcon />
                    <span className="dark:text-white-400">{dayjs(currentResult.created).format('MMM DD, YYYY')}</span>
                  </p>
                  <p className="flex items-center gap-1.5 text-gray-900 dark:text-silver-400 text-[19px] font-semibold leading-7">
                    <ClockIcon />
                    <span className="dark:text-white-400">{dayjs(currentResult.created).format('hh:mma')}</span>
                  </p>
                </div>
              </div>
              {isOnline && currentAssessmentIndex === assessmentResults.length - 1 && (
                <div className="pl-3">
                  <p className="flex bg-teal-300/25 px-2.5 py-1 rounded-full text-gray-900 dark:text-white-200 text-xs font-medium leading-[135%]">
                    Last assessment
                  </p>
                </div>
              )}
            </div>
            {isOnline && (
              <div className="flex">
                <BaseButton
                  scheme={'secondary'}
                  icon={LeftIcon}
                  className="flex p-3 rounded-[12px_0px_0px_12px] focus:border-green-400 focus:bg-green-200 disabled:opacity-30"
                  disabled={currentAssessmentIndex === 0}
                  onClick={() => setCurrentAssessmentIndex((prev) => prev - 1)}
                />
                <BaseButton
                  scheme={'secondary'}
                  icon={RightIcon}
                  className="flex p-3 rounded-[0px_12px_12px_0px] focus:border-green-400 focus:bg-green-200 disabled:opacity-30"
                  disabled={currentAssessmentIndex === assessmentResults.length - 1}
                  onClick={() => setCurrentAssessmentIndex((prev) => prev + 1)}
                />
              </div>
            )}
          </div>

          <div className="monitor-stability flex flex-col gap-4 bg-white-400 dark:bg-gray-900 shadow-section pt-6 px-6 pb-[22px] rounded-2xl">
            {!isOnline && (
              <>
                <h2 className="text-gray-900 dark:text-white-400 text-base font-semibold leading-7">Stability Level</h2>
                <StabilityLevel
                  level={currentResult.overall_score}
                  levelDirection={changeDirection.noChange}
                  isShortVersion
                />
                <h2 className={cn('mt-3 text-gray-900 dark:text-white-400 text-base font-semibold leading-7 pb-3')}>
                  Clinical Stability Indicators (CSI)
                </h2>
              </>
            )}
            {isOnline && (
              <>
                <StabilityChart
                  currentLevelIndex={currentAssessmentIndex}
                  stabilityLevels={assessmentResults.map((result) => result.overall_score)}
                  levelDirection={determineDirection}
                  onSelectResult={(index) => setCurrentAssessmentIndex(index)}
                />
                <h2 className={cn('-mt-6 text-gray-900 dark:text-white-400 text-base font-semibold leading-7 pb-3')}>
                  Clinical Stability Indicators (CSI)
                </h2>
              </>
            )}
            <div className="flex">
              {STABILITY_INDICATORS.map((indicator, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center gap-2.5 w-1/6 my-[11.5px] border-r-[1px] last:border-none border-white-300"
                >
                  <indicator.icon
                    className={
                      isSymptomScoreNotBelowThreshold(indicator.symptomId, indicator.checkingScore)
                        ? indicator.unstableClass
                        : indicator.stableClass
                    }
                  />
                  <p className="text-gray-900 dark:text-white-400 text-center text-xs font-medium leading-[17px]">
                    {indicator.title}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {isRecommendationsNotConfirmed && (
            <div className="my-6 flex items-center gap-2 self-stretch border bg-red-100 px-3.5 py-3 rounded-full border-solid border-red-200 text-red-400">
              <InfoIcon />
              <span className="text-gray-900 text-sm font-medium leading-[140%]">
                Patient recommendations have not been confirmed
              </span>
            </div>
          )}

          <div className="treatment-recommendations flex flex-col items-start mt-6 gap-4 self-stretch bg-white-400 dark:bg-gray-900 p-6 shadow-section rounded-2xl">
            <h2 className="text-gray-900 dark:text-white-400 text-base font-semibold leading-7">
              Treatment Recommendations
            </h2>
            <div className="flex justify-between gap-6 w-full">
              {TREATMENT_RECOMMENDATIONS.map((recommendation) => (
                <Recommendation
                  key={recommendation.id}
                  isComfortMeasures={false}
                  name={recommendation.name}
                  id={recommendation.id}
                  currentResult={currentResult}
                  afterUpdate={fetchPatientAssessmentResults}
                  comfortRecommendations={assessment?.comfort_recommendations ?? []}
                  isShowControls={
                    isOnline && currentResult.id === priorResult.id && !patientMedicalData?.stop_assessment_date
                  }
                />
              ))}
            </div>
          </div>

          <div className="comfort-recommendations flex mb-6 flex-col items-start mt-6 gap-4 self-stretch bg-white-400 dark:bg-gray-900 p-6 shadow-section rounded-2xl">
            <h2 className="text-gray-900 dark:text-white-400 text-base font-semibold leading-7">
              Comfort recommendations
            </h2>
            <div className="flex justify-between gap-6 w-full">
              {COMFORT_RECOMMENDATIONS.map((recommendation) => (
                <Recommendation
                  key={recommendation.id}
                  isComfortMeasures
                  id={recommendation.id}
                  name={recommendation.name}
                  currentResult={currentResult}
                  afterUpdate={fetchPatientAssessmentResults}
                  comfortRecommendations={assessment?.comfort_recommendations ?? []}
                  isShowControls={
                    isOnline && currentResult.id === priorResult.id && !patientMedicalData?.stop_assessment_date
                  }
                />
              ))}
            </div>
          </div>
        </div>
        {isOnline && (
          <div className="send-print-btns flex justify-center items-start gap-6 pb-10 self-stretch border-b border-gray-200 dark:border-white-400/[.10]">
            <BaseButton
              scheme="secondary"
              icon={MailIcon}
              iconProps={{ className: 'w-[24px] h-[24px]' }}
              isIconOnLeftSide
              className="w-1/2 !p-5 !rounded-2xl !leading-[130%] dark:!bg-gray-900 !border-none"
            >
              Send
            </BaseButton>
            <BaseButton
              scheme="secondary"
              icon={PrintIcon}
              iconProps={{ className: 'w-[24px] h-[24px]' }}
              isIconOnLeftSide
              className="w-1/2 !p-5 !rounded-2xl !leading-[130%] dark:!bg-gray-900 !border-none"
            >
              Print
            </BaseButton>
          </div>
        )}
        {isOnline && (
          <PatientHistoryAssessments
            results={assessmentResults}
            currentAssessmentResultId={currentResult.id}
            selectResult={selectResultById}
          />
        )}
        <Footer />

        {isShowRecommendationsModal && (
          <RecommendationsModal
            onCloseClick={() => {
              setShowRecommendationsModal(false);
            }}
            onContinueClick={() => {
              setShowRecommendationsModal(false);
              setShowFollowRecommendationsModal(true);
            }}
          />
        )}

        {isShowFollowRecommendationsModal && patientMedicalData && (
          <FollowRecommendationModal
            patientData={patientMedicalData}
            onCloseClick={() => {
              setShowFollowRecommendationsModal(false);
              fetchPatientAssessmentResults();
            }}
            onContinueClick={(dosesData: DosesDataType) => {
              setCurrentDosesData(dosesData);
              setShowFollowRecommendationsModal(false);
              setShowThanksModal(true);
            }}
            recommendations={noDecisionsOnTreatmentRecommendation}
            lastResult={priorResult}
            fetchPatientInfo={fetchPatientData}
            comfortRecommendations={assessment?.comfort_recommendations ?? []}
          />
        )}

        {isShowThanksModal && (
          <ThanksModal onStartAssessment={handleRouteToAssessment} onCloseClick={() => setShowThanksModal(false)} />
        )}

        {discontinueAssessmentOptions.isShowDiscontinueModal && (
          <StopAssessmentModal
            isContinueAssessment={discontinueAssessmentOptions.isContinueAssessments}
            onContinue={handleActionInDiscontinueModal}
            onDiscontinue={handleConfirmationModal}
          />
        )}
        {discontinueAssessmentOptions.isShowConfirmationDiscontinueModal && (
          <ActionModal
            title={`Are you sure you want to discontinue assessments for patient ID: ${patientMedicalData?.ref_id}?`}
            description='The patient will be moved to the "Former Patients" section, and will no longer show up on your active roster. You can always re-activate them later.'
            onCancelClick={() =>
              setDiscontinueAssessmentOptions((prev) => ({
                ...prev,
                isShowConfirmationDiscontinueModal: false,
              }))
            }
            onSuccessClick={stopAssessment}
            successText="Yes, discontinue"
            isSubmitting={submitting}
          />
        )}

        {discontinueAssessmentOptions.isDiscontinueAssessments && (
          <div className="fixed bottom-0 left-0 w-full px-4 py-3 flex justify-between items-center bg-gray-500/90 backdrop-blur-[5px]">
            <p className="flex items-center gap-1.5 text-white-400 text-sm font-semibold leading-[140%]">
              <WarningCubeIcon />
              You may have reached criteria to discontinue assessments.
            </p>
            <div className="flex items-center gap-3">
              <BaseButton
                className="text-silver-400 hover:text-gray-400 !border-sky"
                scheme="transparent"
                onClick={() =>
                  setDiscontinueAssessmentOptions((prev) => ({
                    ...prev,
                    isShowDiscontinueModal: true,
                  }))
                }
              >
                Details
              </BaseButton>
              <BaseButton
                scheme="primary"
                onClick={() =>
                  setDiscontinueAssessmentOptions((prev) => ({
                    ...prev,
                    isShowConfirmationDiscontinueModal: true,
                  }))
                }
              >
                Discontinue
              </BaseButton>
            </div>
          </div>
        )}

        <Joyride
          steps={tourSteps}
          run={isStartTour}
          spotlightPadding={2}
          styles={{
            spotlight: {
              backgroundColor: 'rgba(255, 255, 255, 0.4)',
              borderRadius: 12,
            },
          }}
          tooltipComponent={(props) => (
            <Tooltip {...props} tourName={detailTour} stepsCount={tourSteps.length} skipTour={skippedTour} />
          )}
        />
        {isShowTourModal && (
          <StartTourModal
            title="Assessment complete"
            description="Since it's your first log-in, we'll show you around."
            onStartTourClick={startTour}
            onCancelClick={skippedTour}
          />
        )}

        {ErrorModalComponent}
      </div>
    </div>
  );
};
