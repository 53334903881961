import dayjs from 'dayjs';

interface OnboardingResultRecordType {
  ref_id: number | null;
  created: string;
  given_name: string;
  family_name: string;
  birth_weight_kg: number;
  datetime_of_birth: string | null;
  time_option: string;
  narcoticIds: string[] | null;
  other_narcotics: string[] | null;
  has_ever_had_morphine: boolean;
  has_clonidine_been_trialed_off: boolean;
  has_ever_had_clonidine: boolean;
  has_taken_long_acting_opioids: boolean;
  was_exposed_to_opioids_post_delivery: boolean;
  transferred: boolean;
  clonidine_dosage_at_the_previous_hospital_mcg: number | null;
  morphine_dosage_at_the_previous_hospital_mg: number | null;
  clonidine_frequency_at_the_previous_hospital: number | null;
}

export enum Narcotics {
  'Bupernophine (Subutex/Suboxone)' = '132db6f1-e84a-4f06-bd3c-a7c10b346e43',
  Methadone = '1025c212-627b-4af5-bde2-296e80b73a91',
  'Prescription pain pills' = 'c1be4434-97a2-48c1-a091-2ce5616dbd84',
  Benzodiazepines = '7d8e69aa-949a-4097-b471-f0abaff2f055',
  Heroin = 'd0248889-b507-482c-be43-cb974b82ab26',
  Fentanyl = 'b603f1b8-1a50-49f3-917f-a075c676f139',
}

export const NORMAL_MORPHINE_MIN_DOSE_MG_KG = 0.005;
export const NORMAL_MORPHINE_MAX_DOSE_MG_KG = 0.25;
export const NORMAL_CLONIDINE_MIN_DOSE_MCG_KG = 0.5;
export const NORMAL_CLONIDINE_MAX_DOSE_MCG_KG = 2.5;

export const TIME_FORMAT_OPTIONS = {
  military: {
    label: 'Military',
    value: 'military',
    format: 'MM.dd.YYYY HH:mm',
  },
  standard: {
    label: 'Standard',
    value: 'standard',
    format: 'MM.dd.YYYY HH:mm a',
  },
};

export class OnboardingResultRecord {
  ref_id: number | null;
  created: string;
  given_name: string;
  family_name: string;
  birth_weight_kg: number | null;
  narcotic_ids: string[] | null;
  other_narcotics: string[] | null;
  datetime_of_birth: string | null;
  time_option: string;
  has_ever_had_morphine: boolean;
  has_clonidine_been_trialed_off: boolean;
  has_ever_had_clonidine: boolean;
  has_taken_long_acting_opioids: boolean;
  was_exposed_to_opioids_post_delivery: boolean;
  transferred: boolean | null;
  morphine_dosage_at_the_previous_hospital_mg: number | null;
  clonidine_dosage_at_the_previous_hospital_mcg: number | null;
  clonidine_frequency_at_the_previous_hospital: number | null;

  constructor(record: Partial<OnboardingResultRecordType> = {}) {
    this.ref_id = record.ref_id || null;
    this.created = dayjs().toISOString();
    this.given_name = record.given_name || '';
    this.family_name = record.family_name || '';
    this.birth_weight_kg = record.birth_weight_kg || null;
    this.datetime_of_birth = record.datetime_of_birth || null;
    this.time_option = record.time_option || TIME_FORMAT_OPTIONS.standard.value;
    this.transferred = record.transferred || null;
    this.narcotic_ids = record.narcoticIds || null;
    this.other_narcotics = record.other_narcotics || null;
    this.has_ever_had_morphine = record.has_ever_had_morphine || false;
    this.has_clonidine_been_trialed_off = record.has_clonidine_been_trialed_off || false;
    this.has_ever_had_clonidine = record.has_ever_had_clonidine || false;
    this.has_taken_long_acting_opioids = record.has_taken_long_acting_opioids || false;
    this.was_exposed_to_opioids_post_delivery = record.was_exposed_to_opioids_post_delivery || false;
    this.morphine_dosage_at_the_previous_hospital_mg = record.morphine_dosage_at_the_previous_hospital_mg || null;
    this.clonidine_dosage_at_the_previous_hospital_mcg = record.clonidine_dosage_at_the_previous_hospital_mcg || null;
    this.clonidine_frequency_at_the_previous_hospital = record.clonidine_frequency_at_the_previous_hospital || null;
  }
}
