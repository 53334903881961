import Dexie from 'dexie';

import { AssessmentType } from 'types/assessment';
import { AssessmentResultRecord } from 'record/assessment-result.record';

type ExtendedAssessmentResultRecord = AssessmentResultRecord & { ref_id: string };

class MyDatabase extends Dexie {
  assessment: Dexie.Table<AssessmentType, number>;
  assessmentResults: Dexie.Table<ExtendedAssessmentResultRecord, number>;

  constructor() {
    super('assess-pro-db');
    this.version(1.1).stores({
      assessment: '++id, version, comfort_recommendations, questions',
      assessmentResults:
        '&ref_id, patient_id, isComplete, version, created, offline, has_ever_had_morphine, has_ever_had_clonidine, has_clonidine_been_trialed_off, has_taken_long_acting_opioids, overall_score, hours_old, has_missing_assessment, missing_assessment_reason, assessment_result_symptom, assessment_result_drug, answers',
    });

    this.assessment = this.table('assessment');
    this.assessmentResults = this.table('assessmentResults');
  }
}

export const db = new MyDatabase();
