import React from 'react';

import { OptionType, SelectField } from './form-fields';

type SortBySelectorProps = {
  options: OptionType[];
  value: string | number | null;
  onChange: (value: string | number | null) => void;
};

export const SortBySelector: React.FC<SortBySelectorProps> = ({ options, value, onChange }) => {
  return (
    <div>
      <SelectField
        label="Sort by:"
        name="sortBy"
        value={value}
        options={options}
        onChange={(value) => {
          if (typeof value === 'string' || typeof value === 'number') {
            onChange(value);
          }
        }}
        labelClassName="flex whitespace-nowrap items-center w-[160px] mr-6"
      />
    </div>
  );
};
