import React from 'react';

import { QuestionInfo } from 'components/assessment/common/QuestionInfo';
import { DateTimeField } from 'components/ui/form-fields';
import { TIME_FORMAT_OPTIONS } from 'record/onboarding-result.record';
import dayjs from 'dayjs';

type DateQuestionStepProps = {
  title: string;
  date: string | null;
  onChangeDate: (value: Date | null) => void;
};

export const DateQuestionStep: React.FC<DateQuestionStepProps> = ({ title, date, onChangeDate }) => {
  const maxTime =
    !date || dayjs().isSame(dayjs(date), 'day') ? new Date() : new Date(new Date().setHours(23, 59, 59, 999));

  return (
    <div>
      <QuestionInfo title={title} />

      <DateTimeField
        label="Date/Time"
        placeholderFormat={TIME_FORMAT_OPTIONS.standard.format}
        maxDate={dayjs().toDate()}
        minTime={new Date(new Date().setHours(0, 0, 0, 0))}
        maxTime={maxTime}
        value={date ? new Date(date) : null}
        onChange={(date) => onChangeDate(date)}
      />
    </div>
  );
};
