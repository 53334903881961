import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ActionModal } from 'components/ui';
import { IconButton } from 'components/ui/buttons';
import { AppRoutes, replaceRouteParamWithValue } from 'routes/app-routes';
import { AddUserIcon, AlertIcon, CloseIcon, StethoscopeIcon } from 'assets/icons';
import { useNetworkCheck } from 'contexts/NetworkContextProvider';

type AssessmentHeaderProps = {
  isFirstAssessment?: boolean;
  refId?: number | null;
};

export const AssessmentHeader: React.FC<AssessmentHeaderProps> = ({ refId, isFirstAssessment }) => {
  const [isShowConfirm, setShowConfirm] = useState(false);

  const { id: patientId } = useParams();
  const { isOnline } = useNetworkCheck();

  const navigate = useNavigate();

  const handleRoute = () => {
    if (patientId && !isFirstAssessment) {
      const route = replaceRouteParamWithValue(AppRoutes.CurrentPatient, 'id', patientId);
      navigate(route, { state: { isCallAssessment: false } });
    } else {
      navigate(AppRoutes.Roster);
    }
  };

  return (
    <div className="flex justify-between items-center pb-4 mb-6 border-b-[1px] border-silver-400 dark:border-white-400/20">
      <div className="flex items-center gap-2">
        <div className="flex w-8 h-8 justify-center items-center bg-mint dark:bg-sea-serpent/30 p-1.5 rounded-lg text-sea-400 dark:text-sea-serpent">
          {refId ? <StethoscopeIcon /> : <AddUserIcon />}
        </div>
        <p className="text-gray-500 dark:text-silver-400 text-center text-xs font-semibold leading-[17px]">
          {refId ? `Assessment for patient: ID ${refId}` : 'Patient onboarding'}
        </p>
      </div>
      {isOnline && (
        <div className="dark:text-silver-400">
          <IconButton scheme="secondary" size="sm" icon={CloseIcon} onClick={() => setShowConfirm(true)} />
        </div>
      )}

      {isShowConfirm && (
        <ActionModal
          title="Are you sure you want to leave?"
          description="If you log out, all the data you previously entered will be lost and will not be saved."
          onCancelClick={() => setShowConfirm(false)}
          onSuccessClick={handleRoute}
          iconTitle={AlertIcon}
          iconTitleProps={{ className: 'w-6 h-6 [&>g>path]:fill-red-400' }}
          successText="Yes, leave"
        />
      )}
    </div>
  );
};
