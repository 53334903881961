import React from 'react';

import cn from 'classnames';

import { RadioField } from 'components/ui/form-fields';
import { QuestionInfo } from 'components/assessment/common/QuestionInfo';

type ReasonStepProps = {
  currentReason?: string | null;
  onChange: (value: string) => void;
};

export const ReasonStep: React.FC<ReasonStepProps> = ({ currentReason, onChange }) => {
  const reasons = [
    { label: 'Infant was doing well', value: 'infant was doing well' },
    { label: 'Device was offline', value: 'device was offline' },
    { label: 'Other', value: 'Other' },
  ];
  return (
    <div>
      <QuestionInfo title="Please indicate the reason for the skipped assessment:" />
      {reasons.map((reason) => (
        <div
          key={reason.value}
          className={cn(
            'flex items-center justify-between gap-3 mb-3 self-stretch border pl-5 pr-3 py-[22px] rounded-xl dark:hover:bg-white-400/10 dark:hover:border-white-400/20',
            currentReason === reason.value
              ? 'border-gray-800 bg-white-400 dark:bg-white-400/20 dark:border-white-400/20'
              : 'bg-white-400/80 dark:bg-gray-900 border-transparent',
          )}
        >
          <RadioField
            label={reason.label}
            name="symptoms"
            value={reason.value}
            checked={currentReason === reason.value}
            onChange={(event) => onChange(event.target.value)}
          />
        </div>
      ))}
    </div>
  );
};
