import { Symptoms } from '../types/assessment';
import { SymptomScore } from './types/symptom-score';

const csiSymptoms = [Symptoms.Sleep, Symptoms.Feeding, Symptoms.Stools, Symptoms.Crying];
const nonCSISymptoms = [Symptoms.Tremors, Symptoms.Temperature];

const calculateCSIBooleans = (symptomScores: SymptomScore[]) => {
  
  const symptoms = Object.values(Symptoms).filter((symptom) => csiSymptoms.includes(symptom));

  return symptoms.reduce((acc, symptom) => {
    acc[symptom.toLowerCase()] = symptomScores.some((score) => score.symptom_id === symptom && score.score > 0);
    return acc;
  }, {} as Record<string, boolean>);
};

const calculateNonCSIBooleans = (symptomScores: SymptomScore[]) => {
  const symptoms = Object.values(Symptoms).filter((symptom) => nonCSISymptoms.includes(symptom));

  return symptoms.reduce((acc, symptom) => {
    acc[symptom.toLowerCase()] = symptomScores.some((score) => 
      score.symptom_id === symptom 
      && (
        (score.symptom_id === Symptoms.Tremors && score.score > 3)
        || (score.symptom_id !== Symptoms.Tremors && score.score > 0)
      )
    );
    return acc;
  }, {} as Record<string, boolean>);
};

const calculateCSIScore = (activeSymptoms: string[]): number => {
  let csiScore = 0;

  for (const symptom of csiSymptoms) {
    if (activeSymptoms.includes(symptom)) csiScore++;
  }

  return csiScore;
};

const calculateNonCSIScore = (activeSymptoms: string[]) => {
  let nonCsiScore = 0;

  for (const symptom of nonCSISymptoms) {
    if (activeSymptoms.includes(symptom)) nonCsiScore++;
  }

  return nonCsiScore;
};

export const determineActiveSymptoms = (symptomScores: SymptomScore[]): string[] => {
  const csiBooleans = calculateCSIBooleans(symptomScores);
  const nonCSIBooleans = calculateNonCSIBooleans(symptomScores);
  
  let activeSymptoms: string[] = [];
  for (const key in csiBooleans) {
    if (csiBooleans[key])
      activeSymptoms.push(key);
  }
  for (const key in nonCSIBooleans) {
    if (nonCSIBooleans[key])
      activeSymptoms.push(key);
  }

  return activeSymptoms;
};

export const determineEvaluationScore = (activeSymptoms: string[]): number => {
  const csiScore = calculateCSIScore(activeSymptoms);
  const nonCsiScore = calculateNonCSIScore(activeSymptoms);

  const conditionsToScores = [
    { condition: csiScore > 2 && activeSymptoms.includes(Symptoms.Feeding), score: 6 },
    { condition: csiScore === 2 && activeSymptoms.includes(Symptoms.Feeding), score: 5 },
    { condition: csiScore === 1 && activeSymptoms.includes(Symptoms.Feeding), score: 4 },
    { condition: csiScore > 0 && !activeSymptoms.includes(Symptoms.Feeding), score: 3 },
    { condition: nonCsiScore > 0, score: 2 },
    { condition: nonCsiScore === 0, score: 1 },
  ];

  const matchingCondition = conditionsToScores.find(({ condition }) => condition);

  return matchingCondition ? matchingCondition.score : 0;
};
