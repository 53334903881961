import { AssessmentResultAnswerType } from 'types/assessment';

interface AssessmentResultSymptomRecordType {
  symptom_id: string;
  score: number;
  is_poor: boolean;
}

interface AssessmentResultDrugRecordType {
  drug_id: string;
  recommendation: string;
  recommendation_coded: string;
  before_recommendation_dosage_mcg: number;
  before_recommendation_frequency: number;
  recommendation_dosage_mcg: number;
  recommendation_frequency: number;
  has_accepted_recommendation: boolean;
  decline_reasons: string[];
}

interface AssessmentResultOfflineRecordType {
  tenant_key: string;
  created_by: string | null;
}

interface AssessmentResultRecordType {
  isComplete: boolean | null;
  version: number | null;
  created: Date | null;
  patient_id: string | null;
  offline: AssessmentResultOfflineRecordType | null;
  has_ever_had_morphine: boolean | null;
  has_ever_had_clonidine: boolean | null;
  has_clonidine_been_trialed_off: boolean | null;
  has_taken_long_acting_opioids: boolean | null;
  hours_old: number | null;
  overall_score: number | null;
  has_missing_assessment: boolean | null;
  missing_assessment_reason: string | null;
  assessment_result_symptom: AssessmentResultSymptomRecordType[] | null;
  assessment_result_drug: AssessmentResultDrugRecordType[] | null;
  answers: AssessmentResultAnswerType[] | null;
}
export class AssessmentResultRecord {
  isComplete: boolean;
  version: number;
  created: Date;
  patient_id: string;
  offline: AssessmentResultOfflineRecord | null;
  has_ever_had_morphine: boolean;
  has_ever_had_clonidine: boolean;
  has_clonidine_been_trialed_off: boolean;
  has_taken_long_acting_opioids: boolean;
  hours_old: number;
  overall_score: number;
  has_missing_assessment: boolean;
  missing_assessment_reason: string | null;
  assessment_result_symptom: AssessmentResultSymptomRecord[];
  assessment_result_drug: AssessmentResultDrugRecord[];
  answers: AssessmentResultAnswerType[];

  constructor(record: Partial<AssessmentResultRecordType> = {}) {
    this.isComplete = record.isComplete || false;
    this.patient_id = record.patient_id || '';
    this.hours_old = record.hours_old || 0;
    this.version = record.version || 0;
    this.overall_score = record.overall_score || 0;
    this.offline = record.offline || null;
    this.has_missing_assessment = record.has_missing_assessment || false;
    this.missing_assessment_reason = record.missing_assessment_reason || null;
    this.assessment_result_symptom = record.assessment_result_symptom || [];
    this.assessment_result_drug = record.assessment_result_drug || [];
    this.answers = record.answers || [];
    this.created = record.created || new Date();
    this.has_ever_had_morphine = record.has_ever_had_morphine || false;
    this.has_ever_had_clonidine = record.has_ever_had_clonidine || false;
    this.has_clonidine_been_trialed_off = record.has_clonidine_been_trialed_off || false;
    this.has_taken_long_acting_opioids = record.has_taken_long_acting_opioids || false;
  }
}

export class AssessmentResultSymptomRecord {
  symptom_id: string;
  score: number;
  is_poor: boolean;

  constructor(record: Partial<AssessmentResultSymptomRecordType> = {}) {
    this.symptom_id = record.symptom_id || '';
    this.score = record.score || 0;
    this.is_poor = record.is_poor || false;
  }
}

export class AssessmentResultDrugRecord {
  drug_id: string;
  recommendation: string;
  recommendation_coded: string;
  before_recommendation_dosage_mcg: number;
  before_recommendation_frequency: number;
  recommendation_dosage_mcg: number;
  recommendation_frequency: number;
  has_accepted_recommendation: boolean;
  decline_reasons: string[];

  constructor(record: Partial<AssessmentResultDrugRecordType> = {}) {
    this.drug_id = record.drug_id || '';
    this.recommendation = record.recommendation || '';
    this.recommendation_coded = record.recommendation_coded || '';
    this.before_recommendation_dosage_mcg = record.before_recommendation_dosage_mcg || 0;
    this.before_recommendation_frequency = record.before_recommendation_frequency || 0;
    this.recommendation_dosage_mcg = record.recommendation_dosage_mcg || 0;
    this.recommendation_frequency = record.recommendation_frequency || 0;
    this.has_accepted_recommendation = record.has_accepted_recommendation || false;
    this.decline_reasons = record.decline_reasons || [];
  }
}

export class AssessmentResultOfflineRecord {
  tenant_key: string;
  created_by: string | null;

  constructor(record: Partial<AssessmentResultOfflineRecordType> = {}) {
    this.tenant_key = record.tenant_key || '';
    this.created_by = record.created_by || null;
  }
}
