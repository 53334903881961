import React, { useEffect, useState } from 'react';

import { InputField } from 'components/ui/form-fields';
import { QuestionInfo } from 'components/assessment/common/QuestionInfo';
import { convertKgToLbsOz, convertLbsOzToKg } from 'helpers/weight-сonversion';

type PatientWeightStepProps = {
  birthWeightKg: number | null;
  onChangeWeight: (weight: number) => void;
};

export const PatientWeightStep: React.FC<PatientWeightStepProps> = ({ birthWeightKg, onChangeWeight }) => {
  const [kilograms, setKilograms] = useState<string>('');
  const [pounds, setPounds] = useState<string>('');
  const [ounces, setOunces] = useState<string>('');

  useEffect(() => {
    if (birthWeightKg) {
      const { lbs, oz } = convertKgToLbsOz(birthWeightKg);
      setKilograms(birthWeightKg.toFixed(2));
      setPounds(lbs.toString());
      setOunces(oz.toFixed(3));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const weightInKg = convertLbsOzToKg(Number(pounds), Number(ounces));
    onChangeWeight(Number(weightInKg.toFixed(2)));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kilograms, pounds, ounces]);

  const handleChangeWeight = (event: React.ChangeEvent<HTMLInputElement>, label: string) => {
    let weight = parseFloat(event.target.value);
    weight = isNaN(weight) || weight < 0 ? 0 : weight;

    if (label === 'Kilograms') {
      const { lbs, oz } = convertKgToLbsOz(weight);
      setKilograms(weight.toString());
      setPounds(lbs.toString());
      setOunces(oz.toFixed(3));
    } else {
      if (label === 'Pounds') {
        const weightInKg = convertLbsOzToKg(weight, Number(ounces)).toFixed(2);
        setKilograms(weightInKg);
        setPounds(weight.toString());
      } else {
        const weightInKg = convertLbsOzToKg(Number(pounds), weight).toFixed(2);
        setKilograms(weightInKg);
        setOunces(weight.toString());
      }
    }
  };

  return (
    <div>
      <QuestionInfo title="What is the birth weight of the infant?" />
      <InputField
        type="number"
        step="0.01"
        min="0"
        className="w-full rounded-xl"
        size="xl"
        label="Kilograms"
        placeholder="00.00"
        inputMode="decimal"
        value={kilograms}
        onChange={(event) => handleChangeWeight(event, 'Kilograms')}
      />
      <div className="relative">
        <hr className="border-silver-400 my-8" />
        <p className="absolute -top-2 left-1/2 -ml-5 px-4 text-gray-900 dark:text-white-400 text-center bg-white-300 dark:bg-blue-500 text-sm font-semibold leading-[140%]">
          OR
        </p>
      </div>

      <div className="flex gap-4">
        <InputField
          type="number"
          step="1"
          min="0"
          className="w-full rounded-xl"
          size="xl"
          label="Pounds"
          placeholder="0"
          inputMode="numeric"
          value={pounds}
          onChange={(event) => handleChangeWeight(event, 'Pounds')}
        />
        <InputField
          type="number"
          step="0.01"
          min="0"
          className="w-full rounded-xl"
          size="xl"
          label="Ounces"
          placeholder="0"
          inputMode="decimal"
          value={ounces}
          onChange={(event) => handleChangeWeight(event, 'Ounces')}
        />
      </div>
    </div>
  );
};
