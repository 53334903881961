import React from 'react';

import { BaseModal } from 'components/ui';
import { BaseButton } from 'components/ui/buttons';
import { CheckboxField } from 'components/ui/form-fields';
import { areArraysDifferent } from 'helpers/are-arrays-different';

type RecommendationModalProps = {
  reasons: string[];
  onDeclineClick: (reasons: string[], isAccepted: boolean) => void;
  onCancelClick: () => void;
  isSubmitting?: boolean;
  isComfortMeasures?: boolean;
};

const TREATMENT_DECLINE_REASONS = ['Clinical acumen', 'At min dose', 'At max dose'];
const COMFORT_DECLINE_REASONS = ['Family not available', 'Accessories not available', 'Clinical acumen', 'Other'];

export const RecommendationDeclineModal: React.FC<RecommendationModalProps> = ({
  reasons,
  isComfortMeasures,
  onDeclineClick,
  onCancelClick,
  isSubmitting,
}) => {
  const [selectedReasons, setSelectedReasons] = React.useState<string[]>(reasons);

  const handleCheckboxChange = (value: string) => {
    if (selectedReasons.includes(value)) {
      setSelectedReasons(selectedReasons.filter((reason) => reason !== value));
    } else {
      setSelectedReasons([...selectedReasons, value]);
    }
  };

  const isDifferent = areArraysDifferent(selectedReasons, reasons);
  const reasonsToDisplay = isComfortMeasures ? COMFORT_DECLINE_REASONS : TREATMENT_DECLINE_REASONS;

  return (
    <BaseModal isShownCloseButton onCloseClick={onCancelClick}>
      <div className="max-w-[420px] px-6 flex flex-col">
        <h2 className="mb-4 text-left text-gray-600 dark:text-white-400 text-[19px] font-semibold leading-7">
          Please provide a reason for declining the recommendation:
        </h2>

        <div className="flex flex-col justify-center items-start gap-3 mb-6">
          {reasonsToDisplay.map((reason) => (
            <CheckboxField
              key={reason}
              label={reason}
              value={reason}
              checked={selectedReasons.includes(reason)}
              onChange={(event) => {
                handleCheckboxChange(event.target.value);
              }}
            />
          ))}
        </div>

        <div className="flex gap-4 w-full justify-end">
          <BaseButton scheme="secondary" onClick={onCancelClick}>
            Cancel
          </BaseButton>
          <BaseButton
            scheme="error"
            onClick={() => onDeclineClick(selectedReasons, false)}
            disabled={!selectedReasons.length || !isDifferent}
            isLoading={isSubmitting}
          >
            Decline
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
};
