import React from 'react';

declare var AppVersion: string;

export const Version: React.FC = () => {

  return (
    <div>
        <h1>Version: {AppVersion}</h1>
        <h2>Env: {process.env.NODE_ENV}</h2>
        <h2>API URL: {process.env.REACT_APP_API_URL}</h2>
        <h2>Public URL: {process.env.PUBLIC_URL}</h2>
    </div>
  );
};