import { useContext, createContext, useEffect, useState, useCallback } from 'react';
import {healthCheck} from 'services/api';

type childrenType = {
  children: React.ReactNode;
};

type networkStatusContextType = {
  isOnline: boolean;
};

const NetworkContext = createContext<networkStatusContextType | null>(null);

export const NetworkProvider = ({ children }: childrenType) => {
  const [isNetworkDetected, setIsNetworkDetected] = useState<boolean | null>(null);
  const [isOnline, setOnline] = useState<boolean | null>(null);

  const checkAndUpdateOnlineStatus = useCallback(() => {
    healthCheck()
    .then((response) => {
      setOnline(response.status === 200);
    })
    .catch((_error) => {
      setOnline(false);
    });
  }, [setOnline]);

  const setNetworkToFalse = useCallback((): void => {
    setIsNetworkDetected(false);
  }, [setIsNetworkDetected]);

  const setNetworkToTrue = useCallback((): void => {
    setIsNetworkDetected(true);
  }, [setIsNetworkDetected]);

  useEffect(() => {
    let intervalState: NodeJS.Timer | null = null;
    
    if (isNetworkDetected) {
      intervalState = setInterval(checkAndUpdateOnlineStatus, 5000);
      checkAndUpdateOnlineStatus();
    } else {
      setOnline(false);
    }

    return () => {
      if (intervalState)
        clearInterval(intervalState);
    }
  }, [isNetworkDetected, setOnline, checkAndUpdateOnlineStatus]);


  useEffect(() => {
    window.addEventListener('online', setNetworkToTrue);
    window.addEventListener('offline', setNetworkToFalse);
    if (navigator.onLine) {
      setNetworkToTrue();
    } else {
      setNetworkToFalse();
    }

    return () => {
      window.removeEventListener('online', setNetworkToTrue);
      window.removeEventListener('offline', setNetworkToFalse);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <NetworkContext.Provider value={{ isOnline: isOnline ?? true }}>{children}</NetworkContext.Provider>;
};

export const useNetworkCheck = () => {
  const context = useContext(NetworkContext);
  if (!context) {
    throw Error('useNetworkCheck must be used inside of NetworkProvider');
  }

  return context;
};
