export { ReactComponent as AddPatientIcon } from './add_patient.svg';
export { ReactComponent as SunIcon } from './sun.svg';
export { ReactComponent as MoonIcon } from './moon.svg';
export { ReactComponent as LockIcon } from './lock.svg';
export { ReactComponent as CheckIcon } from './check.svg';
export { ReactComponent as LockXLIcon } from './lock_xl.svg';
export { ReactComponent as CloseIcon } from './close.svg';
export { ReactComponent as CloseCircleIcon } from './close-circle.svg';
export { ReactComponent as InfoIcon } from './info.svg';
export { ReactComponent as MailIcon } from './mail.svg';
export { ReactComponent as EyeIcon } from './eye.svg';
export { ReactComponent as RightIcon } from './right.svg';
export { ReactComponent as DownIcon } from './down.svg';
export { ReactComponent as LeftIcon } from './left.svg';
export { ReactComponent as HideEyeIcon } from './hide_eye.svg';
export { ReactComponent as WarningIcon } from './warning.svg';
export { ReactComponent as NotificationErrorIcon } from './notification_error.svg';
export { ReactComponent as NotificationSuccessIcon } from './notification_success.svg';
export { ReactComponent as NotificationInfoIcon } from './notification_info.svg';
export { ReactComponent as AlertIcon } from './alert.svg';
export { ReactComponent as AddUserIcon } from './add_user.svg';
export { ReactComponent as StethoscopeIcon } from './stethoscope.svg';
export { ReactComponent as RemoveCircleIcon } from './remove_circle.svg';
export { ReactComponent as CheckCircleIcon } from './check-circle.svg';
export { ReactComponent as OfflineIcon } from './offline.svg';
export { ReactComponent as TickIcon } from './tick.svg';
export { ReactComponent as ClockIcon } from './clock.svg';
export { ReactComponent as FeedingIcon } from './feeding.svg';
export { ReactComponent as TemperatureIcon } from './temperature.svg';
export { ReactComponent as StoolingIcon } from './stooling.svg';
export { ReactComponent as CryingIcon } from './crying.svg';
export { ReactComponent as SleepingIcon } from './sleeping.svg';
export { ReactComponent as TremorsIcon } from './tremors.svg';
export { ReactComponent as ArrowRightIcon } from './arrow-right.svg';
export { ReactComponent as ArrowUpIcon } from './arrow-up.svg';
export { ReactComponent as ArrowDownIcon } from './arrow-down.svg';
export { ReactComponent as PlayIcon } from './play.svg';
export { ReactComponent as WifiOffIcon } from './wifi-off.svg';
export { ReactComponent as QrIcon } from './qr.svg';
export { ReactComponent as CalendarIcon } from './calendar.svg';
export { ReactComponent as MedicalInfoIcon } from './medical-info.svg';
export { ReactComponent as BabyWellIcon } from './baby-well.svg';
export { ReactComponent as BabyGoodIcon } from './baby-good.svg';
export { ReactComponent as BabyWorseIcon } from './baby-worse.svg';
export { ReactComponent as DecreaseIcon } from './decrease.svg';
export { ReactComponent as IncreaseIcon } from './increase.svg';
export { ReactComponent as NoChangeIcon } from './no-change.svg';
export { ReactComponent as StableIcon } from './stable.svg';
export { ReactComponent as PlusIcon } from './plus.svg';
export { ReactComponent as SearchIcon } from './search.svg';
export { ReactComponent as UndoIcon } from './undo.svg';
export { ReactComponent as PrintIcon } from './print.svg';
export { ReactComponent as WarningCubeIcon } from './warning-cube.svg';
export { ReactComponent as WristbandIcon } from './wristband.svg';
export { ReactComponent as MoreIcon } from './more.svg';
export { ReactComponent as EditIcon } from './edit.svg';
