import React, { HTMLAttributes } from 'react';
import { BaseModal } from './BaseModal';
import { BaseButton } from 'components/ui/buttons';
import cn from 'classnames';

type ActionModalProps = {
  title: string;
  additionalComponent?: React.ReactNode;
  description: string;
  iconTitle?: React.ComponentType<HTMLAttributes<SVGElement>>;
  iconTitleProps?: React.HTMLAttributes<SVGAElement>;
  successText?: string;
  cancelText?: string;
  onSuccessClick?: () => void;
  onCancelClick?: () => void;
  isSubmitting?: boolean;
};

export const ActionModal: React.FC<ActionModalProps> = ({
  title,
  description,
  additionalComponent,
  successText = 'Confirm',
  cancelText = 'Cancel',
  onSuccessClick,
  onCancelClick,
  iconTitle: Icon,
  iconTitleProps,
  isSubmitting,
}) => {
  return (
    <BaseModal isShownCloseButton onCloseClick={onCancelClick}>
      <div className="max-w-[470px]">
        <h2 className="flex items-center w-[90%] mb-4 text-left text-gray-600 dark:text-white-400 text-[19px] font-semibold leading-7">
          {Icon ? <Icon {...iconTitleProps} className={cn('mr-2.5', iconTitleProps?.className)} /> : null}
          {title}
        </h2>
        {additionalComponent}
        <p className="mb-8 text-left text-gray-600 dark:text-gray-200 text-sm font-normal leading-[140%]">
          {description}
        </p>

        <div className="flex gap-4 justify-end">
          <BaseButton scheme="secondary" onClick={onCancelClick}>
            {cancelText}
          </BaseButton>
          <BaseButton scheme="primary" onClick={onSuccessClick} isLoading={isSubmitting}>
            {successText}
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
};
