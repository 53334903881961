import React from 'react';

type ProgressBarProps = {
  progress: number;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <div className="pt-1 mx-2 w-full">
      <div className="w-full h-[4px] rounded-md bg-gray-200 dark:bg-gray-500">
        <div className="bg-sea-serpent rounded-md h-full" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};
