import { useState, useEffect, useCallback } from 'react';
import { useDebounce } from 'use-debounce';

import { searchPatients } from 'services/api';
import { PatientRecord } from 'record/patient.record';
import { OptionType } from 'components/ui/form-fields';

export type PractitionerRecord = {
  firstName: string | null;
  lastName: string | null;
  id: string | null;
};

export const rosterSortByOptions: OptionType[] = [
  { value: 0, label: 'Oldest' },
  { value: 1, label: 'Newest' },
];

const SEARCH_DEBOUNCE_DELAY = 750;

export const usePatientsData = () => {
  const [activePatients, setActivePatients] = useState<Record<string, PatientRecord[]> | null>(null);
  const [notActivePatients, setNotActivePatients] = useState<PatientRecord[] | null>(null);
  const [practitioners, setPractitioners] = useState<PractitionerRecord[]>([]);
  const [sortBy, setSortBy] = useState<OptionType['value']>(rosterSortByOptions[0].value);
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebounce(searchValue, SEARCH_DEBOUNCE_DELAY);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const groupByAssignedUserId = (records: PatientRecord[]): Record<string, PatientRecord[]> => {
    const practitionerRecords: PractitionerRecord[] = [];
    const otherRecords: PatientRecord[] = [];

    const groupedRecords = records.reduce(
      (groupedRecords, record) => {
        const key = record.assignedUserId;
        if (key !== null) {
          if (!groupedRecords[key]) {
            groupedRecords[key] = [];
            practitionerRecords.push({
              firstName: record.assignedUserFirstName,
              lastName: record.assignedUserLastName,
              id: record.assignedUserId,
            });
          }
          groupedRecords[key].push(record);
        } else {
          otherRecords.push(record);
        }
        return groupedRecords;
      },
      {} as Record<string, PatientRecord[]>,
    );

    // Append other records to the end of the grouped records
    if (otherRecords.length) {
      groupedRecords['other'] = otherRecords;
    }

    setPractitioners(practitionerRecords);
    return groupedRecords;
  };

  const activePatientsIsEmpty = !activePatients || Object.keys(activePatients).length === 0;
  const arePatientsEmpty = activePatientsIsEmpty && !notActivePatients?.length;

  const fetchPatients = useCallback(
    async (isActive: boolean, isShowMyPatients: boolean = false) => {
      setActivePatients(null);
      setNotActivePatients(null);
      let filters: any = { IsActive: isActive };

      if (isShowMyPatients) {
        filters.MyPatients = isShowMyPatients;
      }
      try {
        setSubmitting(true);
        const { data } = await searchPatients({
          search_value: debouncedSearchValue ? debouncedSearchValue : undefined,
          filters,
          includes: ['assigned_user_info', 'overall_score_from_two_last_assessments'],
          sort_criteria: { column: 'created_date', option: sortBy },
        });
        const patients = data.map((record: any) => new PatientRecord(record));
        if (isActive) {
          setActivePatients(groupByAssignedUserId(patients));
        } else {
          setNotActivePatients(patients);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
    [debouncedSearchValue, sortBy],
  );

  const fetch = useCallback(
    async (myPatients?: boolean) => {
      await Promise.all([fetchPatients(true, myPatients), fetchPatients(false, myPatients)]);
    },
    [fetchPatients],
  );

  useEffect(() => {
    fetch();
  }, [debouncedSearchValue, sortBy, fetch]);

  return {
    activePatients,
    notActivePatients,
    practitioners,
    fetch,
    searchValue,
    setSearchValue,
    setSortBy,
    sortBy,
    arePatientsEmpty,
    submitting,
  };
};
