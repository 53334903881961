import React from 'react';
import { FormikProps } from 'formik';

import { InputField } from 'components/ui/form-fields';
import { QuestionInfo } from 'components/assessment/common/QuestionInfo';
import { OnboardingValidateSchemaType } from 'components/assessment/OnboardingQuestionnaire';

type PatientNameEntryStepProps = {
  formik: FormikProps<OnboardingValidateSchemaType>;
};

export const PatientNameEntryStep: React.FC<PatientNameEntryStepProps> = ({ formik }) => {
  return (
    <div>
      <QuestionInfo
        title="Enter Patient's Name"
        description="This is a one-time entry that will be a stored in a secure location, only accessible by your group admin."
        optional
      />
      <div className="flex gap-4">
        <InputField
          className="w-full rounded-xl"
          size="xl"
          label="First Name"
          {...formik.getFieldProps('givenName')}
          errorMessage={formik.errors.givenName}
        />
        <InputField
          className="w-full rounded-xl"
          size="xl"
          label="Last Name"
          {...formik.getFieldProps('familyName')}
          errorMessage={formik.errors.familyName}
        />
      </div>
    </div>
  );
};
