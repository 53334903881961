import cn from 'classnames';
import React, { CSSProperties, HTMLAttributes } from 'react';
import { PulseLoader } from 'react-spinners';

export type ButtonScheme =
  | 'primary'
  | 'cta-primary'
  | 'secondary'
  | 'cta-secondary'
  | 'stroke'
  | 'cta-stroke'
  | 'transparent'
  | 'transparent-large'
  | 'error'
  | 'accept'
  | 'reject'
  | 'text';
export type ButtonSize = 'sm' | 'md' | 'lg' | 'xl' | 'none';
export type BaseButtonProps = {
  children?: React.ReactNode;
  icon?: React.ComponentType<HTMLAttributes<SVGElement>>;
  iconProps?: React.HTMLAttributes<SVGAElement>;
  scheme?: ButtonScheme;
  size?: ButtonSize;
  className?: string;
  disabled?: boolean;
  isIconOnLeftSide?: boolean;
  isLoading?: boolean;
};

const buttonSchemeClassMap: Record<ButtonScheme, string> = {
  primary:
    'bg-green-400 hover:bg-green-500 active:bg-green-600 text-sm leading-[140%] rounded-md focus:shadow-focus-button font-semibold border border-transparent text-white-400 disabled:bg-white-200 disabled:text-silver-400 dark:disabled:text-gray-800 dark:disabled:bg-gray-500',
  'cta-primary':
    'bg-green-400 hover:bg-green-500 active:bg-green-600 text-[19px] rounded-xl leading-[140%] focus:shadow-focus-button text-white-400 font-semibold border border-transparent disabled:bg-silver-400 dark:disabled:text-gray-800 dark:disabled:bg-gray-500',
  secondary:
    'bg-white-400 dark:bg-transparent hover:bg-white-100 dark:hover:bg-gray-900 active:bg-gray-200 dark:active:bg-gray-500 border border-gray-200 dark:border-gray-500 dark:hover:border-sky dark:active:border-sky focus:border-solid dark:focus:shadow-focus-secondary-button dark:focus:text-silver-400 rounded-md border-solid text-gray-600 dark:text-silver-400 dark:active:text-silver text-sm font-medium leading-[140%] disabled:text-silver dark:disabled:text-gray-500 disabled:opacity-60',
  'cta-secondary':
    'bg-transparent hover:bg-white-100 dark:hover:bg-gray-500 active:bg-gray-200 dark:active:bg-sky border border-solid border-gray-200 dark:border-sky dark:active:border-sky focus:shadow-focus-cta-secondary-button rounded-xl text-sky dark:text-silver-400 text-[19px] font-semibold leading-[140%] disabled:text-silver-400 dark:disabled:border-sky dark:disabled:text-sky',
  stroke:
    'border-2 border-green-400 bg-white-400 dark:border-green-400/40 dark:bg-transparent rounded-lg text-base text-green-400 leading-[140%] font-semibold hover:bg-green-100 dark:hover:bg-green-400/10 active:bg-green-200 dark:active:bg-green-400/25 focus:shadow-focus-button disabled:border-silver-400 dark:disabled:border-sky disabled:text-gray-800 dark:disabled:text-sky',
  'cta-stroke':
    'border-2 border-green-400 bg-white-400 dark:border-green-400/40 dark:bg-transparent rounded-xl text-[19px] text-green-400 leading-[140%] font-semibold hover:bg-green-100 dark:hover:bg-green-400/10 active:bg-green-200 dark:active:bg-green-400/25 focus:shadow-focus-button disabled:border-silver-400 dark:disabled:border-sky disabled:text-gray-800 dark:disabled:text-sky',
  transparent:
    'rounded-md text-gray-600 dark:text-silver-400 text-sm font-medium leading-[140%] hover:bg-white-300 dark:hover:bg-gray-500 active:bg-gray-200 dark:active:bg-sky border-2 border-transparent focus:border-2 focus:border-solid focus:border-gray-200 dark:focus:border-sky disabled:text-silver-400 dark:disabled:text-sky',
  'transparent-large':
    'bg-white-400 dark:bg-gray-900 rounded-2xl flex w-[333px] justify-center items-center gap-2 border border-transparent hover:border-silver-400 active:border-silver-400 dark:active:border-gray-500 focus:border-silver-400 dark:hover:border-gray-900 dark:hover:bg-gray-500  hover:bg-white-400 active:bg-gray-200 dark:active:bg-gray-500 rounded-2xl border-solid text-gray-500 dark:text-silver-400 text-sm font-medium leading-[140%] disabled:text-silver dark:disabled:text-sky',
  error:
    'px-6 py-1.5 bg-red-400 rounded-md text-white-400 text-sm font-semibold leading-[140%] hover:bg-red-700 active:bg-red-800 active:shadow-focus-error-button disabled:bg-white-300 disabled:text-gray-800 dark:disabled:bg-gray-800 dark:disabled:text-white-300',
  accept:
    'px-6 py-1.5 text-green-900 dark:text-green-400 text-[11px] font-semibold leading-[17px] uppercase bg-green-400/[.07] rounded-md border border-solid border-green-400/60 dark:bg-green-400/[.05] dark:border-green-400/[.25] hover:bg-green-100 dark:hover:border-green-400 dark:hover:bg-green-400/20 active:bg-green-200 dark:active:bg-green-400/25 dark:active:border-green-400 focus:bg-white-400 focus:shadow-focus-accept-button',
  reject:
    'px-6 py-1.5 text-red-400 dark:text-coral text-[11px] font-semibold leading-[17px] uppercase bg-red-400/[.05] dark:bg-red-300/[.05] rounded-md border border-solid border-red-400/30 dark:border-red-300/30 hover:bg-red-400/10 dark:hover:bg-red-300/[.15] hover:border-red-400/50 dark:hover:border-red-300 active:bg-red-400/[.12] active:border-red-400/30 dark:active:border-red-300 dark:active:bg-red-300/[.25] focus:bg-white-400 focus:shadow-focus-reject-button',
  text: 'text-[11px] font-medium leading-[17px] underline dark:text-silver-400',
};

export const disabledClassName: string = 'disabled:cursor-not-allowed disabled:pointer-events-none';

const buttonSizeClassMap: Record<ButtonSize, string> = {
  sm: 'px-3 py-1.5',
  md: 'px-4 py-2.5',
  lg: 'p-5',
  xl: 'px-6 py-3',
  none: '',
};

const override: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  margin: '0 auto',
};

export const BaseButton: React.FC<BaseButtonProps & React.ComponentPropsWithoutRef<'button'>> = ({
  scheme,
  size,
  className,
  children,
  icon: Icon,
  iconProps,
  isIconOnLeftSide,
  isLoading,
  ...props
}) => {
  const typeClass = buttonSchemeClassMap[scheme || 'primary'];
  const sizeClass = buttonSizeClassMap[size || 'md'];
  const reverseClass = isIconOnLeftSide ? 'flex-row-reverse' : '';

  return (
    <button
      type={props.type || 'button'}
      className={cn(
        className,
        disabledClassName,
        'flex items-center gap-2 justify-center border-solid transition ease-in-out',
        typeClass,
        sizeClass,
        reverseClass,
      )}
      {...props}
    >
      {isLoading ? <PulseLoader cssOverride={override} color="#ffffff" size={13} /> : children}
      {Icon ? <Icon {...iconProps} className={cn('w-[18px]', iconProps?.className)} /> : null}
    </button>
  );
};
