export const assessmentTourSteps = [
  {
    target: '.patient-id',
    title: 'View patient by ID, or QR code',
    content: "Access a patient's information by scanning their QR code or referencing their patient ID.",
    disableBeacon: true,
    placement: 'bottom-start' as const,
  },
  {
    target: '.start-new-assessment',
    title: 'You can start a new assessment',
    content: "Evaluate the patient's current condition and receive an updated recommendation for treatment.",
    disableBeacon: true,
    placement: 'left' as const,
  },
  {
    target: '.toggle-assessment',
    title: 'You can toggle between past assessments',
    content: 'Review past assessment data, track progress, and compare evaluations.',
    disableBeacon: true,
    placement: 'left' as const,
  },
  {
    target: '.monitor-stability',
    title: 'Monitor stability levels',
    content: "Assess the patient's condition at a quick glance by referencing their stability level and CSI.",
    disableBeacon: true,
    placement: 'bottom-start' as const,
  },
  {
    target: '.treatment-recommendations',
    title: 'Reject or Accept Treatment Recommendations',
    content:
      'You must reject or accept treatment recommendations before starting the next evaluation to ensure all suggestions are considered and documented properly.',
    disableBeacon: true,
    placement: 'bottom-start' as const,
  },
  {
    target: '.comfort-recommendations',
    title: 'Reject or Accept Comfort Recommendations',
    content: 'Accept or Reject comfort recommendations before starting the next evaluation.',
    disableBeacon: true,
    placement: 'bottom-start' as const,
  },
  {
    target: '.send-print-btns',
    title: 'You can send or print your assessment',
    content: "You can send or print the assessment, allowing results to be uploaded to the patient's EHR profile.",
    disableBeacon: true,
    placement: 'bottom-start' as const,
  },
  {
    target: '.assessment-history',
    title: 'Keep track of patient assessment history',
    content: 'View past assessment results and details.',
    disableBeacon: true,
    placement: 'top-start' as const,
  },
];
