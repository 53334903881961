import React, { HTMLAttributes, useEffect, useState } from 'react';
import cn from 'classnames';
import { EyeIcon, HideEyeIcon } from 'assets/icons';

export type InputSchemaType = 'default' | 'primary' | 'id';
export type InputSizeType = 'md' | 'lg' | 'xl';

type InputProps = {
  label?: string;
  description?: string;
  schema?: InputSchemaType;
  size?: InputSizeType;
  suffix?: string;
  errorMessage?: string;
  isPasswordInput?: boolean;
  icon?: React.ComponentType<HTMLAttributes<SVGElement>>;
  iconProps?: React.HTMLAttributes<SVGAElement>;
};

const schemaClassMap: Record<InputSchemaType, string> = {
  default:
    'border border-solid focus:border-gray-200 rounded-md focus:shadow-hover-input dark:focus:shadow-hover-dark-input text-gray-600 text-sm font-normal leading-[140%]',
  primary:
    'border-t-0 border-l-0 border-r-0 border-b !shadow-none focus:!ring-0 text-gray-900 dark:text-white-400 text-[32px] font-semibold leading-[normal]',
  id: 'border-none text-gray-500 text-[23px] md:text-[33px] font-medium leading-[normal] bg-transparent !shadow-none focus:!ring-0 tracking-widest',
};

const sizeClassMap: Record<InputSizeType, string> = {
  md: 'h-11 py-2',
  lg: 'h-[58px] pl-0',
  xl: 'h-[68px] px-6 py-[15px]',
};

const PasswordToggleIcon: React.FC<{ showPasswordValue: boolean; onClick: () => void }> = ({
  showPasswordValue,
  onClick,
}) => {
  const Icon = showPasswordValue ? EyeIcon : HideEyeIcon;
  return (
    <Icon
      className="absolute top-1/2 transform -translate-y-3/4 cursor-pointer right-0 [&>g>g>path]:stroke-gray-800 [&>g>path]:stroke-gray-800"
      onClick={onClick}
    />
  );
};

export const InputField: React.FC<InputProps & Omit<React.ComponentPropsWithoutRef<'input'>, 'size'>> = ({
  label,
  description,
  schema = 'default',
  size,
  isPasswordInput,
  suffix,
  errorMessage,
  icon: Icon,
  iconProps,
  className,
  ...props
}) => {
  const [showPasswordValue, setShowPasswordValue] = useState<boolean>(false);
  const sizeClass = sizeClassMap[size || 'md'];

  useEffect(() => {
    if (isPasswordInput) {
      setShowPasswordValue(true);
    }
  }, [isPasswordInput]);

  return (
    <div className="relative w-full">
      <label
        className={cn({
          'mb-1 text-blue-500 text-xs font-medium leading-[17px] dark:text-white-400': schema === 'default',
          'mb-1 text-sky text-sm font-normal leading-5 dark:text-gray-800': schema === 'primary',
          'text-gray-800': props.disabled,
        })}
        htmlFor={`input-field-${props.name}`}
      >
        {label}
      </label>
      <div className="relative">
        <input
          name={`input-field-${props.name}`}
          type={showPasswordValue ? 'password' : props.type}
          className={cn(
            className,
            schemaClassMap[schema],
            sizeClass,
            'w-full placeholder-sky !outline-none transition-all duration-200',
            'dark:bg-white-400/10 dark:text-white-400 dark:placeholder-sky dark:disabled:bg-white-400/[.04]',
            { 'disabled:bg-white-100 disabled:text-gray-800 disabled:placeholder-gray-800': schema !== 'id' },
            Icon ? 'pl-[46px]' : { 'pl-3': schema === 'default' },
            suffix ? 'pr-14' : { 'pr-2': schema === 'default', 'pr-8': schema === 'primary' },
            errorMessage
              ? 'border-red-400'
              : {
                  'border-silver-400 hover:border-gray-800 dark:hover:border-gray-800 dark:border-gray-500':
                    schema === 'default',
                  'border-black-400 dark:border-sky': schema === 'primary',
                },
          )}
          {...props}
        />

        {isPasswordInput && (
          <PasswordToggleIcon
            showPasswordValue={showPasswordValue}
            onClick={() => setShowPasswordValue((prev) => !prev)}
          />
        )}

        {Icon ? <Icon {...iconProps} className={cn('w-5 h-5 absolute top-3 left-3', iconProps?.className)} /> : null}

        {suffix && (
          <div
            className={cn(
              'flex items-center justify-center px-3 py-[13px] h-11 absolute top-0 right-0 font-normal',
              'border-l text-sky text-xs font-medium',
              { 'text-silver-400 bg-white-100': props.disabled },
              errorMessage ? 'border-red-400' : 'border-silver-400 dark:border-gray-800',
            )}
          >
            {suffix}
          </div>
        )}
      </div>
      {description && (
        <p
          className={cn(
            'text-xs font-normal leading-[17px]',
            { 'text-gray-800': props.disabled },
            errorMessage ? 'text-red-400' : 'text-sky dark:text-gray-800',
          )}
        >
          {description}
        </p>
      )}
      {errorMessage && (
        <p className="absolute -bottom-5 left-0 text-xs text-red-400 font-medium leading-[17px]">{errorMessage}</p>
      )}
    </div>
  );
};
