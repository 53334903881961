import React, { useState } from 'react';

import { PlusIcon } from 'assets/icons';
import { assignPatient } from 'services/api';
import { BaseButton } from 'components/ui/buttons';
import { PatientRecord } from 'record/patient.record';
import { ActionModal, createNotification } from 'components/ui';
import { useLoadingWithErrorHandling } from 'hooks/useLoadingWithErrorHandling';

type AssignPatientProps = {
  patient: Pick<PatientRecord, 'id' | 'refId'>;
  buttonScheme?: 'stroke' | 'cta-stroke';
  afterAssign?: () => void;
};

export const AssignPatientButton: React.FC<AssignPatientProps> = ({ patient, buttonScheme, afterAssign }) => {
  const [isShownAssignModal, setIsShownAssignModal] = useState(false);

  const { submitting, setSubmitting, showErrorModal, ErrorModalComponent } = useLoadingWithErrorHandling();

  const assignPatientToYourself = async () => {
    if (!patient.id) return;

    try {
      setSubmitting(true);
      await assignPatient(patient.id);
      createNotification({
        type: 'success',
        title: `Patient ID: ${patient.refId} has been assigned to you`,
      });
      afterAssign && afterAssign();
    } catch (error) {
      console.error(error);
      showErrorModal();
    } finally {
      setSubmitting(false);
      setIsShownAssignModal(false);
    }
  };

  return (
    <>
      <BaseButton
        className="assign-patient"
        scheme={buttonScheme}
        icon={PlusIcon}
        isIconOnLeftSide
        onClick={() => setIsShownAssignModal(true)}
      >
        Assign
      </BaseButton>

      {isShownAssignModal && (
        <ActionModal
          onCancelClick={() => setIsShownAssignModal(false)}
          onSuccessClick={assignPatientToYourself}
          successText="Yes, assign"
          isSubmitting={submitting}
          title={`Are you sure you want to assign yourself patient ID: ${patient.refId}?`}
          description='The patient will appear in the "My Patients", where you can view info and run assessments'
        />
      )}
      {ErrorModalComponent}
    </>
  );
};
