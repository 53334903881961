import React from 'react';
import cn from 'classnames';

import { IconButton } from 'components/ui/buttons';
import { CloseIcon, SearchIcon } from 'assets/icons';

type SearchInputFieldProps = {
  isError?: boolean;
  clearSearchInput?: () => void;
};

export const SearchInputField: React.FC<SearchInputFieldProps & React.ComponentPropsWithoutRef<'input'>> = ({
  isError,
  clearSearchInput,
  ...props
}) => {
  return (
    <div className="relative w-full">
      {isError ? (
        <div className="absolute left-4 top-[30px] flex justify-center items-center bg-red-400 text-white-400 dark:text-gray-500 w-4 h-4 rounded-full">
          <CloseIcon className="w-3 h-3" />
        </div>
      ) : (
        <SearchIcon className="absolute left-4 top-[30px] text-white-100/[.05]" />
      )}

      <input
        type="text"
        className={cn(
          'flex w-full pl-9 pr-12 py-6 placeholder:text-gray-800 placeholder:text-sm placeholder:font-medium dark:bg-gray-900',
          'border-transparent border-[2px] text-gray-500 dark:text-white-400 text-base font-semibold leading-6 focus:outline-none',
          'focus:border-transparent hover:border-b-sky focus:border-b-teal-400 focus:shadow-search focus:ring-transparent',
          'dark:hover:bg-gray-500 dark:hover:border-b-white-400/25 dark:focus:bg-gray-500 dark:focus:border-b-white-400',
        )}
        {...props}
      />
      {props.value && (
        <IconButton
          scheme="primary"
          size="sm"
          className="absolute top-[22px] right-4 rounded-md dark:bg-gray-900"
          icon={CloseIcon}
          iconProps={{ className: 'dark:text-silver-400' }}
          onClick={clearSearchInput}
        />
      )}
    </div>
  );
};
