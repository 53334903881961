import React from 'react';
import cn from 'classnames';
import { CheckIcon } from 'assets/icons';

type CheckboxFieldProps = {
  label?: string;
  errorMessage?: string;
};

export const CheckboxField: React.FC<CheckboxFieldProps & React.ComponentPropsWithoutRef<'input'>> = ({
  label,
  errorMessage,
  ...props
}) => (
  <label className={cn('flex items-center gap-3 group cursor-pointer')}>
    <input type="checkbox" className="peer sr-only" {...props} />
    <span
      className={cn(
        'w-6 h-6 relative rounded border peer-checked:bg-sea-serpent peer-checked:[&>.checked-icon]:opacity-100',
        'hover:bg-white-300 dark:hover:bg-gray-800 peer-checked:hover:bg-green-400 peer-checked:border-transparent peer-focus:shadow-focus-checked',
        'peer-focus:border-sea-serpent peer-disabled:border-gray-200 transition-all duration-200',
        errorMessage ? 'border-red-900' : 'border-gray-800',
      )}
    >
      <CheckIcon
        className={cn(
          'checked-icon absolute top-0 left-0 bottom-0 right-0 m-auto opacity-0 [&>g>path]:fill-white-400',
          'dark:[&>g>path]:fill-black-400',
        )}
      />
    </span>
    {label && (
      <span
        className={cn(
          ' text-sm font-medium leading-[140%]',
          props.disabled ? 'text-gray-800' : 'text-gray-500 dark:text-silver-400',
        )}
      >
        {label}
      </span>
    )}
  </label>
);
