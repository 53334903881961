import React from 'react';
import { BaseModal } from './BaseModal';
import { BaseButton } from 'components/ui/buttons';
import { AlertIcon } from 'assets/icons';

type ErrorModalProps = {
  btnText?: string;
  errorMessage?: string;
  onRetryClick?: () => void;
  onCancelClick?: () => void;
};

export const ErrorModal: React.FC<ErrorModalProps> = ({
  btnText = 'Retry',
  errorMessage = 'We’re having technical issues at the moment. Please wait a few minutes and then try again.',
  onRetryClick,
  onCancelClick,
  ...props
}) => {
  return (
    <BaseModal isShownCloseButton onCloseClick={onCancelClick}>
      <div className="flex flex-col mt-4 items-center max-w-[500px] text-center">
        <AlertIcon className="mb-6 [&>g>path]:fill-silver-400" />
        <h2 className="mb-1 text-silver-800 dark:text-white-400 text-lg font-semibold leading-[145%]">
          Something went wrong
        </h2>
        <p className="mb-8 text-gray-500 dark:text-gray-200 text-sm font-normal leading-[135%]">{errorMessage}</p>

        <div className="flex justify-center">
          <BaseButton scheme="error" onClick={onRetryClick || onCancelClick}>
            {btnText}
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
};
