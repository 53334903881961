import React from 'react';
import cn from 'classnames';

import { BaseButton } from 'components/ui/buttons';
import { MorphineRecommendation } from 'algorithms/morphine-algorithm';
import { QuestionInfo } from 'components/assessment/common/QuestionInfo';
import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon, CloseIcon } from 'assets/icons';

type MorphineRecommendationStepProps = {
  recommendedMorphine: MorphineRecommendation | null;
  onSelect: (value: number) => void;
};

export const MorphineRecStep: React.FC<MorphineRecommendationStepProps> = ({ recommendedMorphine, onSelect }) => {
  const answers = [
    { label: 'Not Started', value: MorphineRecommendation.Notstarted, icon: CloseIcon },
    { label: 'Start', value: MorphineRecommendation.Start, icon: ArrowRightIcon },
    { label: 'No change', value: MorphineRecommendation.Nochange, icon: ArrowRightIcon },
    { label: 'Increase', value: MorphineRecommendation.Increase, icon: ArrowUpIcon },
    { label: 'Decrease', value: MorphineRecommendation.Decrease, icon: ArrowDownIcon },
    { label: 'Discontinue', value: MorphineRecommendation.Discontinue, icon: CloseIcon },
  ];
  return (
    <div>
      <QuestionInfo title="What was the morphine recommendation from the previous assessment?" />
      {answers.map((answer) => (
        <div
          key={answer.value}
          className={cn(
            'w-full mb-3 pl-5 pr-3 py-[18px] rounded-xl border border-solid dark:hover:bg-white-400/10 dark:hover:border-white-400/20',
            recommendedMorphine === answer.value
              ? 'border-gray-800 bg-white-400 dark:bg-white-400/20 dark:border-white-400/20'
              : 'bg-white-400/80 dark:bg-gray-900 border-transparent',
          )}
        >
          <BaseButton
            className={cn('pl-2.5 pr-3 py-[5px] !rounded-full border-gray-800', {
              '!bg-sea-serpent dark:!bg-sea-serpent/20 border-transparent dark:border-sea-serpent text-white-400':
                recommendedMorphine === answer.value,
            })}
            icon={answer.icon}
            iconProps={{
              className: cn('h-[18px] text-gray-900 dark:text-white-400', {
                'text-white-400': recommendedMorphine === answer.value,
              }),
            }}
            isIconOnLeftSide
            onClick={() => onSelect(answer.value)}
            scheme="secondary"
          >
            {answer.label}
          </BaseButton>
        </div>
      ))}
    </div>
  );
};
