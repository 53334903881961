import cn from 'classnames';
import { CheckboxField } from 'components/ui/form-fields';
import React from 'react';

type SubstanceCheckboxProps = {
  narcoticName: string;
  narcoticId: string;
  substances: string[] | null;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const SubstanceCheckbox: React.FC<SubstanceCheckboxProps> = ({
  narcoticName,
  narcoticId,
  substances,
  handleCheckboxChange,
}) => {
  const isSubstanceIncluded = !!substances && substances.includes(narcoticId);
  return (
    <div
      className={cn(
        'mb-3 bg-white-400/80 dark:bg-gray-900 pl-5 pr-3 py-[22px] rounded-xl border dark:hover:bg-white-400/10 dark:hover:border-white-400/20',
        isSubstanceIncluded
          ? 'border-gray-800 bg-white-400 dark:bg-white-400/20 dark:border-white-400/20'
          : 'border-transparent',
      )}
    >
      <CheckboxField
        label={narcoticName}
        name="substance"
        value={narcoticId}
        checked={isSubstanceIncluded}
        onChange={handleCheckboxChange}
      />
    </div>
  );
};
