import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { BaseButton } from 'components/ui/buttons';
import { LeftIcon, PlayIcon, PrintIcon } from 'assets/icons';
import { AppRoutes, replaceRouteParamWithValue } from 'routes/app-routes';
import { QRTemplatesModal } from 'components/details-pages/modals/QRTamplatesModal';

type PatientAddedSuccessProps = {
  patientId: string | null;
  patientRefId: number;
};

export const PatientAddedSuccess: React.FC<PatientAddedSuccessProps> = ({ patientId, patientRefId }) => {
  const [isShowQRTemplateModal, setShowQRTemplateModal] = useState(false);

  const navigate = useNavigate();

  const handleStartAssessment = () => {
    if (!patientId) return;

    const route = replaceRouteParamWithValue(AppRoutes.Assessment, 'id', patientId);
    navigate(route);
  };

  const handleBackToRoster = () => {
    navigate(AppRoutes.Roster);
  };

  const handlePrintQRCode = () => {
    setShowQRTemplateModal(true);
  };

  return (
    <div className="p-10 min-h-[calc(100vh-72px)]">
      <div className="bg-add-patient-success pt-[385px] bg-center bg-top bg-no-repeat">
        <h1 className="max-w-[420px] mx-auto self-stretch text-gray-900 dark:text-silver-400 text-center text-[28px] font-semibold leading-[normal]">
          You have successfully added patient ID: {patientRefId}
        </h1>
        <p className="max-w-[420px] mx-auto mt-3 mb-12 self-stretch text-gray-500 dark:text-silver-400 text-center text-base font-medium leading-[150%]">
          Begin your first assessment, or print your patient's QR code for easy access to their profile.
        </p>
        <div>
          <BaseButton
            scheme="cta-primary"
            className="w-full mb-4"
            size="xl"
            icon={PlayIcon}
            isIconOnLeftSide
            onClick={handleStartAssessment}
          >
            Start First Assessment
          </BaseButton>
          <div className="flex gap-6 w-full pt-4">
            <BaseButton
              scheme="cta-secondary"
              className="w-full"
              size="xl"
              icon={LeftIcon}
              isIconOnLeftSide
              onClick={handleBackToRoster}
            >
              Back to Roster
            </BaseButton>
            <BaseButton
              scheme="cta-secondary"
              className="w-full"
              size="xl"
              icon={PrintIcon}
              isIconOnLeftSide
              onClick={handlePrintQRCode}
            >
              Print QR Code
            </BaseButton>
          </div>
        </div>
      </div>

      {isShowQRTemplateModal && (
        <QRTemplatesModal
          refId={patientRefId}
          onCancelClick={() => {
            setShowQRTemplateModal(false);
          }}
          onPrintClick={() => {}}
        />
      )}
    </div>
  );
};
