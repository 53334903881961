import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import * as process from 'process';
import { useIdleTimer } from 'react-idle-timer';

import API, { refreshAuth } from 'services/api';
import { createNotification } from 'components/ui';
import { useTenancy } from 'contexts/TenantContextProvider';
import { TenantType } from 'types/tenant';
import { useNetworkCheck } from 'contexts/NetworkContextProvider';
import { useCookies } from 'react-cookie';
import { AxiosError } from 'axios';

type AuthContextType = {
  token: string | null;
  user: UserType | null;
  isLoading: boolean;
  setAuthToken: (token: string) => void;
  logout: () => void;
};
type AuthProviderProps = {
  children: ReactNode;
};

type UserType = {
  name: string;
  userId: string;
  roleId: string;
  avatarUrl: string | null;
  tenants: TenantType[];
};

const ONE_MINUTE_IN_MS = 60000;
const TIMEOUT_MINUTES = Number(process.env.REACT_APP_IDLE_TIMEOUT_MINUTES || 10);
const MAX_REFRESH_RETRIES = 3;
const COOKIE_TOKEN = 'token';
let refreshRetries = 0;

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserType | null>(null);
  const [expTime, setExpTime] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_TOKEN]);

  const tenantKey = useTenancy();
  const { isOnline } = useNetworkCheck();

  const showNotification = () => {
    createNotification({
      type: 'warning',
      title: 'Your session has timed out',
      description: 'Due to inactivity, your screen has been automatically locked. Please enter your ID to continue',
    });
  };

  const getUserInfoAndExpiryTime = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payload = JSON.parse(window.atob(base64));

    const parseFormattedTenants = (formattedTenants: string[]): TenantType[] => {
      const tenantClaimDivider = '|';

      return formattedTenants.map((t) => {
        const tenantParts = t.split(tenantClaimDivider);
        const [id, key, name, assessment_version] = tenantParts;
        return { id, key, name, assessment_version: Number(assessment_version) };
      });
    };

    return {
      name: payload.display_name,
      userId: payload.user_id,
      roleId: payload.role_id,
      avatarUrl: payload.avatar_url,
      tenants: parseFormattedTenants(payload.tenant),
      expiryTime: payload.exp,
    };
  };

  const domainForCookie = window.location.hostname.split('.').slice(1).join('.');

  const getMSUntilEpoch = (t: number): number => {
    const currentTime = Math.floor(Date.now() / 1000);
    return (t - currentTime) * 1000;
  };

  // When user goes offline, log them out
  useEffect(() => {
    if (!isOnline) {
      logout();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  // Logout user by destroying their cookies
  const logout = useCallback(() => {
    removeCookie(COOKIE_TOKEN, { path: '/', domain: domainForCookie });
  }, [removeCookie]);

  // Set Authorization header for Axios
  const setAuthAxiosHeader = (token: string): Promise<void> => {
    return new Promise((resolve) => {
      API.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      resolve();
    });
  };

  // Set Authorization token cookie, Axios Auth Header, and parse/load user info from token
  const setAuthToken = useCallback(async (token: string) => {
    await setAuthAxiosHeader(token);
    const userInfo = getUserInfoAndExpiryTime(token);
    setUser(userInfo);
    setExpTime(userInfo.expiryTime);
    const secondsUntilExpiration = getMSUntilEpoch(userInfo.expiryTime) / 1000;
    setCookie(COOKIE_TOKEN, token, { maxAge: secondsUntilExpiration, path: "/", domain: domainForCookie });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set tenant key header for Axios
  const setTenantHeader = useCallback(() => {
    if (tenantKey) {
      API.defaults.headers.common['tenant-key'] = tenantKey;
    } else {
      delete API.defaults.headers.common['tenant-key'];
    }
  }, [tenantKey]);

  // Refresh Auth token
  const refreshAuthToken = useCallback(async () => {
    if (!cookies.token) return;
    try {
      const response = await refreshAuth();
      await setAuthToken(response.data.accessToken);
      refreshRetries = 0;
    } catch (error) {
      console.error('Failed to refresh token', error);
      const was401Response = (error instanceof AxiosError && error.response && error.response.status === 401) || false;
      if (!was401Response) {
        if (refreshRetries < MAX_REFRESH_RETRIES) {
          refreshRetries++;
          refreshAuthToken();
        } else {
          logout();
        }
      }
    }
  }, [cookies.token, logout, setAuthToken]);

  // Initialize auth token/loading status
  useEffect(() => {
    setTenantHeader();
    if (cookies.token) {
      setAuthToken(cookies.token);
      setIsLoading(false);
    } else {
      delete API.defaults.headers.common['Authorization'];
      setIsLoading(false);
    }
  }, [cookies.token, setAuthToken, tenantKey, setTenantHeader]);

  // Refresh token 1 minute before it expires
  useEffect(() => {
    if (expTime) {
      // Use interval instead of timeout, since browsers can pause timeouts when tab is inactive/suspended
      // Interval will check actual expiry time so that when resuming a tab, it will have recent/accurate time
      const intervalRef = setInterval(() => {
        const msUntilRefreshNeeded = getMSUntilEpoch(expTime) - ONE_MINUTE_IN_MS;
        if (msUntilRefreshNeeded < 0) {
          refreshAuthToken();
          clearInterval(intervalRef);
        }
      }, 1000);

      return () => {
        if (intervalRef) {
          clearInterval(intervalRef);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expTime]);

  // Logout if user is idle
  useIdleTimer({
    timeout: TIMEOUT_MINUTES * ONE_MINUTE_IN_MS,
    onIdle: () => {
      if (cookies.token) {
        logout();
        showNotification();
      }
    },
  });

  // Logout if API responds with 401 status
  useEffect(() => {
    const interceptor = API.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          logout();
          showNotification();
          //navigate(AppRoutes.Login, { state: {error: 'Your session has timed out. Please log in again.' } });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      API.interceptors.response.eject(interceptor);
    };
  }, [logout]);

  // Setup Context for Auth
  const contextValue = useMemo(
    () => ({
      token: cookies.token,
      user,
      isLoading,
      setAuthToken,
      refreshAuthToken,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cookies.token, user, isLoading]
  );

  return <AuthContext.Provider value={contextValue}>{!isLoading && children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
