import { useState } from 'react';

import { changePatientStatus } from 'services/api';
import { createNotification } from 'components/ui';
import { PatientRecord } from 'record/patient.record';
import { useLoadingWithErrorHandling } from 'hooks/useLoadingWithErrorHandling';

export const useRestorePatient = (patient: Partial<PatientRecord>, refetch?: () => void) => {
  const [isShowRestoreModal, setIsShowRestoreModal] = useState(false);
  const { submitting, setSubmitting, showErrorModal } = useLoadingWithErrorHandling();

  const restorePatient = async () => {
    if (!patient.id) return;

    try {
      setSubmitting(true);
      await changePatientStatus(patient.id, true);
      if (refetch) {
        refetch();
      }
      createNotification({
        type: 'success',
        title: `The patient ID: ${patient.refId} was successfully restored`,
      });
    } catch (error) {
      console.error(error);
      showErrorModal();
    } finally {
      setSubmitting(false);
      setIsShowRestoreModal(false);
    }
  };

  return { restorePatient, isShowRestoreModal, setIsShowRestoreModal, submitting };
};
