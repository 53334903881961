import React from 'react';
import { BaseButton } from 'components/ui/buttons';
import { BaseModal } from 'components/ui';
import { PlayIcon } from 'assets/icons';
import startTourImage from '../../assets/images/start-tour.png';

type StartTourModalProps = {
  title: string;
  description: string;
  onCancelClick: () => void;
  onStartTourClick: () => void;
};

export const StartTourModal: React.FC<StartTourModalProps> = ({
  title,
  description,
  onCancelClick,
  onStartTourClick,
}) => {
  return (
    <BaseModal isShownCloseButton onCloseClick={onCancelClick}>
      <div className="w-[470px] flex flex-col items-center">
        <img src={startTourImage} alt="start-tour" className="pb-4 h-auto max-w-[240px]" />
        <h2 className="flex items-center mb-1.5 text-left text-gray-900 dark:text-white-400 text-[19px] font-semibold leading-7">
          {title}
        </h2>
        <p className="mb-8 text-left text-gray-900 dark:text-gray-200 text-sm font-normal leading-[130%]">
          {description}
        </p>

        <div className="flex flex-col gap-4">
          <BaseButton scheme="cta-primary" size="xl" onClick={onStartTourClick} icon={PlayIcon} isIconOnLeftSide>
            Start tour
          </BaseButton>
          <BaseButton scheme="transparent" onClick={onCancelClick}>
            Skip tour
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
};
