import axios, { AxiosInstance } from 'axios';
import { AssessmentResultRecord } from 'record/assessment-result.record';
import { OnboardingResultRecord } from 'record/onboarding-result.record';
import { Drugs, RecommendationAcceptanceType } from 'types/assessment';
import { UpdatePatientPayloadType } from 'types/patient';

type LoginAuthPayload = {
  loginId: string;
  tenantKey: string;
};

const API: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

//Health
export const healthCheck = () => {
  return API.head('/Health');
};

//Authentication
export const loginAuth = (payload: LoginAuthPayload) => {
  return API.post('/Authentication/Login', payload);
};

export const refreshAuth = () => {
  return API.post('/Authentication/Renew');
};

// Assessment
export const getAssessment = (version: number) => {
  return API.get(`/Assessment/${version}/ForWeb`);
};

// AssessmentResult
export const getAssessmentResultsByPatientId = (patientId: string) => {
  return API.get(`/AssessmentResult/${patientId}`);
};

export const getLastAssessmentResultsByPatientId = (patientId: string) => {
  return API.get(`/AssessmentResult/${patientId}/Last`, {
    validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
  });
};

export const getUserTotalCompletedAssessments = () => {
  return API.get(`/AssessmentResult/TotalCompleted`);
};

export const postAssessmentResult = (body: AssessmentResultRecord) => {
  return API.post(`/AssessmentResult`, body);
};

// Patient
export const createPatient = (body: Omit<OnboardingResultRecord, 'transferred' | 'weight_unit' | 'time_option'>) => {
  return API.post('/Patient', body);
};

export const searchPatients = (body: any) => {
  return API.post('/Patient/Search', body);
};

export const postClonidineTrialedOffByPatientId = (patientId: string, clonidineDose: number) => {
  return API.post(`/Patient/${patientId}/CheckHasClonidineBeenTrialedOff`, {
    dose: clonidineDose,
  });
};

export const postCheckDrugChanges = (
  patientId: string,
  clonidineDose: number,
  clonidineFrequency: number,
  morphineDose: number,
) => {
  return API.post(`/Patient/${patientId}/CheckDrugChanges`, {
    drugs: {
      [Drugs.Clonidine]: {
        dose: clonidineDose,
        frequency: clonidineFrequency,
      },
      [Drugs.Morphine]: {
        dose: morphineDose,
      },
    },
  });
};

export const getPatientInformation = (patientId: string) => {
  return API.get(`/Patient/${patientId}`);
};

export const updatePatientInformation = (patientId: string, payload: UpdatePatientPayloadType) => {
  return API.put(`/Patient/${patientId}`, payload);
};

export const changePatientStatus = (patientId: string, payload: boolean) => {
  return API.put(`/Patient/${patientId}/Status`, { is_active: payload });
};

export const assignPatient = (patientUUID: string) => {
  return API.put(`/Patient/${patientUUID}/Assign`);
};

export const getGeneratedPatientId = () => {
  return API.get('/Patient/GenerateId');
};

export const updateTreatmentRecommendationAcceptance = (body: RecommendationAcceptanceType) => {
  return API.post('/AssessmentResultDrug/UpdateRecommendationAcceptance', body);
};

export const updateComfortRecommendationAcceptance = (body: RecommendationAcceptanceType) => {
  return API.post('/AssessmentResultComfortRecommendation/UpdateRecommendationAcceptance', body);
};

export default API;
