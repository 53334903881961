import React from 'react';

import { BabyWellIcon, BabyWorseIcon, BabyGoodIcon } from 'assets/icons';
import { changeDirection } from 'types/assessment';

type StabilityIconProps = {
  levelDirection: string | null;
};

export const StabilityIcon: React.FC<StabilityIconProps> = ({ levelDirection }) => {
  if (levelDirection === changeDirection.increase) {
    return <BabyWellIcon />;
  } else if (levelDirection === changeDirection.decrease) {
    return <BabyWorseIcon />;
  } else {
    return <BabyGoodIcon />;
  }
};
