import React, { useState, useMemo } from 'react';

import dayjs from 'dayjs';
import * as Yup from 'yup';
import cn from 'classnames';
import { useFormik } from 'formik';

import { ProgressBar } from 'components/ui';
import { createPatient } from 'services/api';
import { BaseButton } from 'components/ui/buttons';
import { LeftIcon, RightIcon } from 'assets/icons';
import { Header } from 'components/details-pages/Header';
import { BornDateStep } from 'components/assessment/steps/BornDateStep';
import { GenerateIDStep } from 'components/assessment/steps/GenerateIDStep';
import { DrugsDetailStep } from 'components/assessment/steps/DrugsDetailStep';
import { useLoadingWithErrorHandling } from 'hooks/useLoadingWithErrorHandling';
import { PatientAddedSuccess } from 'components/assessment/PatientAddedSuccess';
import { AssessmentHeader } from 'components/assessment/common/AssessmentHeader';
import { PatientWeightStep } from 'components/assessment/steps/PatientWeightStep';
import { YesNoQuestionStep } from 'components/assessment/steps/YesNoQuestionStep';
import {
  OnboardingResultRecord,
  Narcotics,
  NORMAL_MORPHINE_MIN_DOSE_MG_KG,
  NORMAL_MORPHINE_MAX_DOSE_MG_KG,
  NORMAL_CLONIDINE_MIN_DOSE_MCG_KG,
  NORMAL_CLONIDINE_MAX_DOSE_MCG_KG,
} from 'record/onboarding-result.record';
import { PatientNameEntryStep } from 'components/assessment/steps/PatientNameEntryStep';
import { SubstanceExposureStep } from 'components/assessment/steps/SubstanceExposureStep';
import { AxiosResponse } from 'axios';

enum OnboardingQuestionSteps {
  GenerateID = 1,
  PatientNameEntry = 2,
  PatientWeight = 3,
  BornDate = 4,
  SubstanceExposure = 5,
  Transfer = 6,
  DrugsDetail = 7,
}

const enumKeys = Object.keys(OnboardingQuestionSteps).filter((key) => isNaN(Number(key)));
const lastStepKey = enumKeys[enumKeys.length - 1];
const LAST_STEP = OnboardingQuestionSteps[lastStepKey as keyof typeof OnboardingQuestionSteps];

export type OnboardingValidateSchemaType = {
  givenName: string;
  familyName: string;
  otherNarcotic: string | null;
};

type DrugStepRefType = {
  showWarning: () => void;
};

// Define validation schema

export const OnboardingQuestionnaire: React.FC = () => {
  const onboardingSteps = Object.keys(OnboardingQuestionSteps).length / 2;

  const [step, setStep] = useState(OnboardingQuestionSteps.GenerateID);
  const [isPatientAdded, setIsPatientAdded] = useState(false);
  const [patientId, setPatientId] = useState<string | null>(null);
  const [answers, setAnswers] = useState<OnboardingResultRecord>(new OnboardingResultRecord());
  const { submitting, setSubmitting, showErrorModal, ErrorModalComponent } = useLoadingWithErrorHandling();

  const drugStep = React.createRef<DrugStepRefType>();

  const onboardingValidationSchema = Yup.object().shape({
    givenName: Yup.string().max(256, 'First name cannot exceed 256 characters'),
    familyName: Yup.string().max(256, 'Last name cannot exceed 256 characters'),
    otherNarcotic: Yup.string()
      .nullable()
      .test('otherNarcotic', 'Other opiates is required', function (value) {
        const { otherNarcotic } = this.parent;
        return !(otherNarcotic !== null && !value);
      })
      .max(256, 'Other opiates cannot exceed 256 characters'),
  });

  const questionnaireProgress = useMemo(() => {
    return (step / onboardingSteps) * 100;
  }, [step, onboardingSteps]);

  const formik = useFormik<OnboardingValidateSchemaType>({
    initialValues: {
      givenName: '',
      familyName: '',
      otherNarcotic: null,
    },
    validationSchema: onboardingValidationSchema,
    onSubmit: () => {},
  });

  const drugStepProperty = {
    transferred: answers.transferred,
    hasEverHadMorphine: answers.has_ever_had_morphine,
    hasEverHadClonidine: answers.has_ever_had_clonidine,
  };

  const checkIfHaveErrors = (fields: string[]) =>
    fields.some((field) => formik.errors[field as keyof OnboardingValidateSchemaType]);

  const morphineDoseWithinNormalRange = (): boolean => {
    return answers.morphine_dosage_at_the_previous_hospital_mg
      ? answers.morphine_dosage_at_the_previous_hospital_mg >= NORMAL_MORPHINE_MIN_DOSE_MG_KG &&
          answers.morphine_dosage_at_the_previous_hospital_mg <= NORMAL_MORPHINE_MAX_DOSE_MG_KG
      : true;
  };

  const clonidineDoseWithinNormalRange = (): boolean => {
    return answers.clonidine_dosage_at_the_previous_hospital_mcg
      ? answers.clonidine_dosage_at_the_previous_hospital_mcg >= NORMAL_CLONIDINE_MIN_DOSE_MCG_KG &&
          answers.clonidine_dosage_at_the_previous_hospital_mcg <= NORMAL_CLONIDINE_MAX_DOSE_MCG_KG
      : true;
  };

  const createNewPatient = async () => {
    try {
      setSubmitting(true);
      const { transferred, time_option, ...answersBody } = answers;
      const { data }: AxiosResponse<string> = await createPatient({
        ...answersBody,
        datetime_of_birth: dayjs(answers.datetime_of_birth).toISOString(),
        birth_weight_kg: answers.birth_weight_kg,
        has_taken_long_acting_opioids:
          answers.narcotic_ids?.includes(Narcotics.Methadone) ||
          answers.narcotic_ids?.includes(Narcotics['Bupernophine (Subutex/Suboxone)']) ||
          false,
        morphine_dosage_at_the_previous_hospital_mg: answers.morphine_dosage_at_the_previous_hospital_mg,
        clonidine_dosage_at_the_previous_hospital_mcg: answers.clonidine_dosage_at_the_previous_hospital_mcg,
        clonidine_frequency_at_the_previous_hospital: answers.clonidine_frequency_at_the_previous_hospital,
      });
      setPatientId(data);
      setIsPatientAdded(true);
    } catch (error) {
      console.log(error);
      showErrorModal();
    } finally {
      setSubmitting(false);
    }
  };

  const handleNextStep = async () => {
    if (step === OnboardingQuestionSteps.PatientNameEntry || step === OnboardingQuestionSteps.SubstanceExposure) {
      const fieldsToCheck = ['givenName', 'familyName', 'otherNarcotic'];
      if (step === OnboardingQuestionSteps.SubstanceExposure) {
        formik.setFieldTouched('otherNarcotic', formik.values.otherNarcotic !== null);
      }
      if (checkIfHaveErrors(fieldsToCheck)) {
        return;
      }

      setAnswers((prevState) => ({
        ...prevState,
        family_name: formik.values.familyName.trim(),
        given_name: formik.values.givenName.trim(),
        other_narcotics: formik.values.otherNarcotic ? [formik.values.otherNarcotic.trim()] : null,
      }));
    } else if (step === LAST_STEP) {
      const drugsDosesWithinNormalRange = morphineDoseWithinNormalRange() && clonidineDoseWithinNormalRange();

      if (!drugsDosesWithinNormalRange) {
        drugStep.current?.showWarning();
        return;
      }

      await createNewPatient();
      return;
    }
    setStep((currentStep) => (currentStep < onboardingSteps ? currentStep + 1 : currentStep));
  };

  const handleBackStep = () => {
    setStep((currentStep) => (currentStep !== OnboardingQuestionSteps.GenerateID ? currentStep - 1 : 0));
  };

  const isNextStepDisabled = (): boolean => {
    switch (step) {
      case OnboardingQuestionSteps.GenerateID:
        return !answers.ref_id;
      case OnboardingQuestionSteps.PatientNameEntry:
        return !formik.values.givenName || !formik.values.familyName;
      case OnboardingQuestionSteps.PatientWeight:
        return !answers.birth_weight_kg;
      case OnboardingQuestionSteps.BornDate:
        return !answers.datetime_of_birth;
      case OnboardingQuestionSteps.Transfer:
        return answers.transferred === null;
      default:
        return false;
    }
  };

  const updateDrugAnswers = (
    hasEverHadMorphine: boolean | null,
    hasEverHadClonidine: boolean | null,
    morphineDosage: number | null,
    clonidineDosage: number | null,
    clonidineFrequency: number | null,
  ) => {
    setAnswers((prevState) => ({
      ...prevState,
      has_ever_had_morphine: hasEverHadMorphine ?? prevState.has_ever_had_morphine,
      has_ever_had_clonidine: hasEverHadClonidine ?? prevState.has_ever_had_clonidine,
      morphine_dosage_at_the_previous_hospital_mg:
        morphineDosage ?? prevState.morphine_dosage_at_the_previous_hospital_mg,
      clonidine_dosage_at_the_previous_hospital_mcg:
        clonidineDosage ?? prevState.clonidine_dosage_at_the_previous_hospital_mcg,
      clonidine_frequency_at_the_previous_hospital:
        clonidineFrequency ?? prevState.clonidine_frequency_at_the_previous_hospital,
    }));
  };

  const renderQuestion = () => {
    switch (step) {
      case OnboardingQuestionSteps.GenerateID:
        return (
          <GenerateIDStep
            refId={answers.ref_id}
            onChange={(id) => setAnswers((prevState) => ({ ...prevState, ref_id: id }))}
          />
        );
      case OnboardingQuestionSteps.PatientNameEntry:
        return <PatientNameEntryStep formik={formik} />;
      case OnboardingQuestionSteps.PatientWeight:
        return (
          <PatientWeightStep
            birthWeightKg={answers.birth_weight_kg}
            onChangeWeight={(value) => setAnswers((prevState) => ({ ...prevState, birth_weight_kg: value }))}
          />
        );
      case OnboardingQuestionSteps.BornDate:
        return (
          <BornDateStep
            bornDate={answers.datetime_of_birth}
            timeOption={answers.time_option}
            setTimeOption={(value) => setAnswers((prevState) => ({ ...prevState, time_option: value }))}
            onChangeBornDate={(date) =>
              setAnswers((prevState) => ({ ...prevState, datetime_of_birth: date ? date.toString() : null }))
            }
          />
        );
      case OnboardingQuestionSteps.SubstanceExposure:
        return (
          <SubstanceExposureStep
            narcoticIds={answers.narcotic_ids}
            otherNarcotic={answers.other_narcotics}
            opioidsPostDelivery={answers.was_exposed_to_opioids_post_delivery}
            onChangeNarcotics={(value) => setAnswers((prevState) => ({ ...prevState, narcotic_ids: value }))}
            onChangeOpioidsPostDelivery={(value) =>
              setAnswers((prevState) => ({ ...prevState, was_exposed_to_opioids_post_delivery: value }))
            }
            nextStep={handleNextStep}
            formik={formik}
          />
        );
      case OnboardingQuestionSteps.Transfer:
        return (
          <YesNoQuestionStep
            currentAnswer={answers.transferred}
            title="Was the child transferred from another hospital?"
            onSelect={(isTransferred) => setAnswers((prevState) => ({ ...prevState, transferred: isTransferred }))}
          />
        );
      case OnboardingQuestionSteps.DrugsDetail:
        return (
          <DrugsDetailStep
            ref={drugStep}
            data={drugStepProperty}
            onUpdate={updateDrugAnswers}
            onContinue={createNewPatient}
          />
        );
      default:
        return <div>No questions</div>;
    }
  };

  return (
    <div className="relative flex flex-col">
      <Header />
      <div className="flex flex-col content-between w-full max-w-[768px] mx-auto min-h-[calc(100vh-173px)]">
        {isPatientAdded && answers.ref_id ? (
          <PatientAddedSuccess patientRefId={answers.ref_id} patientId={patientId} />
        ) : (
          <>
            <div className="py-6 px-10">
              <AssessmentHeader />
              {renderQuestion()}
            </div>
          </>
        )}
        {ErrorModalComponent}
      </div>
      {!isPatientAdded && (
        <div className="sticky bottom-0 px-10 py-6 flex justify-between items-center bg-white-300 dark:bg-blue-500 border-t-[1px] border-gray-200 dark:border-white-400/10">
          <BaseButton
            className={cn({ 'opacity-0 pointer-events-none': step === OnboardingQuestionSteps.GenerateID })}
            disabled={step === OnboardingQuestionSteps.GenerateID}
            scheme="cta-secondary"
            size="xl"
            isIconOnLeftSide
            icon={LeftIcon}
            onClick={handleBackStep}
          >
            Back
          </BaseButton>
          <div className="w-full max-w-[160px]">
            <ProgressBar progress={questionnaireProgress} />
          </div>
          <div className="flex gap-3 items-center">
            {step === OnboardingQuestionSteps.PatientNameEntry && (
              <BaseButton scheme="cta-secondary" size="xl" onClick={() => setStep((prevState) => prevState + 1)}>
                Skip
              </BaseButton>
            )}
            <BaseButton
              scheme="cta-primary"
              size="xl"
              icon={step !== LAST_STEP ? RightIcon : undefined}
              isLoading={submitting}
              disabled={isNextStepDisabled()}
              onClick={handleNextStep}
            >
              {step === LAST_STEP ? 'Finish' : 'Next'}
            </BaseButton>
          </div>
        </div>
      )}
    </div>
  );
};
