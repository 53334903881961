export const rosterTourSteps = [
  {
    target: '.theme-switch',
    title: 'You can choose dark or light mode',
    content:
      'Personalize your browsing experience by selecting light for a crisp, minimalist vibe or dark for a sleek, immersive ambiance. ',
    disableBeacon: true,
    placement: 'left' as const,
    styles: {
      spotlight: {
        borderRadius: 21,
      },
    },
  },
  {
    target: '.lock-screen',
    title: 'Lock the screen when your session is complete',
    content:
      'Ensure your privacy and security by locking the screen when you finish your session, safeguarding patient data.',
    disableBeacon: true,
    placement: 'left' as const,
    styles: {
      spotlight: {
        borderRadius: 50,
      },
    },
  },
  {
    target: '.add-patient',
    title: 'Add a patient',
    content: 'When a patient is ready for their first assessment, start by adding them to the system.',
    disableBeacon: true,
    placement: 'left' as const,
    styles: {
      spotlight: {
        borderRadius: 12,
      },
    },
  },
  {
    target: '.group-tabs',
    title: 'View different groups of patients',
    content: 'Efficiently navigate patients by navigating between their assigned provider.',
    disableBeacon: true,
    placement: 'right' as const,
    styles: {
      spotlight: {
        borderRadius: 12,
      },
    },
  },
  {
    target: '.nurse',
    title: 'Each patient belongs to a nurse',
    content: 'View your own patients, or your colleagues.',
    disableBeacon: true,
    placement: 'bottom-start' as const,
    styles: {
      spotlight: {
        borderRadius: 12,
      },
    },
  },
  {
    target: '.stability-level',
    title: 'Patient stability level',
    content: "Quickly view patient's progress based on previous assessments",
    disableBeacon: true,
    placement: 'bottom-start' as const,
    styles: {
      spotlight: {
        borderRadius: 12,
      },
    },
  },
  {
    target: '.assign-patient',
    title: 'You can assign patients to yourself',
    content: 'Quick and convenient ability to assign patients between shifts.',
    disableBeacon: true,
    placement: 'left' as const,
    styles: {
      spotlight: {
        borderRadius: 12,
      },
    },
  },
  {
    target: '.restore-patient',
    title: 'You can restore former patients',
    content:
      'If a patient has stopped and then is restarting assessments, use this function to bring them back to your roster.',
    disableBeacon: true,
    placement: 'left' as const,
    styles: {
      spotlight: {
        borderRadius: 12,
      },
    },
  },
];
