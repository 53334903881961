import React, { useCallback, useImperativeHandle } from 'react';

import dayjs from 'dayjs';
import { useFormik } from 'formik';

import {
  Narcotics,
  NORMAL_CLONIDINE_MAX_DOSE_MCG_KG,
  NORMAL_CLONIDINE_MIN_DOSE_MCG_KG,
  NORMAL_MORPHINE_MAX_DOSE_MG_KG,
  NORMAL_MORPHINE_MIN_DOSE_MG_KG,
} from 'record/onboarding-result.record';
import { MorphineRecStep } from './steps/archive/MorphineRecStep';
import { useAssessment } from 'contexts/AssessmentContextProvider';
import { ReasonStep } from 'components/assessment/steps/ReasonStep';
import { BornDateStep } from 'components/assessment/steps/BornDateStep';
import { SymptomsStep } from 'components/assessment/steps/SymptomsStep';
import { DrugsDetailStep } from 'components/assessment/steps/DrugsDetailStep';
import { DateQuestionStep } from 'components/assessment/steps/DateQuestionStep';
import { YesNoQuestionStep } from 'components/assessment/steps/YesNoQuestionStep';
import { PatientWeightStep } from 'components/assessment/steps/PatientWeightStep';
import { PatientNameEntryStep } from 'components/assessment/steps/PatientNameEntryStep';
import { SubstanceExposureStep } from 'components/assessment/steps/SubstanceExposureStep';
import { OnboardingValidateSchemaType } from 'components/assessment/OnboardingQuestionnaire';
import { useNetworkCheck } from 'contexts/NetworkContextProvider';
import * as Yup from 'yup';

export enum CommonQuestionSteps {
  PatientName = 1,
  BirthWeight,
  OpiatesExposure,
  BornDate,
  Transfer,
  CurrentClonidineMorphineDose,
  LastKnowAssessment,
  ReasonForSkippedAssessment,
  HasPriorAssessment,
  AssessmentSymptoms,
  LastClonidineChange,
  HasIncreasedClonidine,
  HasClonidineBeenTrialedOff,
  LastMorphineChange,
  LastMorphineRecommendation,
}

type StepHandlerFuncType = () => number | null;
type StepHandlerType = {
  [key: number]: StepHandlerFuncType;
};

type CommonQuestionnaireProps = {
  relativeStep: number;
  proceedToNextStep: (forceNextStep?: boolean) => void;
};

type DrugStepRefType = {
  showWarning: () => void;
};

export const CommonQuestionnaire = React.forwardRef<unknown, CommonQuestionnaireProps>((props, ref) => {
  const { assessmentData, setAssessmentData, setAssessmentResult, assessmentResult, currentStep } = useAssessment();

  const { isOnline } = useNetworkCheck();

  const drugStep = React.createRef<DrugStepRefType>();

  const onboardingValidationSchema = Yup.object().shape({
    givenName: Yup.string().max(256, 'First name cannot exceed 256 characters'),
    familyName: Yup.string().max(256, 'Last name cannot exceed 256 characters'),
    otherNarcotic: Yup.string()
      .nullable()
      .test('otherNarcotic', 'Other opiates is required', function (value) {
        const { otherNarcotic } = this.parent;
        return !(otherNarcotic !== null && !value);
      })
      .max(256, 'Other opiates cannot exceed 256 characters'),
  });

  const formik = useFormik<OnboardingValidateSchemaType>({
    initialValues: {
      givenName: '',
      familyName: '',
      otherNarcotic: null,
    },
    validationSchema: onboardingValidationSchema,
    onSubmit: () => {},
  });

  const handlePatientNameOrOpiatesExposure = useCallback(() => {
    const fieldsToCheck = ['givenName', 'familyName', 'otherNarcotic'];

    if (currentStep === CommonQuestionSteps.OpiatesExposure) {
      formik.setFieldTouched('otherNarcotic', formik.values.otherNarcotic !== null);
    }

    const hasErrors = fieldsToCheck.some((field) => formik.errors[field as keyof OnboardingValidateSchemaType]);

    if (!hasErrors) {
      if (currentStep === CommonQuestionSteps.PatientName) {
        setAssessmentData((current) => {
          return {
            ...current,
            familyName: formik.values.familyName.trim(),
            givenName: formik.values.givenName.trim(),
          };
        });
      } else if (currentStep === CommonQuestionSteps.OpiatesExposure) {
        setAssessmentData((current) => {
          return {
            ...current,
            hasTakenLongActingOpiods:
              assessmentData.narcoticIds?.includes(Narcotics.Methadone) ||
              assessmentData.narcoticIds?.includes(Narcotics['Bupernophine (Subutex/Suboxone)']) ||
              false,
            otherNarcotics: formik.values.otherNarcotic ? [formik.values.otherNarcotic.trim()] : null,
          };
        });
      }

      return currentStep + 1;
    }

    return currentStep;
  }, [formik, setAssessmentData, currentStep, assessmentData.narcoticIds]);

  const changePriorAssessmentStatus = useCallback(() => {
    setAssessmentData((current) => {
      return {
        ...current,
        isPriorAssessmentDone: true,
      };
    });
    return Object.keys(CommonQuestionSteps).length / 2;
  }, [setAssessmentData]);

  const handleHasPriorAssessment = useCallback((): number | null => {
    if (!assessmentData.hasPriorAssessment) {
      changePriorAssessmentStatus();
      return null;
    } else {
      return CommonQuestionSteps.AssessmentSymptoms;
    }
  }, [changePriorAssessmentStatus, assessmentData]);

  const shouldNavigateToMainAssessment = useCallback(() => {
    return (
      (!assessmentData.hasEverHadClonidine || assessmentData.clonidineDose === 0) &&
      (!assessmentData.hasEverHadMorphine || assessmentData.morphineDose === 0)
    );
  }, [assessmentData]);

  const handleAssessmentSymptoms = useCallback((): number | null => {
    if (shouldNavigateToMainAssessment()) {
      changePriorAssessmentStatus();
      return null;
    } else {
      return assessmentData.hasEverHadClonidine || assessmentData.clonidineDose > 0
        ? CommonQuestionSteps.LastClonidineChange
        : CommonQuestionSteps.LastMorphineChange;
    }
  }, [shouldNavigateToMainAssessment, changePriorAssessmentStatus, assessmentData]);

  const handleLastClonidineChange = useCallback((): number | null => {
    if (shouldNavigateToMainAssessment()) {
      changePriorAssessmentStatus();
      return null;
    } else {
      return assessmentData.hasEverHadClonidine || assessmentData.clonidineDose > 0
        ? CommonQuestionSteps.HasIncreasedClonidine
        : CommonQuestionSteps.LastMorphineChange;
    }
  }, [shouldNavigateToMainAssessment, changePriorAssessmentStatus, assessmentData]);

  const handleHasIncreasedClonidine = useCallback((): number | null => {
    if (shouldNavigateToMainAssessment()) {
      changePriorAssessmentStatus();
      return null;
    } else {
      return assessmentData.hasEverHadClonidine || assessmentData.clonidineDose > 0
        ? CommonQuestionSteps.HasClonidineBeenTrialedOff
        : CommonQuestionSteps.LastMorphineChange;
    }
  }, [shouldNavigateToMainAssessment, changePriorAssessmentStatus, assessmentData]);

  const handleOtherCases = useCallback((): number | null => {
    return currentStep + 1;
  }, [currentStep]);

  const handleDrugsChange = useCallback((): number | null => {
    const morphineDoseInRange =
      assessmentData.morphineDose >= NORMAL_MORPHINE_MIN_DOSE_MG_KG &&
      assessmentData.morphineDose <= NORMAL_MORPHINE_MAX_DOSE_MG_KG;

    const clonidineDoseInRange =
      assessmentData.clonidineDose >= NORMAL_CLONIDINE_MIN_DOSE_MCG_KG &&
      assessmentData.clonidineDose <= NORMAL_CLONIDINE_MAX_DOSE_MCG_KG;
    if (
      (assessmentData.morphineDose !== 0 && !morphineDoseInRange) ||
      (assessmentData.clonidineDose !== 0 && !clonidineDoseInRange)
    ) {
      drugStep.current?.showWarning();
      return currentStep;
    } else {
      return isOnline ? currentStep + 1 : CommonQuestionSteps.HasPriorAssessment;
    }
  }, [currentStep, assessmentData, drugStep, isOnline]);

  const handleLastMorphineChange = useCallback((): number | null => {
    if (assessmentResult && assessmentResult.has_missing_assessment) {
      changePriorAssessmentStatus();
      return null;
    } else {
      return CommonQuestionSteps.LastMorphineRecommendation;
    }
  }, [changePriorAssessmentStatus, assessmentResult]);

  const handleLastKnowAssessment = useCallback((): number | null => {
    if (assessmentResult && assessmentResult.has_missing_assessment) {
      changePriorAssessmentStatus();
      return null;
    } else {
      return CommonQuestionSteps.ReasonForSkippedAssessment;
    }
  }, [changePriorAssessmentStatus, assessmentResult]);

  const handleReasonForSkippedAssessment = useCallback((): number | null => {
    return CommonQuestionSteps.HasPriorAssessment;
  }, []);

  const handleLastMorphineRecommendation = useCallback((): number | null => {
    changePriorAssessmentStatus();
    return null;
  }, [changePriorAssessmentStatus]);

  const handleStep = useCallback(
    (step: number) => {
      const stepHandlers: StepHandlerType = {
        [CommonQuestionSteps.PatientName]: handlePatientNameOrOpiatesExposure,
        [CommonQuestionSteps.BirthWeight]: handleOtherCases,
        [CommonQuestionSteps.OpiatesExposure]: handlePatientNameOrOpiatesExposure,
        [CommonQuestionSteps.BornDate]: handleOtherCases,
        [CommonQuestionSteps.Transfer]: handleOtherCases,
        [CommonQuestionSteps.CurrentClonidineMorphineDose]: handleDrugsChange,
        [CommonQuestionSteps.LastKnowAssessment]: handleLastKnowAssessment,
        [CommonQuestionSteps.ReasonForSkippedAssessment]: handleReasonForSkippedAssessment,
        [CommonQuestionSteps.HasClonidineBeenTrialedOff]: handleOtherCases,
        [CommonQuestionSteps.HasPriorAssessment]: handleHasPriorAssessment,
        [CommonQuestionSteps.AssessmentSymptoms]: handleAssessmentSymptoms,
        [CommonQuestionSteps.LastClonidineChange]: handleLastClonidineChange,
        [CommonQuestionSteps.HasIncreasedClonidine]: handleHasIncreasedClonidine,
        [CommonQuestionSteps.LastMorphineChange]: handleLastMorphineChange,
        [CommonQuestionSteps.LastMorphineRecommendation]: handleLastMorphineRecommendation,
      };
      return stepHandlers[step]();
    },
    [
      handlePatientNameOrOpiatesExposure,
      handleOtherCases,
      handleDrugsChange,
      handleLastKnowAssessment,
      handleReasonForSkippedAssessment,
      handleHasPriorAssessment,
      handleAssessmentSymptoms,
      handleLastClonidineChange,
      handleHasIncreasedClonidine,
      handleLastMorphineChange,
      handleLastMorphineRecommendation,
    ],
  );

  useImperativeHandle(
    ref,
    () => ({
      handleNextStep: () => {
        const step = handleStep(currentStep);
        if (step) {
          return step;
        }
        return null;
      },
    }),
    [handleStep, currentStep],
  );

  const drugStepProperty = {
    transferred: assessmentData.isTransferred,
    hasEverHadMorphine: assessmentData.hasEverHadMorphine,
    hasEverHadClonidine: assessmentData.hasEverHadClonidine,
  };

  const updateDrugAnswers = (
    hasEverHadMorphine: boolean | null,
    hasEverHadClonidine: boolean | null,
    morphineDosage: number | null,
    clonidineDosage: number | null,
    clonidineFrequency: number | null,
  ) => {
    setAssessmentData((prevState) => ({
      ...prevState,
      hasEverHadMorphine: hasEverHadMorphine ?? prevState.hasEverHadMorphine,
      hasEverHadClonidine: hasEverHadClonidine ?? prevState.hasEverHadClonidine,
      morphineDose: morphineDosage ?? prevState.morphineDose,
      clonidineDose: clonidineDosage ?? prevState.clonidineDose,
      clonidineFrequency: clonidineFrequency ?? prevState.clonidineFrequency,
    }));
  };

  const renderQuestion = () => {
    switch (currentStep) {
      case CommonQuestionSteps.CurrentClonidineMorphineDose:
        return (
          <DrugsDetailStep
            ref={drugStep}
            data={drugStepProperty}
            onUpdate={updateDrugAnswers}
            onContinue={() => props.proceedToNextStep(true)}
          />
        );
      case CommonQuestionSteps.PatientName:
        return <PatientNameEntryStep formik={formik} />;
      case CommonQuestionSteps.BornDate:
        return (
          <BornDateStep
            timeOption={assessmentData.timeOption}
            bornDate={assessmentData.datetimeOfBirth}
            setTimeOption={(value) =>
              setAssessmentData((current) => {
                return {
                  ...current,
                  timeOption: value,
                };
              })
            }
            onChangeBornDate={(date) =>
              setAssessmentData((current) => {
                return {
                  ...current,
                  datetimeOfBirth: date ? date.toString() : null,
                };
              })
            }
          />
        );
      case CommonQuestionSteps.BirthWeight:
        return (
          <PatientWeightStep
            birthWeightKg={assessmentData.birthWeightKg}
            onChangeWeight={(value) =>
              setAssessmentData((current) => {
                return {
                  ...current,
                  birthWeightKg: value,
                };
              })
            }
          />
        );
      case CommonQuestionSteps.OpiatesExposure:
        return (
          <SubstanceExposureStep
            narcoticIds={assessmentData.narcoticIds}
            otherNarcotic={assessmentData.otherNarcotics}
            opioidsPostDelivery={assessmentData.wasExposedToOpioidsPostDelivery}
            onChangeNarcotics={(value) =>
              setAssessmentData((current) => {
                return {
                  ...current,
                  narcoticIds: value,
                };
              })
            }
            onChangeOpioidsPostDelivery={(value) =>
              setAssessmentData((current) => {
                return {
                  ...current,
                  wasExposedToOpioidsPostDelivery: value,
                };
              })
            }
            nextStep={props.proceedToNextStep}
            formik={formik}
          />
        );
      case CommonQuestionSteps.Transfer:
        return (
          <YesNoQuestionStep
            currentAnswer={assessmentData.isTransferred}
            title="Was the child transferred from another hospital?"
            onSelect={(isTransferred) => {
              setAssessmentData((current) => {
                return {
                  ...current,
                  isTransferred: isTransferred,
                };
              });
            }}
          />
        );
      case CommonQuestionSteps.LastKnowAssessment:
        const lastAssessmentDate = dayjs(assessmentData.lastKnownAssessmentDate).format('DD/MM/YYYY HH:mm');

        return (
          <YesNoQuestionStep
            currentAnswer={assessmentResult ? assessmentResult.has_missing_assessment : null}
            title={`The last known assessment was ${lastAssessmentDate}, which is greater than the expected time between assessments. Was this the last known assessment?`}
            onSelect={(value) => {
              setAssessmentResult((current) => {
                return { ...current, has_missing_assessment: value };
              });
            }}
          />
        );

      case CommonQuestionSteps.ReasonForSkippedAssessment:
        return (
          <ReasonStep
            currentReason={assessmentResult?.missing_assessment_reason}
            onChange={(value) =>
              setAssessmentResult((prevState) => ({ ...prevState, missing_assessment_reason: value }))
            }
          />
        );

      case CommonQuestionSteps.HasPriorAssessment:
        return (
          <YesNoQuestionStep
            currentAnswer={assessmentData.hasPriorAssessment}
            title="Has an AssessPro assessment been completed for this infant before?"
            onSelect={(value) => {
              setAssessmentData((current) => {
                return {
                  ...current,
                  hasPriorAssessment: value,
                  isFirstAssessment: !value
                };
              });
            }}
          />
        );

      case CommonQuestionSteps.AssessmentSymptoms:
        return (
          <SymptomsStep
            symptoms={assessmentData.symptoms}
            onChange={(value) =>
              setAssessmentData((current) => {
                return {
                  ...current,
                  symptoms: assessmentData.symptoms?.includes(value)
                    ? assessmentData.symptoms.filter((s) => s !== value)
                    : [...(assessmentData.symptoms || []), value],
                };
              })
            }
          />
        );

      case CommonQuestionSteps.LastClonidineChange:
        return (
          <DateQuestionStep
            title="When was Clonidine dose last changed?"
            date={assessmentData.changedClonidineDate}
            onChangeDate={(date) =>
              setAssessmentData((current) => {
                return {
                  ...current,
                  changedClonidineDate: date ? date.toString() : null,
                };
              })
            }
          />
        );

      case CommonQuestionSteps.HasIncreasedClonidine:
        return (
          <YesNoQuestionStep
            currentAnswer={assessmentData.clonidineIncreasedInLastSixHours}
            title="Has Clonidine increased in the past 6 hours?"
            onSelect={(value) => {
              setAssessmentData((current) => {
                return {
                  ...current,
                  clonidineIncreasedInLastSixHours: value,
                  isLastAssessmentRecommendationAnIncrease: false,
                };
              });
            }}
          />
        );
      case CommonQuestionSteps.HasClonidineBeenTrialedOff:
        return (
          <YesNoQuestionStep
            currentAnswer={assessmentData.hasClonidineBeenTrialedOff}
            title="Has Clonidine been trialed off?"
            onSelect={(value) => {
              setAssessmentData((current) => {
                return {
                  ...current,
                  hasClonidineBeenTrialedOff: value,
                };
              });
            }}
          />
        );

      case CommonQuestionSteps.LastMorphineChange:
        return (
          <DateQuestionStep
            title="When was Morphine dose last changed?"
            date={assessmentData.changedMorphineDate}
            onChangeDate={(date) =>
              setAssessmentData((current) => {
                return {
                  ...current,
                  changedMorphineDate: date ? date.toString() : null,
                };
              })
            }
          />
        );
      case CommonQuestionSteps.LastMorphineRecommendation:
        return (
          <MorphineRecStep
            recommendedMorphine={assessmentData.lastRecommendedMorphine}
            onSelect={(lastRecommendation) =>
              setAssessmentData((current) => {
                return {
                  ...current,
                  lastRecommendedMorphine: lastRecommendation,
                };
              })
            }
          />
        );

      default:
        return <div>No questions</div>;
    }
  };

  return <>{renderQuestion()}</>;
});
