import React, { useMemo, useState } from 'react';
import cn from 'classnames';

import { ActionModal } from 'components/ui';
import { BaseButton } from 'components/ui/buttons';
import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon } from 'assets/icons';
import { getComfortMeasures } from 'algorithms/comfort-measures-algorithm';
import { useLoadingWithErrorHandling } from 'hooks/useLoadingWithErrorHandling';
import { RecommendationDeclineModal } from 'components/assessment-details/RecommendationDeclineModal';
import { updateComfortRecommendationAcceptance, updateTreatmentRecommendationAcceptance } from 'services/api';
import {
  AssessmentResultType,
  ComfortMeasures,
  ComfortRecommendations,
  Drugs,
  RecommendationAcceptanceType,
} from 'types/assessment';
import { ClonidineRecommendation } from 'algorithms/clonidine-algorithm';
import { MorphineRecommendation } from 'algorithms/morphine-algorithm';

const STANDARD_COMFORT_MEASURES = [
  'Family Engagement',
  'Minimal Stimulation',
  'Quiet/Low Light Environment',
  'Pacifier',
  'Swaddle',
  'Early Intervention',
  'Bundle Assessments',
  'C-Position',
];

type RecommendationProps = {
  name: string;
  id: string;
  currentResult: AssessmentResultType;
  className?: string;
  afterUpdate?: () => void;
  isComfortMeasures: boolean;
  isShowControls?: boolean;
  comfortRecommendations: ComfortRecommendations[];
};

export const Recommendation: React.FC<RecommendationProps> = ({
  id,
  name,
  className,
  currentResult,
  afterUpdate,
  isComfortMeasures,
  isShowControls,
  comfortRecommendations,
}) => {
  const [isShowDeclineModal, setShowDeclineModal] = useState(false);
  const [isShowCancelModal, setShowCancelModal] = useState(false);

  const { submitting, setSubmitting, showErrorModal, ErrorModalComponent } = useLoadingWithErrorHandling();

  const getDrugRecommendation = useMemo(() => {
    if (!currentResult) return 'Not Started';

    const drug = currentResult.assessment_result_drug.find((drug) => drug.drug_id === id);
    return drug?.recommendation || 'Not Started';
  }, [currentResult, id]);

  const getDrugRecommendationDirection = useMemo(() => {
    if (!currentResult) return 0;

    const drug = currentResult.assessment_result_drug.find((drug) => drug.drug_id === id);
    if (drug?.drug_id === Drugs.Clonidine) {
      const clonidineRecommendationKey = drug.recommendation_coded as keyof typeof ClonidineRecommendation;
      if (!clonidineRecommendationKey) return 0;

      const clonidineRecommendation = ClonidineRecommendation[clonidineRecommendationKey];
      switch (clonidineRecommendation) {
        case ClonidineRecommendation.Decrease:
          return -1;
        case ClonidineRecommendation.Increase:
          return 1;
        default:
          return 0;
      }
    }
    if (drug?.drug_id === Drugs.Morphine) {
      const morphineRecommendationKey = drug.recommendation_coded as keyof typeof MorphineRecommendation;
      if (!morphineRecommendationKey) return 0;

      const morphineRecommendation = MorphineRecommendation[morphineRecommendationKey];
      switch (morphineRecommendation) {
        case MorphineRecommendation.Decrease:
          return -1;
        case MorphineRecommendation.Increase:
          return 1;
        default:
          return 0;
      }
    }
  }, [currentResult, id]);

  const generateCSIComfortMeasures = useMemo(() => {
    if (!currentResult) return [];
    return getComfortMeasures(currentResult.assessment_result_symptom, comfortRecommendations);
  }, [currentResult, comfortRecommendations]);

  const generateStandardComfortMeasures = useMemo(() => {
    return STANDARD_COMFORT_MEASURES.filter((measure) => !generateCSIComfortMeasures.includes(measure));
  }, [generateCSIComfortMeasures]);

  const currentDrug = currentResult.assessment_result_drug.find((drug) => drug.drug_id === id);
  const currentMeasure = currentResult.comfortRecommendations.find((measure) => measure.type_id === id);
  const declineReasons = isComfortMeasures ? currentMeasure?.decline_reasons || [] : currentDrug?.decline_reasons || [];

  const handleRecommendationAcceptance = async (reasons: string[], isAccepted: boolean | null) => {
    if (!currentResult) return;

    const payload: RecommendationAcceptanceType = {
      assessment_result_id: currentResult.id,
      has_accepted_recommendation: isAccepted,
      decline_reasons: reasons,
      update_only_decline_reasons: false,
      ...(isComfortMeasures ? { recommendation_type_id: id } : { drug_id: id }),
    };

    try {
      setSubmitting(true);
      isComfortMeasures
        ? await updateComfortRecommendationAcceptance(payload)
        : await updateTreatmentRecommendationAcceptance(payload);
      afterUpdate && afterUpdate();
    } catch (error) {
      showErrorModal();
      console.error('Failed to decline recommendation', error);
    } finally {
      setShowDeclineModal(false);
      setSubmitting(false);
    }
  };

  const handleCancelRecommendation = async () => {
    await handleRecommendationAcceptance([], null);
    setShowCancelModal(false);
  };

  const currentMeasuresList =
    isComfortMeasures && id === ComfortMeasures['Standard Comfort Measures']
      ? generateStandardComfortMeasures
      : generateCSIComfortMeasures;

  const RenderIconByRecommendation = () => {
    switch (getDrugRecommendationDirection) {
      case 1:
        return <ArrowUpIcon />;
      case -1:
        return <ArrowDownIcon />;
      default:
        return <ArrowRightIcon />;
    }
  };

  const RenderDeclinedAction = () => (
    <div className="flex justify-between items-center gap-4 px-6 py-3">
      <div className="flex items-center gap-2.5">
        <div className="flex flex-col items-center bg-red-400 px-2.5 py-1 rounded-full text-xs text-white-400">
          Declined
        </div>
        <BaseButton scheme="text" size="none" onClick={() => setShowDeclineModal(true)}>
          View/edit reason ({declineReasons.length})
        </BaseButton>
      </div>
      <BaseButton className="!px-0" scheme="text" onClick={() => setShowCancelModal(true)}>
        Cancel
      </BaseButton>
    </div>
  );

  const RenderPendingAction = () => (
    <div className="flex gap-4 px-6 py-4">
      <BaseButton scheme="reject" size="none" onClick={() => setShowDeclineModal(true)}>
        Decline
      </BaseButton>
      <BaseButton scheme="accept" size="none" onClick={() => handleRecommendationAcceptance([], true)}>
        Accept
      </BaseButton>
    </div>
  );

  const RenderAcceptedAction = () => (
    <div className="flex justify-between items-center gap-4 px-6 py-3">
      <div className="flex flex-col items-center bg-green-400 px-2.5 py-1 rounded-full text-xs text-white-400">
        Accepted
      </div>
      <BaseButton className="!px-0" scheme="text" onClick={() => setShowCancelModal(true)}>
        Cancel
      </BaseButton>
    </div>
  );

  const RenderActionByRecommendation = () => {
    if (!isShowControls || (isComfortMeasures && !currentMeasuresList.length)) return null;

    const currentState = isComfortMeasures
      ? currentMeasure?.has_accepted_recommendation
      : currentDrug?.has_accepted_recommendation;

    switch (currentState) {
      case true:
        return <RenderAcceptedAction />;
      case false:
        return <RenderDeclinedAction />;
      default:
        return <RenderPendingAction />;
    }
  };

  const RenderComfortMeasuresList = () => {
    return (
      <ul>
        {currentMeasuresList.map((comfortMeasure) => (
          <li
            key={comfortMeasure}
            className={cn(
              'mb-2 pl-2.5 relative last:mb-0 text-gray-900 dark:text-white-400 text-sm font-normal leading-[130%] before:empty-content',
              'before:block before:absolute before:bg-sky before:w-1 before:h-1 before:rounded-full before:left-0 before:top-1/2 before:mt-[-2px]'
            )}
          >
            {comfortMeasure}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <div
        className={cn(
          'flex flex-col justify-between border border-gray-200 dark:border-white-400/[.07] bg-white-400 dark:bg-transparent rounded-lg border-solid',
          className ? className : 'w-1/2'
        )}
      >
        {isComfortMeasures ? (
          <div
            className={cn('flex flex-col h-full gap-4 px-6 py-4', {
              'border-b border-gray-200 dark:border-white-400/[.10]': isShowControls && currentMeasuresList.length,
            })}
          >
            <h3 className="text-gray-900 dark:text-white-400 text-base font-semibold leading-7">{name}</h3>
            <RenderComfortMeasuresList />
          </div>
        ) : (
          <div
            className={cn('flex items-center justify-between w-full gap-4 px-6 py-4', {
              'border-b border-gray-200 dark:border-white-400/[.10]': isShowControls,
            })}
          >
            <p className="text-gray-900 dark:text-white-400 text-base font-semibold leading-7">{name}</p>
            <div className="pl-2.5 pr-3 py-[5px] rounded-full text-gray-900 dark:text-silver-400 text-[13px] font-medium leading-[17px] flex items-center gap-1 border border-gray-800 border-solid">
              <RenderIconByRecommendation />
              <span className="first-letter:uppercase lowercase">{getDrugRecommendation}</span>
            </div>
          </div>
        )}
        <RenderActionByRecommendation />
      </div>

      {isShowDeclineModal && (
        <RecommendationDeclineModal
          reasons={declineReasons}
          isComfortMeasures={isComfortMeasures}
          onCancelClick={() => setShowDeclineModal(false)}
          onDeclineClick={handleRecommendationAcceptance}
          isSubmitting={submitting}
        />
      )}

      {isShowCancelModal && (
        <ActionModal
          title="Are you sure you want to cancel?"
          description={`Are you sure you want to change your answer regarding ${name.toLowerCase()} recommendation`}
          successText={'Yes, cancel'}
          onCancelClick={() => setShowCancelModal(false)}
          onSuccessClick={handleCancelRecommendation}
          isSubmitting={submitting}
        />
      )}

      {ErrorModalComponent}
    </>
  );
};
