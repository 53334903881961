import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes } from 'routes/app-routes';
import CacheBuster from 'react-cache-buster';
import reportWebVitals from './reportWebVitals';
import { AuthProvider, NetworkProvider, TenancyProvider, ThemeProvider, UsersnapProvider } from 'contexts';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const version = require('../package.json').version;
const enableCacheBuster = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test';

// Logic to handle cache clearing
const handleCacheClear = () => {
  console.log('Cache cleared!');
};

root.render(
  <React.StrictMode>
    <TenancyProvider>
      <ThemeProvider>
        <CacheBuster
          currentVersion={version}
          isEnabled={enableCacheBuster}
          isVerboseMode={false}
          metaFileDirectory={'.'}
          onCacheClear={handleCacheClear}
        >
          <NetworkProvider>
            <AuthProvider>
              <UsersnapProvider>
                <Routes />
              </UsersnapProvider>
            </AuthProvider>
          </NetworkProvider>
        </CacheBuster>
      </ThemeProvider>
    </TenancyProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
