import React from 'react';
import cn from 'classnames';

import { BaseModal } from 'components/ui';
import { WristbandIcon } from 'assets/icons';
import { BaseButton } from 'components/ui/buttons';

type QRTemplatesModalProps = {
  onCancelClick: () => void;
  onPrintClick: () => void;
  refId: number;
};

const TEMPLATES = [
  {
    id: '1',
    name: '16-2x2.6',
    counts: 16,
    blockClass: 'w-[16px] h-[20px]',
    gridClass: 'px-[5px] py-[9px] grid-rows-4 grid-cols-4 gap-[2px]',
  },
  {
    id: '2',
    name: '8-2 5x3.6',
    counts: 8,
    blockClass: 'w-[34px] h-[20px]',
    gridClass: 'px-[5px] py-[9px] grid-rows-4 grid-cols-2 gap-[2px]',
  },
  {
    id: '3',
    name: '20-2x2',
    counts: 9,
    blockClass: 'w-[22px] h-[27px]',
    gridClass: 'px-[5px] py-[9px] grid-rows-3 grid-cols-3 gap-[2px]',
  },
  {
    id: '4',
    name: '9-25x3.26',
    counts: 9,
    blockClass: 'w-[20px] h-[29px]',
    gridClass: 'p-[5px] grid-rows-3 grid-cols-3 gap-[4px]',
  },
  {
    id: '5',
    name: '4-3x5',
    counts: 4,
    blockClass: 'w-[33px] h-[43px]',
    gridClass: 'px-[5px] py-[6px] grid-rows-2 grid-cols-2 gap-[4px]',
  },
  {
    id: '6',
    name: '3-3x5',
    counts: 3,
    blockClass: 'w-[40px] h-[27px]',
    gridClass: 'px-[20px] py-[9px] grid-rows-3 grid-cols-1 gap-[2px]',
  },
  {
    id: '7',
    name: '3x5',
    counts: 1,
    blockClass: 'w-[27px] h-[40px]',
    gridClass: 'px-[28px] py-[26px] grid-rows-1 grid-cols-1 gap-[4px]',
  },
];

export const QRTemplatesModal: React.FC<QRTemplatesModalProps> = ({ onCancelClick, onPrintClick, refId }) => {
  const [selectedTemplate, setSelectedTemplate] = React.useState<string | null>(null);
  return (
    <BaseModal isShownCloseButton onCloseClick={onCancelClick}>
      <div className="flex flex-col mt-4 items-center w-[460px] text-center">
        <h2 className="mb-4 text-gray-900 dark:text-white-400 text-[28px] font-semibold leading-[normal]">
          Print QR code for patient ID:&nbsp;{refId}
        </h2>
        <p className="mb-4 text-sky dark:text-silver-400 text-center text-sm font-semibold leading-[140%]">
          Choose Print Layout:
        </p>

        <div className="grid grid-rows-2 grid-cols-4 gap-4">
          {TEMPLATES.map((template) => (
            <button
              type="button"
              key={template.id}
              className={cn(
                'flex flex-col max-h-[140px] items-center gap-1 border p-2 rounded-lg border-solid hover:bg-white-300',
                selectedTemplate === template.id
                  ? 'border-sea-serpent bg-mint dark:bg-mint/20'
                  : 'bg-white-400 dark:bg-transparent border-gray-200 dark:border-sky',
              )}
              onClick={() => setSelectedTemplate(template.id)}
            >
              <p className="text-silver-800 dark:text-white-400 text-xs font-semibold leading-[17px]">
                {template.name}
              </p>
              <span className={cn('w-full grid', template.gridClass)}>
                {Array(template.counts)
                  .fill(null)
                  .map((_, index) => (
                    <span
                      key={index}
                      className={cn(
                        selectedTemplate === template.id ? 'bg-teal-300' : 'bg-iron dark:bg-sky',
                        template.blockClass,
                      )}
                    />
                  ))}
              </span>
            </button>
          ))}
          <button
            type="button"
            className={cn(
              'flex flex-col max-h-[140px] items-center gap-1 border p-2 rounded-lg border-solid hover:bg-white-300',
              selectedTemplate === 'wristband'
                ? 'border-sea-serpent bg-mint dark:bg-mint/20'
                : 'bg-white-400 dark:bg-transparent border-gray-200 dark:border-sky',
            )}
            onClick={() => setSelectedTemplate('wristband')}
          >
            <p className="text-silver-800 dark:text-white-400 text-xs font-semibold leading-[17px]">
              Hospital Wristband
            </p>
            <span className={cn('w-full grid ', selectedTemplate === 'wristband' ? 'text-teal-300' : 'text-gray-800')}>
              <WristbandIcon />
            </span>
          </button>
        </div>

        <div className="mt-8 w-full flex items-center justify-end gap-4">
          <BaseButton scheme="secondary" onClick={onCancelClick}>
            Cancel
          </BaseButton>
          <BaseButton scheme="primary" size="md" onClick={onPrintClick} disabled={!selectedTemplate}>
            Print
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
};
