import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { changePatientStatus } from 'services/api';
import useClickOutside from 'hooks/useClickOutside';
import { useAuth } from 'contexts/AuthContextProvider';
import { LogoIcon, LogoDarkIcon } from 'assets/images';
import { useTenantRedirect } from 'hooks/useTenantRedirect';
import { TenantSelector } from 'components/ui/TenantSelector';
import { DARK_MODE_CLASS, useTheme } from 'contexts/ThemeContextProvider';
import { BaseButton, IconButton } from 'components/ui/buttons';
import { QRModal } from 'components/details-pages/modals/QRModal';
import { useNetworkCheck } from 'contexts/NetworkContextProvider';
import { PatientMedicalRecord } from 'record/patient-medical.record';
import { AppRoutes, replaceRouteParamWithValue } from 'routes/app-routes';
import { ActionModal, createNotification, ThemeSwitch } from 'components/ui';
import { useLoadingWithErrorHandling } from 'hooks/useLoadingWithErrorHandling';
import { QRTemplatesModal } from 'components/details-pages/modals/QRTamplatesModal';
import { StopAssessmentDate } from 'components/assessment/common/StopAssessmentDate';
import { AssignPatientButton } from 'components/assessment-details/AssignPatientButton';
import { CloseCircleIcon, LeftIcon, LockIcon, MoreIcon, OfflineIcon, PlayIcon, UndoIcon } from 'assets/icons';

type HeaderProps = {
  isDiscontinue?: boolean;
  onDiscontinue?: () => void;
  onBeforeAssessmentAction?: () => void;
  patientData?: PatientMedicalRecord | null;
};

export const Header: React.FC<HeaderProps> = ({
  patientData,
  isDiscontinue,
  onDiscontinue,
  onBeforeAssessmentAction,
}) => {
  const [isShowQRModal, setShowQRModal] = useState(false);
  const [isShowDropdown, setShowDropdown] = useState(false);
  const [isShowRestoreModal, setIsShowRestoreModal] = useState(false);
  const [isShowQRTemplateModal, setShowQRTemplateModal] = useState(false);
  const [isShowStopAssessmentModal, setShowStopAssessmentModal] = useState(false);

  const dropdownRef = useClickOutside<HTMLDivElement>(isShowDropdown, () => setShowDropdown(false));
  const redirectToTenant = useTenantRedirect();
  const { isOnline } = useNetworkCheck();

  const { theme } = useTheme();
  const { submitting, setSubmitting, showErrorModal, ErrorModalComponent } = useLoadingWithErrorHandling();

  const { logout } = useAuth();
  const { id: patientId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleRouteToAssessment = () => {
    const route = replaceRouteParamWithValue(AppRoutes.Assessment, 'id', patientId);
    navigate(route);
  };

  const handleRouteToPatientAssessmentDetails = () => {
    if (!patientData) return;

    const route = replaceRouteParamWithValue(AppRoutes.CurrentPatient, 'id', patientId);
    navigate(route);
  };

  const startAssessment = () => {
    if (isDiscontinue && onDiscontinue) {
      onDiscontinue();
      return;
    }

    if (onBeforeAssessmentAction) {
      onBeforeAssessmentAction();
      return;
    }
    handleRouteToAssessment();
  };

  const restorePatient = async () => {
    if (!patientData || !patientId) return;

    try {
      setSubmitting(true);
      await changePatientStatus(patientId, true);
      navigate(AppRoutes.Roster);
      createNotification({
        type: 'success',
        title: `Patient ID: ${patientData.ref_id} has been restored and assigned to you`,
      });
    } catch (error) {
      console.error(error);
      showErrorModal();
    } finally {
      setSubmitting(false);
      setIsShowRestoreModal(false);
    }
  };

  const stopAssessment = async () => {
    if (!patientData || !patientId) return;

    try {
      setSubmitting(true);
      await changePatientStatus(patientId, false);
      navigate(AppRoutes.Roster);
      createNotification({
        type: 'success',
        title: `Assessment for patient ID: ${patientData.ref_id} successfully discontinued`,
      });
    } catch (error) {
      console.error(error);
      showErrorModal();
    } finally {
      setSubmitting(false);
      setShowDropdown(false);
    }
  };

  return (
    <>
      <div className="px-4 py-3.5 flex flex-col shadow-header bg-white-400 dark:bg-gray-900">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            {theme === DARK_MODE_CLASS ? (
              <LogoDarkIcon className="max-w-[150px]" />
            ) : (
              <LogoIcon className="max-w-[150px]" />
            )}
            {user?.tenants && user.tenants.length > 1 && (
              <TenantSelector
                tenants={user.tenants}
                onChange={(value) => {
                  redirectToTenant(value as string);
                }}
              />
            )}
          </div>
          <div className="flex gap-2 items-center">
            {!isOnline && (
              <IconButton disabled scheme="offline" icon={OfflineIcon} iconProps={{ className: '' }} type="button" />
            )}
            <ThemeSwitch isDarkMode={theme === 'dark'} />
            <IconButton
              className="lock-screen"
              icon={LockIcon}
              iconProps={{ className: 'dark:[&>g>path]:fill-white-400' }}
              type="button"
              onClick={logout}
            />
          </div>
        </div>

        {ErrorModalComponent}

        {isShowRestoreModal && (
          <ActionModal
            title={`Are you sure you want to restore patient ID: ${patientData?.ref_id}?`}
            description="After restoring, the patient will re-appear in your active roster."
            additionalComponent={
              patientData?.stop_assessment_date && <StopAssessmentDate date={patientData?.stop_assessment_date} />
            }
            onCancelClick={() => setIsShowRestoreModal(false)}
            onSuccessClick={restorePatient}
            successText="Yes, restore and assign"
            isSubmitting={submitting}
          />
        )}

        {isShowQRModal && patientData && (
          <QRModal
            refId={patientData.ref_id}
            age={patientData.getAge()}
            onPrintClick={() => {
              setShowQRModal(false);
              setShowQRTemplateModal(true);
            }}
            onCancelClick={() => setShowQRModal(false)}
          />
        )}

        {isShowQRTemplateModal && patientData && (
          <QRTemplatesModal
            refId={patientData.ref_id}
            onCancelClick={() => {
              setShowQRTemplateModal(false);
            }}
            onPrintClick={() => {}}
          />
        )}

        {patientData && (
          <div className="pt-4 pb-2 w-full max-w-[768px] mx-auto">
            <div className="flex justify-between items-center">
              <BaseButton
                className="!rounded-full"
                scheme="secondary"
                size="sm"
                icon={LeftIcon}
                isIconOnLeftSide
                onClick={() => navigate(AppRoutes.Roster)}
              >
                Back
              </BaseButton>
              {patientData.stop_assessment_date && <StopAssessmentDate date={patientData.stop_assessment_date} />}
            </div>
            <div className="flex justify-between">
              <div className="patient-id flex pt-4 items-center gap-3">
                <button
                  type="button"
                  onClick={() => setShowQRModal(true)}
                  className="w-16 h-16 bg-demo-qr dark:bg-demo-qr-dark bg-center bg-no-repeat border border-gray-200 dark:border-transparent bg-white-400 dark:bg-gray-700 p-2 rounded-xl border-solid"
                />
                <div>
                  <h2 className="text-gray-900 dark:text-white-400 text-[28px] font-semibold leading-[normal]">
                    ID: {patientData.ref_id}
                  </h2>
                  <p className="text-gray-500 dark:text-silver-400 text-sm font-medium leading-[140%]">
                    {patientData.getAge()}
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-3">
                {patientData.stop_assessment_date ? (
                  <BaseButton
                    className="border-2"
                    scheme="cta-secondary"
                    onClick={() => setIsShowRestoreModal(true)}
                    icon={UndoIcon}
                    isIconOnLeftSide
                  >
                    Restore
                  </BaseButton>
                ) : (
                  <div className="flex flex-col items-end gap-[18px]">
                    <div className="relative" ref={dropdownRef}>
                      <IconButton
                        className="dark:text-white-400"
                        icon={MoreIcon}
                        scheme="transparent"
                        onClick={() => setShowDropdown((prev) => !prev)}
                      />
                      {isShowDropdown && (
                        <div className="absolute top-full right-0 flex w-[224px] mt-1 p-0.5 pr-0 border border-mercury bg-white-400 dark:bg-gray-900 shadow-dropdown rounded-lg border-solid fadeIn">
                          <BaseButton
                            className="!text-red-400 text-xs"
                            scheme="transparent"
                            icon={CloseCircleIcon}
                            isIconOnLeftSide
                            onClick={() => {
                              setShowStopAssessmentModal(true);
                              setShowDropdown(false);
                            }}
                          >
                            Discontinue assessments
                          </BaseButton>
                        </div>
                      )}

                      {isShowStopAssessmentModal && (
                        <ActionModal
                          title={`Are you sure you want to discontinue assessments for patient ID: ${patientData.ref_id}?`}
                          description='The patient will be moved to the "Former Patients" section, and will no longer show up on your active roster. You can always re-activate them later.'
                          onCancelClick={() => setShowStopAssessmentModal(false)}
                          onSuccessClick={stopAssessment}
                          successText="Yes, discontinue"
                          isSubmitting={submitting}
                        />
                      )}
                    </div>
                    <BaseButton
                      className="start-new-assessment"
                      scheme="cta-primary"
                      icon={PlayIcon}
                      isIconOnLeftSide
                      onClick={startAssessment}
                    >
                      {patientData.is_assigned_to_the_user ? 'Start new' : ''}
                    </BaseButton>
                    {!patientData.is_assigned_to_the_user && (
                      <AssignPatientButton
                        patient={{ id: patientId || '', refId: patientData.ref_id }}
                        buttonScheme="cta-stroke"
                        afterAssign={handleRouteToPatientAssessmentDetails}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
