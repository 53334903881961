import React, { HTMLAttributes } from 'react';
import { BaseModal } from './BaseModal';
import { BaseButton } from 'components/ui/buttons';
import cn from 'classnames';

type MessageModalProps = {
  title: string;
  description: string | React.ReactElement | React.ReactElement[];
  icon?: React.ComponentType<HTMLAttributes<SVGElement>>;
  iconProps?: React.HTMLAttributes<SVGAElement>;
  isShownCloseButton?: boolean;
  successText?: string;
  cancelText?: string;
  onSuccessClick?: () => void;
  onCancelClick?: () => void;
};

export const MessageModal: React.FC<MessageModalProps> = ({
  title,
  description,
  successText = 'Yes, continue',
  cancelText = 'Cancel',
  onSuccessClick,
  onCancelClick,
  icon: Icon,
  iconProps,
  isShownCloseButton = true,
}) => {
  return (
    <BaseModal isShownCloseButton={isShownCloseButton} onCloseClick={onCancelClick}>
      <div className="max-w-[500px] px-8 flex flex-col items-center">
        {Icon ? <Icon {...iconProps} className={cn('mb-6', iconProps?.className)} /> : null}
        <h2 className="text-center mb-4 text-gray-600 dark:text-white-400 text-[19px] font-semibold leading-7">
          <span>{title}</span>
        </h2>
        <p className="mb-8 text-gray-600 dark:text-gray-200 text-sm font-normal leading-[140%]">{description}</p>

        <div className="flex gap-4 w-full justify-center">
          {isShownCloseButton && (
            <BaseButton scheme="secondary" className="w-full" onClick={onCancelClick}>
              {cancelText}
            </BaseButton>
          )}
          <BaseButton scheme="primary" className={cn(isShownCloseButton ? 'w-full' : '')} onClick={onSuccessClick}>
            {successText}
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
};
