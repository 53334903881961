import { ComfortRecommendations } from 'types/assessment';

type SymptomScore = {
  symptom_id: string;
  score: number;
};

// comfortRecommendations get from AssessmentContext -> Assessment Definition
export const getComfortMeasures = (
  symptomScores: SymptomScore[],
  comfortRecommendations: ComfortRecommendations[]
): string[] => {
  const recommendations = new Set<string>();

  const symptomToRecommendation = comfortRecommendations.reduce((acc, { symptom_id, text }) => {
    acc[symptom_id] = text;
    return acc;
  }, {} as { [key: string]: string });

  symptomScores.forEach(({ symptom_id, score }) => {
    if (score > 0 && symptom_id in symptomToRecommendation && !!symptomToRecommendation[symptom_id]) {
      recommendations.add(symptomToRecommendation[symptom_id]);
    }
  });

  return Array.from(recommendations);
};
