import React from 'react';
import { QuestionInfo } from 'components/assessment/common/QuestionInfo';
import { CheckboxField } from 'components/ui/form-fields';
import cn from 'classnames';
import { Symptoms } from 'types/assessment';

type SymptomsStepProps = {
  symptoms: string[] | null;
  onChange: (value: string) => void;
};

export const SymptomsStep: React.FC<SymptomsStepProps> = ({ symptoms, onChange }) => {
  const answers = [
    { label: 'Poor Feed', value: Symptoms.Feeding },
    { label: 'Poor Stool', value: Symptoms.Stools },
    { label: 'Poor Sleep', value: Symptoms.Sleep },
    { label: 'Crying', value: Symptoms.Crying },
    { label: 'Fever', value: Symptoms.Temperature },
    { label: 'Tremors', value: Symptoms.Tremors },
  ];
  return (
    <div>
      <QuestionInfo title="Please enter the prior AssessPro symptoms:" />
      {answers.map((answer) => (
        <div
          key={answer.value}
          className={cn(
            'flex items-center justify-between gap-3 mb-3 self-stretch border pl-5 pr-3 py-[22px] rounded-xl dark:hover:bg-white-400/10 dark:hover:border-white-400/20',
            symptoms?.includes(answer.value)
              ? 'border-gray-800 bg-white-400 dark:bg-white-400/20 dark:border-white-400/20'
              : 'bg-white-400/80 dark:bg-gray-900 border-transparent',
          )}
        >
          <CheckboxField
            label={answer.label}
            name="symptoms"
            value={answer.value}
            checked={symptoms?.includes(answer.value)}
            onChange={(event) => onChange(event.target.value)}
          />
        </div>
      ))}
    </div>
  );
};
