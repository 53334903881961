import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const MAX_HOURS_BEFORE_DAYS: number = 96;
const DAY_IN_HOURS: number = 24;

interface PatientRecordType {
  ref_id: number;
  datetime_of_birth: string | null;
  birth_weight_kg: number | null;
  family_name: string;
  given_name: string;
  has_clonidine_been_trialed_off: boolean;
  has_ever_had_clonidine: boolean;
  has_ever_had_morphine: boolean;
  has_taken_long_acting_opioids: boolean;
  is_assigned_to_the_user: boolean;
  narcotic_ids: string[] | null;
  other_narcotics: string[] | null;
  stop_assessment_date: string | null;
  morphine_dosage_at_the_previous_hospital_mg: number | null;
  clonidine_dosage_at_the_previous_hospital_mcg: number | null;
  clonidine_frequency_at_the_previous_hospital: number | null;
}

export class PatientMedicalRecord {
  _record: any;
  ref_id: number;
  datetime_of_birth: string | null;
  birth_weight_kg: number | null;
  family_name: string;
  given_name: string;
  has_clonidine_been_trialed_off: boolean;
  has_ever_had_clonidine: boolean;
  has_ever_had_morphine: boolean;
  has_taken_long_acting_opioids: boolean;
  is_assigned_to_the_user: boolean;
  narcotic_ids: string[] | null;
  other_narcotics: string[] | null;
  stop_assessment_date: string | null;
  morphine_dosage_at_the_previous_hospital_mg: number | null;
  clonidine_dosage_at_the_previous_hospital_mcg: number | null;
  clonidine_frequency_at_the_previous_hospital: number | null;

  constructor(record: Partial<PatientRecordType> = {}) {
    this._record = record;
    this.ref_id = record.ref_id || 0;
    this.datetime_of_birth = record.datetime_of_birth || null;
    this.birth_weight_kg = record.birth_weight_kg || null;
    this.has_clonidine_been_trialed_off = record.has_clonidine_been_trialed_off || false;
    this.has_ever_had_clonidine = record.has_ever_had_clonidine || false;
    this.has_ever_had_morphine = record.has_ever_had_morphine || false;
    this.has_taken_long_acting_opioids = record.has_taken_long_acting_opioids || false;
    this.family_name = record.family_name || '';
    this.given_name = record.given_name || '';
    this.is_assigned_to_the_user = record.is_assigned_to_the_user || false;
    this.narcotic_ids = record.narcotic_ids || null;
    this.other_narcotics = record.other_narcotics || null;
    this.stop_assessment_date = record.stop_assessment_date || null;
    this.morphine_dosage_at_the_previous_hospital_mg = record.morphine_dosage_at_the_previous_hospital_mg || null;
    this.clonidine_dosage_at_the_previous_hospital_mcg = record.clonidine_dosage_at_the_previous_hospital_mcg || null;
    this.clonidine_frequency_at_the_previous_hospital = record.clonidine_frequency_at_the_previous_hospital || null;
  }

  getAge(): string {
    const currentDate = dayjs();
    const patientDate = dayjs.utc(this.datetime_of_birth).local();

    const diffInHours = currentDate.diff(patientDate, 'hour', true);
    const diffInHoursRounded = diffInHours.toFixed(2);

    if (diffInHours > MAX_HOURS_BEFORE_DAYS) {
      const diffInDays = Math.floor(diffInHours / DAY_IN_HOURS);
      const remainingHours = diffInHours % DAY_IN_HOURS;
      const remainingHoursRounded = remainingHours.toFixed(2);
      return `${diffInDays} days ${remainingHoursRounded} hours old`;
    } else {
      return `${diffInHoursRounded} hours old`;
    }
  }
}
