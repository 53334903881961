import React, { useState } from 'react';
import dayjs from 'dayjs';

import { OptionType } from 'components/ui/form-fields';
import { AssessmentResultType } from 'types/assessment';
import { SortBySelector } from 'components/ui/SortBySelector';
import { PatientHistoryAssessmentItem } from './PatientHistoryAssessmentItem';

type PatientHistoryAssessmentsProps = {
  results: AssessmentResultType[];
  currentAssessmentResultId: string;
  selectResult: (resultId: string) => void;
};

export const PatientHistoryAssessments: React.FC<PatientHistoryAssessmentsProps> = ({
  results,
  currentAssessmentResultId,
  selectResult,
}) => {
  const sortByOptions: OptionType[] = [
    { value: 'newest', label: 'Newest' },
    { value: 'oldest', label: 'Oldest' },
  ];

  const [sortBy, setSortBy] = useState<OptionType['value']>(sortByOptions[0].value);
  const [assessmentResults, setAssessmentResults] = useState<AssessmentResultType[]>(results);

  const onSortByOptionChange = (sortByOption: string | number | null) => {
    setSortBy(sortByOption);

    setAssessmentResults((state) => {
      return sortByOption === sortByOptions[0].value
        ? [...state].sort((a, b) => dayjs(b.created).diff(dayjs(a.created)))
        : [...state].sort((a, b) => dayjs(a.created).diff(dayjs(b.created)));
    });
  };

  const isLatestAssessment = (index: number) => {
    const currentAssessmentCreatedDate = dayjs(assessmentResults[index].created);

    for (let i = 0; i < assessmentResults.length; i++) {
      if (i !== index) {
        const otherAssessmentCreatedDate = dayjs(assessmentResults[i].created);

        if (currentAssessmentCreatedDate.isBefore(otherAssessmentCreatedDate)) return false;
      }
    }

    return true;
  };

  return (
    <div className="assessment-history flex flex-col mb-4 mt-6 self-stretch bg-white-400 dark:bg-gray-900 shadow-section rounded-2xl">
      <div className="flex justify-between items-center pt-6 pb-4 px-6 border-b border-gray-200 dark:border-white-400/10">
        <div className="flex">
          <h2 className="text-gray-900 dark:text-white-400 text-base font-semibold leading-7">History Assessment</h2>
        </div>
        <SortBySelector options={sortByOptions} value={sortBy} onChange={onSortByOptionChange} />
      </div>
      <div className="flex flex-row justify-between bg-white-100 dark:bg-white-400/[.04] text-left text-sky dark:text-gray-800 text-xs font-medium leading-4 tracking-[0.6px] uppercase">
        <div className="w-1/2 px-6 py-3">Date/time</div>
        <div className="w-1/2 px-0 py-3">Stability level</div>
      </div>
      {assessmentResults.map((assessmentResult, index) => (
        <PatientHistoryAssessmentItem
          key={`${assessmentResult.created}${index}`}
          isLatest={isLatestAssessment(index)}
          isSelected={assessmentResult.id === currentAssessmentResultId}
          assessmentResult={assessmentResult}
          onChangeResult={selectResult}
        />
      ))}
    </div>
  );
};
