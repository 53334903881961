import React, { forwardRef } from 'react';

import cn from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { CalendarIcon } from 'assets/icons';
import './dateTimeField.css';

type InputSizeType = 'md' | 'lg';

type DateTimeFieldProps = {
  label?: string;
  placeholderFormat?: string;
  errorMessage?: string;
  wrapperClassName?: string;
  size?: InputSizeType;
  value?: Date | null;
  minTime?: Date | null;
  maxTime?: Date | null;
  maxDate?: Date | null;
  onChange: (value: Date | null) => void;
};

type CustomInputProps = {
  value: string;
  onClick: () => void;
};

const sizeClassMap: Record<InputSizeType, string> = {
  md: 'h-11 pl-3 py-2 rounded-md',
  lg: 'h-[68px] px-6 py-[18px] rounded-xl',
};

export const DateTimeField: React.FC<DateTimeFieldProps> = ({
  label,
  minTime,
  maxTime,
  maxDate,
  placeholderFormat = 'MM.DD.YYYY, HH:MM',
  size,
  wrapperClassName,
  errorMessage,
  value,
  onChange,
}) => {
  const sizeClass = sizeClassMap[size || 'lg'];
  const topClass = cn('absolute', label ? (size === 'md' ? 'top-6 right-3' : 'top-9 right-6') : 'top-5 right-6');

  const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(({ value, onClick }, ref) => (
    <div className={cn('relative flex justify-between', wrapperClassName)} onClick={onClick}>
      <label className="w-full flex flex-col mb-1 text-blue-500 text-xs font-medium leading-[17px] dark:text-white-400">
        {label}
        <input
          className={cn(
            'w-full bg-white-400 border border-gray-800 focus:border-gray-200',
            'dark:bg-white-400/10 dark:text-white-400 dark:placeholder-sky dark:disabled:bg-white-400/[.04]',
            'focus:shadow-hover-input dark:focus:shadow-hover-dark-input text-gray-600 text-sm font-normal leading-[140%]',
            sizeClass,
          )}
          placeholder={placeholderFormat}
          value={value}
          ref={ref}
          readOnly
        />
      </label>
      <CalendarIcon className={topClass} />
      {errorMessage && (
        <p className="absolute bottom-0 left-0 text-xs text-red-400 font-medium leading-[17px]">{errorMessage}</p>
      )}
    </div>
  ));

  return (
    <div className="date-time-field w-full">
      <DatePicker
        wrapperClassName="w-full"
        selected={value}
        onChange={(date) => onChange(date)}
        showTimeSelect
        timeIntervals={1}
        dateFormat={placeholderFormat}
        maxDate={maxDate}
        minTime={minTime || new Date(0)}
        maxTime={maxTime || new Date(24 * 60 * 60 * 1000 - 1)}
        customInput={<CustomInput value={value ? value.toString() : ''} onClick={() => {}} />}
      />
    </div>
  );
};
