import React from 'react';

import { BaseModal } from 'components/ui';
import { BaseButton } from 'components/ui/buttons';
import confirmImage from 'assets/images/confirm.png';

type RecommendationsModalProps = {
  onCloseClick: () => void;
  onContinueClick: () => void;
};

export const RecommendationsModal: React.FC<RecommendationsModalProps> = ({ onContinueClick, onCloseClick }) => {
  return (
    <BaseModal isShownCloseButton onCloseClick={onCloseClick}>
      <div className="max-w-[420px] px-6 flex flex-col items-center">
        <img src={confirmImage} alt="stop-assessment" className="pb-4 h-auto max-w-[240px]" />
        <h2 className="mb-1.5 text-left text-gray-900 dark:text-white-400 text-[19px] font-semibold leading-7">
          Please confirm
        </h2>
        <p className="mb-8 text-gray-900 dark:text-silver-400 text-center text-sm font-normal leading-[130%]">
          Please confirm implementation of recommendations from prior assessment
        </p>
        <div className="flex flex-col items-center gap-4">
          <BaseButton scheme="cta-primary" onClick={onContinueClick}>
            Continue
          </BaseButton>
          <BaseButton scheme="transparent" onClick={onCloseClick}>
            Later
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
};
