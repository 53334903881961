import React, { forwardRef } from 'react';
import cn from 'classnames';

import { badStabilityLevelLowerBound, changeDirection } from 'types/assessment';

type LevelIndicatorProps = {
  level: number;
  levelDirection: string;
  isLast?: boolean;
};

export const LevelIndicator = forwardRef<HTMLInputElement, LevelIndicatorProps>(
  ({ level, levelDirection, isLast }, ref) => {
    return (
      <div ref={ref} className={cn('flex items-center relative h-[56px] w-[66px]')}>
        <div
          className={cn(
            'flex justify-center items-center w-[50px] h-[50px] p-[9px] rounded-full border-4 border-solid',
            'border-white-400 dark:border-gray-900 text-white-400 text-center text-xl font-semibold leading-[17px] z-10 shadow-level',
            level >= badStabilityLevelLowerBound ? 'bg-red-400' : 'bg-green-400',
          )}
        >
          S{level}
        </div>
        {isLast && (
          <span
            className={cn(
              'absolute w-[17px] h-[17px] shadow-level',
              { 'top-[20px] left-[39px] rotate-45': levelDirection === changeDirection.noChange },
              { 'top-[5px] right-[17px] rotate-70': levelDirection === changeDirection.increase },
              { 'bottom-[5px] right-[17px] rotate-15': levelDirection === changeDirection.decrease },
              level >= badStabilityLevelLowerBound ? 'bg-red-400' : 'bg-green-400',
            )}
          />
        )}
      </div>
    );
  },
);
