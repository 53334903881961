import React from 'react';
import cn from 'classnames';

import { QrIcon } from 'assets/icons';
import { BaseButton } from 'components/ui/buttons';
import { getGeneratedPatientId } from 'services/api';
import { InputField } from 'components/ui/form-fields';
import { QuestionInfo } from 'components/assessment/common/QuestionInfo';
import { useLoadingWithErrorHandling } from 'hooks/useLoadingWithErrorHandling';

type GenerateIdProps = {
  refId: number | null;
  onChange: (id: number) => void;
};
export const GenerateIDStep: React.FC<GenerateIdProps> = ({ refId, onChange }) => {
  const { submitting, setSubmitting, showErrorModal, ErrorModalComponent } = useLoadingWithErrorHandling();

  const generateID = async () => {
    try {
      setSubmitting(true);
      const { data } = await getGeneratedPatientId();
      onChange(data);
    } catch (error) {
      showErrorModal();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <QuestionInfo title="To begin, generate a patient ID" />
      <div className="flex items-center justify-center flex-wrap md:flex-nowrap gap-4 md:justify-between self-stretch bg-white-400 dark:bg-white-400/10 p-4 rounded-xl">
        <div className="flex items-center justify-self-start">
          <div
            className={cn(
              'flex w-[52px] h-[52px] justify-center items-center gap-2.5 border border-gray-200 dark:border-gray-500 p-2 rounded-lg border-solid',
              refId ? 'text-sky dark:text-white-400' : 'text-silver-400 dark:text-sky',
            )}
          >
            <QrIcon />
          </div>

          <InputField
            className="placeholder:text-silver-400 dark:disabled:bg-transparent"
            value={refId ? refId : ''}
            name="id"
            disabled
            schema="id"
            placeholder="- - - - - - - -"
          />
        </div>
        <BaseButton
          isLoading={submitting}
          disabled={Boolean(refId)}
          className="min-w-[170px]"
          scheme="cta-primary"
          size="xl"
          onClick={generateID}
        >
          Generate ID
        </BaseButton>
      </div>

      {ErrorModalComponent}
    </div>
  );
};
