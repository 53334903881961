import React from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from 'components/details-pages/Header';
import { AssessmentProvider } from 'contexts/AssessmentContextProvider';

export const Assessment: React.FC = () => {
  return (
    <div className="flex flex-col content-between h-screen">
      <Header />
      <AssessmentProvider>
        <Outlet />
      </AssessmentProvider>
    </div>
  );
};
