import { AnswerType, AssessmentResultAnswerType, QuestionType } from 'types/assessment';
import { SymptomScore } from './types/symptom-score';

const getSymptomIds = (questions: QuestionType[]): string[] => {
  const symptomIds = questions.map((question) => question.symptom_id);
  return symptomIds.filter((symptomId, index) => symptomIds.indexOf(symptomId) === index);
};
const calculateScoreForSymptom = (
  symptomId: string,
  answers: AssessmentResultAnswerType[],
  questions: QuestionType[]
): number => {
  const relatedQuestionsBySymptom = questions.filter((question) => question.symptom_id === symptomId);

  return relatedQuestionsBySymptom.reduce((totalScore: number, relatedQuestion) => {
    const questionScore = relatedQuestion.answer_options
      .filter((answerOption: AnswerType) => answers.some((answer) => answer.answer_id === answerOption.id))
      .reduce((questionTotalScore: number, answer: AnswerType) => questionTotalScore + answer.score, 0);
    return totalScore + questionScore;
  }, 0);
};

export const calculateScoreForSymptoms = (answers: AssessmentResultAnswerType[], questions: QuestionType[]) => {
  const symptomIds = getSymptomIds(questions);

  return symptomIds.map<SymptomScore>((symptomId) => ({
    symptom_id: symptomId,
    score: calculateScoreForSymptom(symptomId, answers, questions)
  }));
};
