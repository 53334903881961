import React from 'react';

import { BaseModal, ProgressBar } from 'components/ui';
import { DoseForm } from 'components/assessment-details/DoseForm';
import { PatientMedicalRecord } from 'record/patient-medical.record';
import { Recommendation } from 'components/assessment-details/Recommendation';
import {
  AssessmentResultDrug,
  AssessmentResultType,
  ComfortRecommendations,
  TREATMENT_RECOMMENDATIONS,
} from 'types/assessment';
import { DosesDataType } from 'pages/AssessmentDetails';

type FollowRecommendationModalProps = {
  onCloseClick: () => void;
  onContinueClick: (dosesData: DosesDataType) => void;
  patientData: PatientMedicalRecord;
  recommendations: AssessmentResultDrug[];
  lastResult: AssessmentResultType;
  fetchPatientInfo: () => void;
  comfortRecommendations: ComfortRecommendations[];
};

const ADDITIONAL_STEP = 1;
const ONE_HUNDRED_PERCENT = 100;

export const FollowRecommendationModal: React.FC<FollowRecommendationModalProps> = ({
  onCloseClick,
  onContinueClick,
  fetchPatientInfo,
  patientData,
  recommendations,
  lastResult,
  comfortRecommendations,
}) => {
  const [step, setStep] = React.useState(0);

  const calculateProgress = (total: number, completed: number): number => {
    return (completed / total) * ONE_HUNDRED_PERCENT;
  };

  const currentProgress = calculateProgress(recommendations.length + ADDITIONAL_STEP, step + ADDITIONAL_STEP);

  const getRecommendationName = () => {
    if (step === recommendations.length) return '';

    const recommendation = TREATMENT_RECOMMENDATIONS.find(
      (recommendation) => recommendation.id === recommendations[step].drug_id,
    );

    return recommendation ? recommendation.name : '';
  };

  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  return (
    <BaseModal isShownCloseButton onCloseClick={onCloseClick}>
      <div className="w-[500px] flex flex-col items-center">
        <p className="mb-3 text-gray-500 text-center text-xs font-semibold leading-[17px]">
          {step === recommendations.length ? 'Summary' : 'Treatment Recommendations'}
        </p>
        <div className="mb-8 w-full max-w-[200px]">
          <ProgressBar progress={currentProgress} />
        </div>
        <h2 className="mb-4 self-stretch text-gray-900 text-center text-[19px] font-semibold leading-7">
          {step === recommendations.length
            ? 'Please review summary info'
            : 'Did you follow the treatment recommendations?'}
        </h2>

        {step < recommendations.length && (
          <div>
            <Recommendation
              className="w-full"
              name={getRecommendationName()}
              id={recommendations[step].drug_id}
              currentResult={lastResult}
              isComfortMeasures={false}
              isShowControls
              comfortRecommendations={comfortRecommendations}
              afterUpdate={nextStep}
            />
          </div>
        )}
        {step === recommendations.length && (
          <DoseForm
            lastResult={lastResult}
            onCancelClick={onCloseClick}
            onSubmit={onContinueClick}
            refetchData={fetchPatientInfo}
            patientData={patientData}
          />
        )}
      </div>
    </BaseModal>
  );
};
