import React, { useState } from 'react';

import { ErrorModal } from 'components/ui/modal/ErrorModal';

type UseLoadingWithErrorHandlingReturn = {
  submitting: boolean;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  showErrorModal: () => void;
  ErrorModalComponent: React.ReactNode | null;
};

export const useLoadingWithErrorHandling = (): UseLoadingWithErrorHandlingReturn => {
  const [submitting, setSubmitting] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const showErrorModal = () => {
    setIsErrorModalOpen(true);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const ErrorModalComponent = isErrorModalOpen ? <ErrorModal onCancelClick={closeErrorModal} btnText="Close" /> : null;

  return { submitting, setSubmitting, showErrorModal, ErrorModalComponent };
};
