import React, { CSSProperties } from 'react';
import { PulseLoader } from 'react-spinners';

const override: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  margin: '4px auto',
};

export const Loader = () => {
  return (
    <div className="flex w-full py-2">
      <PulseLoader cssOverride={override} color="#8dc63f" size={15} />
    </div>
  );
};
