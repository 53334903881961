import React, { useState } from 'react';
import cn from 'classnames';

import { PatientCard } from 'components/PatientCard';
import { PatientRecord } from 'record/patient.record';
import { useAuth } from 'contexts/AuthContextProvider';
import { DownIcon, MedicalInfoIcon } from 'assets/icons';
import { PractitionerRecord } from 'hooks/usePatientsData';
import { Loader } from 'components/ui/Loader';

const notFoundTips = ['Check for typos and spelling errors', 'Try more general keywords', 'Try different keywords'];

type PatientListProps = {
  activePatients: Record<string, PatientRecord[]> | null;
  notActivePatients: PatientRecord[] | null;
  practitioners: PractitionerRecord[];
  fetch: () => void;
  isPatientsEmpty: boolean;
  search: string;
  isSubmitting: boolean;
};

export const PatientList: React.FC<PatientListProps> = ({
  activePatients,
  notActivePatients,
  practitioners,
  fetch,
  isPatientsEmpty,
  search,
  isSubmitting,
}) => {
  const [isShowNoActivePatients, setIsShowNoActivePatients] = useState<boolean>(true);

  const { user } = useAuth();
  return (
    <div className="w-full dark:bg-gray-900">
      {isSubmitting && <Loader />}

      {activePatients &&
        Object.values(activePatients).map((patientRecords, index) => (
          <div key={index}>
            <h2 className="nurse w-full flex items-center gap-1.5 py-3 px-4 bg-white-100 text-gray-500 dark:text-gray-200 text-xs font-medium leading-[17px] dark:bg-white-400/[.04]">
              {practitioners[index] ? (
                <>
                  <MedicalInfoIcon />
                  {practitioners[index].firstName} {practitioners[index].lastName}
                  {user?.userId === practitioners[index].id ? (
                    <span className="flex bg-teal-300/25 px-2.5 py-1 rounded-full text-green-800 dark:text-white-400 text-xs font-medium leading-[135%]">
                      You
                    </span>
                  ) : null}
                </>
              ) : (
                <span className="flex items-center gap-1.5">
                  <MedicalInfoIcon />
                  Other
                </span>
              )}
            </h2>

            {patientRecords.map((patient: PatientRecord) => (
              <PatientCard
                patient={patient}
                key={patient.refId}
                isYourPatient={practitioners[index] && user?.userId === practitioners[index].id}
                fetchPatients={fetch}
              />
            ))}
          </div>
        ))}
      {notActivePatients?.length ? (
        <div>
          <button
            className="flex w-full h-[42px] items-start gap-1.5 bg-white-100 dark:bg-white-400/[.04] px-4 py-3 text-sky text-xs font-medium leading-[17px]"
            type="submit"
            onClick={() => setIsShowNoActivePatients(!isShowNoActivePatients)}
          >
            <DownIcon className={cn('w-4 h-4', { 'rotate-180': isShowNoActivePatients })} />
            Former patients
          </button>
          {isShowNoActivePatients && (
            <div className="w-full">
              {notActivePatients.map((patient: PatientRecord) => (
                <PatientCard patient={patient} key={patient.refId} isActive={false} fetchPatients={fetch} />
              ))}
            </div>
          )}
        </div>
      ) : null}
      {search && isPatientsEmpty && (
        <div className="p-6 bg-white-400 dark:bg-gray-900 shadow-[0px_6px_10px_-1px_rgba(6,25,56,0.07)]">
          <p className="text-gray-900 dark:text-white-400 text-sm font-semibold leading-[130%]">
            No results for “{search}”
          </p>
          <ul className="pt-3">
            {notFoundTips.map((tip) => (
              <li
                key={tip}
                className="flex pl-3 relative text-gray-900 dark:text-white-400 text-xs font-medium leading-[17px]"
              >
                <span className="w-1 h-1 absolute top-2 left-0 bg-sky rounded-full" />
                {tip}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
