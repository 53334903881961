import { MorphineRecommendation } from 'algorithms/morphine-algorithm';
import { TIME_FORMAT_OPTIONS } from 'record/onboarding-result.record';
import { OptionType } from 'components/ui/form-fields';

interface AssessmentDataRecordType {
  refId: number;
  datetimeOfBirth: string | null;
  timeOption: string;
  givenName: string;
  familyName: string;
  birthWeightKg: number;
  narcoticIds: string[] | null;
  otherNarcotics: string[] | null;
  wasExposedToOpioidsPostDelivery: boolean;
  clonidineDose: number;
  clonidineFrequency: number;
  morphineDose: number;
  isTransferred: boolean | null;
  lastKnownAssessmentDate: Date;
  hasPriorAssessment: boolean | null;
  symptoms: string[];
  changedClonidineDate: string | null;
  clonidineIncreasedInLastSixHours: boolean | null;
  lastClonidineDoseChangeDose: number | null;
  lastClonidineDoseChangeFrequency: number | null;
  isLastAssessmentRecommendationAnIncrease: boolean | null;
  changedMorphineDate: string | null;
  isPriorAssessmentDone: boolean;
  isFirstAssessment: boolean;
  hasEverHadMorphine: boolean | null;
  hasEverHadClonidine: boolean | null;
  hasClonidineBeenTrialedOff: boolean | null;
  hasTakenLongActingOpiods: boolean | null;
  lastRecommendedMorphine: MorphineRecommendation | null;
  morphineChange: string | null;
}

export const FREQUENCY_OPTIONS: OptionType[] = [
  { label: 'Not Taking/Not Applicable', value: 0 },
  { label: 'Every 3 hours', value: 3 },
  { label: 'Every 6 hours', value: 6 },
];

export class AssessmentDataRecord {
  refId: number;
  datetimeOfBirth: string | null;
  timeOption: string;
  givenName: string;
  familyName: string;
  birthWeightKg: number | null;
  narcoticIds: string[] | null;
  clonidineDose: number;
  clonidineFrequency: number;
  morphineDose: number;
  isTransferred: boolean | null;
  lastKnownAssessmentDate: Date;
  isPriorAssessmentDone: boolean;
  isFirstAssessment: boolean;
  otherNarcotics: string[] | null;
  wasExposedToOpioidsPostDelivery: boolean;
  hasPriorAssessment: boolean | null;
  symptoms: string[];
  changedClonidineDate: string | null;
  clonidineIncreasedInLastSixHours: boolean | null;
  lastClonidineDoseChangeDose: number | null;
  lastClonidineDoseChangeFrequency: number | null;
  isLastAssessmentRecommendationAnIncrease: boolean | null;
  hasClonidineBeenTrialedOff: boolean | null;
  changedMorphineDate: string | null;
  hasEverHadMorphine: boolean | null;
  hasEverHadClonidine: boolean | null;
  hasTakenLongActingOpiods: boolean | null;
  lastRecommendedMorphine: number | null;
  morphineChange: string | null;

  constructor(record: Partial<AssessmentDataRecordType> = {}) {
    this.refId = record.refId || 0;
    this.timeOption = record.timeOption || TIME_FORMAT_OPTIONS.military.value;
    this.datetimeOfBirth = record.datetimeOfBirth || null;
    this.givenName = record.givenName || '';
    this.familyName = record.familyName || '';
    this.birthWeightKg = record.birthWeightKg || null;
    this.datetimeOfBirth = record.datetimeOfBirth || null;
    this.clonidineDose = record.clonidineDose || 0;
    this.clonidineFrequency = record.clonidineFrequency || 0;
    this.morphineDose = record.morphineDose || 0;
    this.isTransferred = record.isTransferred || null;
    this.narcoticIds = record.narcoticIds || null;
    this.otherNarcotics = record.otherNarcotics || null;
    this.wasExposedToOpioidsPostDelivery = record.wasExposedToOpioidsPostDelivery || false;
    this.hasPriorAssessment = record.hasPriorAssessment || null;
    this.changedClonidineDate = record.changedClonidineDate || null;
    this.changedMorphineDate = record.changedMorphineDate || null;
    this.isPriorAssessmentDone = record.isPriorAssessmentDone || false;
    this.lastKnownAssessmentDate = record.lastKnownAssessmentDate || new Date();
    this.isFirstAssessment = record.isFirstAssessment || true;
    this.hasEverHadMorphine = record.hasEverHadMorphine || null;
    this.hasEverHadClonidine = record.hasEverHadClonidine || null;
    this.hasClonidineBeenTrialedOff = record.hasClonidineBeenTrialedOff || null;
    this.hasTakenLongActingOpiods = record.hasTakenLongActingOpiods || null;
    this.lastRecommendedMorphine = record.lastRecommendedMorphine || null;
    this.symptoms = record.symptoms || [];
    this.clonidineIncreasedInLastSixHours = record.clonidineIncreasedInLastSixHours || null;
    this.lastClonidineDoseChangeDose = record.lastClonidineDoseChangeDose || null;
    this.lastClonidineDoseChangeFrequency = record.lastClonidineDoseChangeFrequency || null;
    this.isLastAssessmentRecommendationAnIncrease = record.isLastAssessmentRecommendationAnIncrease || null;
    this.morphineChange = record.morphineChange || null;
  }
}
