import React, { useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter, useNavigate } from 'react-router-dom';

import { Layout } from '../Layout';
import { Login } from 'pages/Login';
import { Roster } from 'pages/Roster';
import { Version } from 'pages/Version';
import { Assessment } from 'pages/Assessment';
import { ProtectedRoute } from './ProtectedRoute';
import { useAuth } from 'contexts/AuthContextProvider';
import { AssessmentDetails } from 'pages/AssessmentDetails';
import { OnboardingQuestionnaire } from 'components/assessment/OnboardingQuestionnaire';
import { useNetworkCheck } from 'contexts/NetworkContextProvider';

export enum AppRoutes {
  Login = '/login',
  Roster = '/roster',
  Version = '/version',
  Onboarding = '/onboarding',
  Assessment = '/assessment/:id',
  CurrentPatient = '/patient/:id',
}

export const replaceRouteParamWithValue = (
  route: AppRoutes,
  paramName: string,
  paramValue: string | number | undefined,
) => {
  if (paramValue === undefined) {
    return route;
  }
  return route.replace(`:${paramName}`, paramValue.toString());
};

export const Routes = () => {
  const [router, setRouter] = useState<any | null>(null);

  const { token } = useAuth();
  const { isOnline } = useNetworkCheck();

  useEffect(() => {
    // Define public routes accessible to all users
    const routesForPublic = [
      {
        path: AppRoutes.Login,
        element: <Login />,
      },
    ];

    // Define routes accessible only to authenticated users
    const routesForAuthenticatedOnly = [
      {
        path: '/',
        element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
        children: [
          { path: AppRoutes.Roster, element: <Roster /> },
          {
            path: AppRoutes.Assessment,
            element: <Assessment />,
          },
          { path: AppRoutes.Onboarding, element: <OnboardingQuestionnaire /> },
          { path: AppRoutes.Version, element: <Version /> },
          { path: AppRoutes.CurrentPatient, element: <AssessmentDetails /> },
        ],
      },
    ];
    // Define routes accessible only to non-authenticated users
    const routesForNotAuthenticatedOnly = [
      {
        path: AppRoutes.Login,
        element: <Login />,
      },
      ...(isOnline
        ? []
        : [
            {
              path: AppRoutes.Assessment,
              element: <Assessment />,
            },
            { path: AppRoutes.CurrentPatient, element: <AssessmentDetails /> },
          ]),
      {
        path: '*',
        element: <RedirectToLogin />,
      },
    ];

    // Combine and conditionally include routes based on authentication status
    const newRouter = createBrowserRouter([
      ...routesForPublic,
      ...(token
        ? [
            {
              path: '/',
              element: <RedirectToRoster />,
            },
          ]
        : routesForNotAuthenticatedOnly),
      ...routesForAuthenticatedOnly,
    ]);

    setRouter(newRouter);
  }, [isOnline, token]);

  function RedirectToLogin() {
    const navigate = useNavigate();
    useEffect(() => {
      navigate(AppRoutes.Login);
    }, [navigate]);

    return null;
  }

  function RedirectToRoster() {
    const navigate = useNavigate();

    useEffect(() => {
      navigate(AppRoutes.Roster);
    }, [navigate]);

    return null;
  }

  // Provide the router configuration using RouterProvider
  return <Layout>{router && <RouterProvider router={router} />}</Layout>;
};
