import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { ActionModal } from 'components/ui';
import { BaseButton } from 'components/ui/buttons';
import { InputField } from 'components/ui/form-fields';
import { AlertIcon, CheckCircleIcon, InfoIcon } from 'assets/icons';
import { MediaModal } from 'components/ui/modal/MediaModal';
import { useAssessment } from 'contexts/AssessmentContextProvider';
import { QuestionInfo } from 'components/assessment/common/QuestionInfo';
import {
  AnswerType,
  AssessmentMediaType,
  AssessmentResultAnswerType,
  AssessmentType,
  QuestionType,
} from 'types/assessment';
import { AssessmentResultRecord } from 'record/assessment-result.record';
import { AnswerButton } from 'components/assessment/common/AnswerButton';
import { CommonQuestionSteps } from 'components/assessment/CommonQuestionnaire';
import { MessageModal } from 'components/ui/modal/MessageModal';
import parse from 'html-react-parser';

const ANSWER_TYPE_FOR_INPUT = ['int', 'float'];
const RESPIRATORY_RATE_QUESTION_ID = 'a4e4052e-971a-4ab1-b0c1-d9a6675be8a5';

enum AssessmentMedia {
  image = 'image',
  video = 'video',
}

type MainAssessmentQuestionnaireProps = {
  relativeStep: number;
  proceedToNextStep: () => void;
};

export const MainAssessmentQuestionnaire = React.forwardRef<unknown, MainAssessmentQuestionnaireProps>(
  ({ relativeStep, proceedToNextStep }, ref) => {
    const [isShownAnnotationModal, setShownAnnotationModal] = useState<boolean>(false);
    const [isShownMediaModal, setShownMediaModal] = useState<boolean>(false);
    const [isShownWarningModal, setShownWarningModal] = useState<boolean>(false);
    const [isSkippedWarning, setSkippedWarning] = useState<boolean>(false);
    const [isAnswerUpdated, setAnswerUpdated] = useState<boolean>(false);

    const { assessment, assessmentResult, setAssessmentResult, currentStep } = useAssessment();

    const lengthCommonQuestionnaire = Object.keys(CommonQuestionSteps).length / 2;

    const question = assessment?.questions[relativeStep];

    const handleSkipSeries = useCallback(
      (questionId: string) => {
        if (!assessment) return;

        const nextStep = relativeStep + 1;
        const seriesKey = assessment.questions.find((question) => question.id === questionId)?.series_key;
        for (let i = nextStep; i < assessment.questions.length; i++) {
          if (assessment.questions[i].series_key !== seriesKey) {
            return i + lengthCommonQuestionnaire + 1;
          }
        }
        return null;
      },
      [assessment, lengthCommonQuestionnaire, relativeStep],
    );

    useImperativeHandle(
      ref,
      () => ({
        handleNextStep: () => {
          if (!assessment) return;

          const currentQuestion = assessment.questions[relativeStep];
          const currentAnswer = assessmentResult?.answers.find((answer) => answer.question_id === currentQuestion?.id);
          const isRespiratoryRateQuestion = currentQuestion?.id === RESPIRATORY_RATE_QUESTION_ID;

          if (
            isRespiratoryRateQuestion &&
            !isSkippedWarning &&
            currentAnswer &&
            currentAnswer.answer_num &&
            currentQuestion &&
            currentQuestion.upper_threshold &&
            currentQuestion.lower_threshold
          ) {
            if (
              currentAnswer?.answer_num < currentQuestion?.lower_threshold ||
              currentAnswer?.answer_num > currentQuestion?.upper_threshold
            ) {
              setShownWarningModal(true);
              return currentStep;
            }
          }

          if (isSkippedWarning) {
            setSkippedWarning(false);
            setShownWarningModal(false);
          }

          setAnswerUpdated(false);

          const selectedAnswerQuestionOption = currentQuestion?.answer_options.find(
            (option) => option.id === currentAnswer?.answer_id,
          );

          if (!selectedAnswerQuestionOption?.continue_series && currentQuestion?.series_key) {
            return handleSkipSeries(currentQuestion?.id);
          } else if (relativeStep >= assessment.questions.length - 1) {
            return null;
          } else {
            return currentStep + 1;
          }
        },
      }),
      [assessment, relativeStep, assessmentResult, currentStep, isSkippedWarning, handleSkipSeries],
    );
    useEffect(() => {
      if (isSkippedWarning) {
        proceedToNextStep();
      }
    }, [isSkippedWarning, proceedToNextStep]);

    useEffect(() => {
      const currentAnswer = assessmentResult?.answers.find((answer) => answer.question_id === question?.id);
      if (isAnswerUpdated && currentAnswer && question?.answer_type === 'options') {
        proceedToNextStep();
      }
    }, [question?.answer_type, question?.id, assessmentResult?.answers, proceedToNextStep, isAnswerUpdated]);

    const updateAnswerBasedOnInput = (event: React.ChangeEvent<HTMLInputElement>, question: QuestionType) => {
      const inputValue = Number(event.target.value);
      const matchingOption = question.answer_options.find(
        (option) => option.upper_threshold !== undefined && option.upper_threshold >= inputValue,
      );

      if (matchingOption) {
        setAssessmentResult((currentAssessmentResult: AssessmentResultRecord) => {
          const previousAnswers = currentAssessmentResult.answers;
          const existingAnswerIndex = previousAnswers.findIndex((answer) => answer.question_id === question.id);
          const newAnswer: AssessmentResultAnswerType = {
            question_id: question.id,
            answer_id: matchingOption.id,
            answer_num: inputValue,
          };

          const newAnswers = [...previousAnswers];
          if (existingAnswerIndex === -1) {
            newAnswers.push(newAnswer);
          } else {
            newAnswers[existingAnswerIndex] = newAnswer;
          }
          return {
            ...currentAssessmentResult,
            answers: newAnswers,
          };
        });
      }
    };

    const handleAnswer = (currentAnswer: AnswerType, questionId: string) => {
      const selectedAnswer: AssessmentResultAnswerType = { question_id: questionId, answer_id: currentAnswer.id };

      setAssessmentResult((currentAssessmentResult: AssessmentResultRecord) => {
        let answerUpdated = false;

        const newAnswers = currentAssessmentResult.answers.map((answer) => {
          if (answer.question_id === questionId) {
            answerUpdated = true;
            return selectedAnswer;
          }
          return answer;
        });

        if (!answerUpdated) {
          newAnswers.push(selectedAnswer);
        }

        return {
          ...currentAssessmentResult,
          answers: newAnswers,
        };
      });
      setAnswerUpdated(true);
    };

    const selectedAnswer = assessmentResult?.answers.find((answer) => {
      return answer.question_id === assessment?.questions[relativeStep].id;
    });

    const renderModalWarningDescription = () => {
      const mainText =
        question?.id === RESPIRATORY_RATE_QUESTION_ID ? 'Normal respiratory rate range: ' : 'Normal temperature: ';
      const subText = `<strong>${question?.lower_threshold} - ${question?.upper_threshold}</strong>`;
      return parse(`${mainText} ${subText}`);
    };

    const renderAnswerOption = (answer: AnswerType, questionId: string) => (
      <AnswerButton
        key={answer.id}
        currentAnswer={!!selectedAnswer && selectedAnswer.answer_id === answer.id}
        onSelect={() => handleAnswer(answer, questionId)}
        icon={CheckCircleIcon}
        buttonText={answer.text}
      />
    );

    const renderQuestion = (question: QuestionType) => (
      <div key={question.id}>
        <div className="flex flex-col gap-2">
          {ANSWER_TYPE_FOR_INPUT.includes(question.answer_type) ? (
            <InputField
              className="px-6 py-[18px] rounded-xl h-16"
              type="number"
              value={selectedAnswer?.answer_num}
              min={question.lower_threshold}
              max={question.upper_threshold}
              onChange={(e) => {
                updateAnswerBasedOnInput(e, question);
              }}
            />
          ) : (
            question.answer_options.map((answer) => renderAnswerOption(answer, question.id))
          )}
        </div>
      </div>
    );

    const renderMedia = (media: AssessmentMediaType) => {
      return (
        <>
          {media.type === AssessmentMedia.image && (
            <div className="my-8">
              <img
                src={`/images/${media.asset}`}
                alt="media"
                className="w-80 h-80 shrink-0 rounded-xl cursor-pointer"
                onClick={() => setShownMediaModal(true)}
              />
            </div>
          )}

          {media.type === AssessmentMedia.video && (
            <div className="my-8">
              <video className="max-w-[420px] h-60 shrink-0 rounded-xl" controls>
                <source src={`/videos/${media.asset}`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </>
      );
    };

    const renderAssessmentQuestion = (assessment: AssessmentType) => {
      return (
        <div>
          <QuestionInfo
            title={assessment.questions[relativeStep].text}
            description={assessment.questions[relativeStep].subtext}
          />

          {assessment.questions.length ? renderQuestion(assessment.questions[relativeStep]) : <div>No Questions</div>}

          {assessment.questions[relativeStep].annotation && (
            <BaseButton
              icon={InfoIcon}
              isIconOnLeftSide
              scheme="secondary"
              size="sm"
              className="!bg-transparent mt-6"
              onClick={() => setShownAnnotationModal(true)}
            >
              {assessment.questions[relativeStep].annotation?.title}
            </BaseButton>
          )}

          {isShownWarningModal && (
            <MessageModal
              icon={AlertIcon}
              iconProps={{ className: 'text-silver-400' }}
              successText="Continue"
              cancelText="Change"
              title={`This value is ${
                selectedAnswer?.answer_num &&
                question?.upper_threshold &&
                selectedAnswer?.answer_num > question?.upper_threshold
                  ? 'above'
                  : 'below'
              } the expected range`}
              description={renderModalWarningDescription()}
              onCancelClick={() => setShownWarningModal(false)}
              onSuccessClick={() => {
                setSkippedWarning(true);
              }}
            />
          )}

          {assessment.questions[relativeStep].media &&
            renderMedia(assessment.questions[relativeStep].media as AssessmentMediaType)}

          {isShownMediaModal && (
            <MediaModal
              isVideo={assessment.questions[relativeStep].media?.type === AssessmentMedia.video}
              src={assessment.questions[relativeStep].media?.asset || ''}
              onCancelClick={() => setShownMediaModal(false)}
            />
          )}

          {isShownAnnotationModal && (
            <ActionModal
              onCancelClick={() => setShownAnnotationModal(false)}
              onSuccessClick={() => setShownAnnotationModal(false)}
              iconTitle={InfoIcon}
              iconTitleProps={{ className: '[&>path]:fill-sea-serpent' }}
              successText="Got it!"
              title={assessment.questions[relativeStep].annotation!.title}
              description={assessment.questions[relativeStep].annotation!.text}
            />
          )}
        </div>
      );
    };

    return <>{assessment && <>{renderAssessmentQuestion(assessment)}</>}</>;
  },
);
