import React from 'react';
import { BaseModal } from './BaseModal';

type MediaModalProps = {
  src: string;
  isVideo?: boolean;
  onCancelClick: () => void;
};

export const MediaModal: React.FC<MediaModalProps> = ({ src, isVideo = false, onCancelClick }) => {
  return (
    <BaseModal scheme="secondary" isShownCloseButton onCloseClick={onCancelClick}>
      <div className="max-w-[470px] mb-16 rounded-xl overflow-hidden">
        {isVideo ? <video src={src} controls /> : <img src={src} alt="media" />}
      </div>
    </BaseModal>
  );
};
