import React from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import { BaseModal } from 'components/ui';
import { BaseButton } from 'components/ui/buttons';
import { updatePatientInformation } from 'services/api';
import { UpdatePatientPayloadType } from 'types/patient';
import { PatientMedicalRecord } from 'record/patient-medical.record';
import { useLoadingWithErrorHandling } from 'hooks/useLoadingWithErrorHandling';
import { Narcotics, TIME_FORMAT_OPTIONS } from 'record/onboarding-result.record';
import { DateTimeField, InputField, OptionType, SelectField } from 'components/ui/form-fields';

type EditSummaryInfoModalProps = {
  onCloseClick: () => void;
  patientData: PatientMedicalRecord;
  refetch: () => void;
};

const narcoticsOptions: OptionType[] = Object.entries(Narcotics).map(([narcoticName, narcoticId]) => {
  return {
    label: narcoticName,
    value: narcoticId,
  };
});

export const EditSummaryInfoModal: React.FC<EditSummaryInfoModalProps> = ({ onCloseClick, patientData, refetch }) => {
  const [birthDate, setBirthDate] = React.useState<Date | null>(
    patientData.datetime_of_birth ? new Date(patientData.datetime_of_birth) : null,
  );
  const [narcotics, setNarcotics] = React.useState<OptionType[] | null>(
    narcoticsOptions.filter((n) => patientData.narcotic_ids?.includes(n.value as string)),
  );
  const [weight, setWeight] = React.useState<number | null>(patientData.birth_weight_kg || null);

  const { submitting, setSubmitting, showErrorModal, ErrorModalComponent } = useLoadingWithErrorHandling();

  const { id: patientId } = useParams();

  const maxTime =
    !birthDate || dayjs().isSame(dayjs(birthDate), 'day') ? new Date() : new Date(new Date().setHours(23, 59, 59, 999));

  const updatePatientInfo = async () => {
    if (!patientId) return;

    try {
      setSubmitting(true);

      const payload: UpdatePatientPayloadType = {
        given_name: patientData.given_name,
        family_name: patientData.family_name,
        birth_weight_kg: weight ?? 0,
        datetime_of_birth: dayjs(birthDate).toISOString(),
        narcotic_ids: narcotics && narcotics.length ? narcotics.map((n) => String(n.value)) : [],
        other_narcotics: patientData.other_narcotics,
        has_ever_had_morphine: patientData.has_ever_had_morphine,
        has_clonidine_been_trialed_off: patientData.has_clonidine_been_trialed_off,
        has_ever_had_clonidine: patientData.has_ever_had_clonidine,
        has_taken_long_acting_opioids: patientData.has_taken_long_acting_opioids,
      };

      await updatePatientInformation(patientId, payload);
      await refetch();
      onCloseClick();
    } catch (error) {
      console.error(error);
      showErrorModal();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <BaseModal isShownCloseButton onCloseClick={onCloseClick} isUseOverflowHidden={false}>
      <div className="w-[420px] px-6 flex flex-col items-start">
        <h2 className="mb-4 text-gray-900 text-[19px] font-semibold leading-7">Edit Summary Info</h2>
        <div className="flex flex-col text-left items-start w-full">
          <SelectField
            selectClassName="mb-4"
            label="Exposure Meds"
            schema="secondary"
            options={narcoticsOptions}
            isMulti
            value={narcotics}
            onChange={(value) => {
              setNarcotics(value as OptionType[]);
            }}
          />
          <DateTimeField
            wrapperClassName="w-full mb-4 !rounded-md"
            size="md"
            label="Date/time of birth"
            placeholderFormat={TIME_FORMAT_OPTIONS.standard.format}
            maxDate={dayjs().toDate()}
            minTime={new Date(new Date().setHours(0, 0, 0, 0))}
            maxTime={maxTime}
            value={birthDate}
            onChange={(date) => setBirthDate(date)}
          />
          <InputField
            type="number"
            step="0.01"
            min="0"
            className="w-full rounded-xl mb-4"
            label="Birth Weight"
            placeholder="0.00"
            inputMode="decimal"
            suffix="Kg"
            value={weight ?? undefined}
            onChange={(event) => setWeight(Number(event.target.value))}
          />
        </div>
        <div className="w-full flex items-center justify-end gap-6 pt-4">
          <BaseButton scheme="transparent" onClick={onCloseClick}>
            Cancel
          </BaseButton>
          <BaseButton scheme="primary" isLoading={submitting} onClick={updatePatientInfo}>
            Save
          </BaseButton>
        </div>

        {ErrorModalComponent}
      </div>
    </BaseModal>
  );
};
