import React from 'react';
import cn from 'classnames';

type TabsProps = {
  titles: string[];
  contents: React.ReactNode[];
  additionalControls?: React.ReactNode;
  searchSlot?: React.ReactNode;
  activeTab: number;
  setActiveTab: (index: number) => void;
};

export const Tabs: React.FC<TabsProps> = ({
  titles,
  contents,
  additionalControls,
  searchSlot,
  activeTab,
  setActiveTab,
}) => {
  return (
    <div className="w-full">
      <div className="w-full flex justify-between items-center dark:bg-gray-900">
        <div className="flex group-tabs">
          {titles.map((title, index) => (
            <button
              type="button"
              key={index}
              className={cn(
                'inline-flex items-center shrink-0 bg-white-400 p-5 text-center text-base leading-[150%] dark:bg-gray-900',
                'hover:shadow-hover-tab hover:bg-white-100 dark:hover:bg-gray-600/[.01] active:bg-white-300 dark:active:bg-white-300/10',
                index === activeTab
                  ? 'text-blue-400 font-semibold shadow-active-tab dark:text-white-400'
                  : 'text-sky font-medium shadow-tab dark:text-gray-800 dark:shadow-dark-tab',
              )}
              onClick={() => setActiveTab(index)}
            >
              {title}
            </button>
          ))}
        </div>

        {additionalControls}
      </div>
      {searchSlot}
      <div className="transition-all duration-200 ease-in-out dark:bg-gray-900 dark:text-white-100">
        {contents[activeTab]}
      </div>
    </div>
  );
};
