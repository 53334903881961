import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { InitOptions, loadSpace, SpaceApi } from '@usersnap/browser';
import { useNetworkCheck } from 'contexts/NetworkContextProvider';

type UsersnapContextType = {
  api: SpaceApi | null;
  setInitParams: (params: InitOptions) => void;
};

type UsersnapProviderProps = {
  initParams?: InitOptions;
  children: ReactNode;
};

const API_KEY = `${process.env.USERSNAP_GLOBAL_API_KEY}`;

export const UsersnapContext = createContext<UsersnapContextType | null>(null);

export const useUsersnapApi = (): UsersnapContextType | null => useContext(UsersnapContext);

export const UsersnapProvider: React.FC<UsersnapProviderProps> = ({ initParams: initialParams, children }) => {
  const [usersnapApi, setUsersnapApi] = useState<SpaceApi | null>(null);
  const [initParams, setInitParams] = useState<InitOptions | undefined>(initialParams);

  const { isOnline } = useNetworkCheck();

  useEffect(() => {
    if (isOnline) {
      loadSpace(API_KEY)
        .then((api: SpaceApi) => {
          api.init(initParams);
          setUsersnapApi(api);
        })
        .catch((error) => {
          console.error('Error initializing Usersnap:', error);
        });
    } else {
      setUsersnapApi(null);
    }
  }, [initParams, isOnline]);

  const updateInitParams = useCallback(
    (params: InitOptions) => {
      setInitParams(params);
      if (usersnapApi) {
        usersnapApi.init(params);
      }
    },
    [usersnapApi],
  );

  return (
    <UsersnapContext.Provider value={{ api: usersnapApi, setInitParams: updateInitParams }}>
      {children}
    </UsersnapContext.Provider>
  );
};
