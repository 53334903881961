import { GISymptoms } from './types/gi-symptoms';

export enum MorphineRecommendation {
  Notstarted,
  Start,
  Increase,
  Nochange,
  Decrease,
  Discontinue,
}

export type MorphineRecommendationResult = {
  calculatedDose: number;
  recommendation: string;
  recommendationCoded: MorphineRecommendation;
  forceClonidineStart: boolean;
  note: string;
};

export type GetMorphineRecommendationRequest = {
  priorEvaluationScore: number;
  currentEvaluationScore: number;
  priorMorphineRecommendation: MorphineRecommendation;
  priorAssessmentGISymptoms: GISymptoms;
  currentAssessmentGISymptoms: GISymptoms;
  morphineDose: number;
};

const CHANGE_PERCENTAGE_DISPLAY = "10";
const CHANGE_PERCENTAGE = 0.10;
const DISCONTINUE_DETERMINATION_MAX_DOSAGE =  0.0104444444; // Rounded MAX

const shouldStartClonidine = (priorScore: number, currentScore: number, hasConsistentPoorStooling: boolean) => {
  return (hasConsistentPoorStooling && priorScore === 3 && currentScore === 3) || (priorScore === 4 && currentScore === 4);
};

const shouldIncreaseDose = (priorScore: number, currentScore: number) => {
  return (priorScore >= 3 && currentScore === 6) || (priorScore === 5 && currentScore === 5);
};

const shouldDecreaseDose = (priorScore: number, currentScore: number) => {
  return priorScore <= 3 && currentScore < 3;
};

const handleNotStarted = (priorScore: number, currentScore: number, hasConsistentPoorStooling: boolean): MorphineRecommendationResult => {
  if (shouldStartClonidine(priorScore, currentScore, hasConsistentPoorStooling)) {
    return { 
      calculatedDose: 0,
      recommendation: 'No Change', 
      recommendationCoded: MorphineRecommendation.Notstarted,
      forceClonidineStart: true,
      note: "handleNotStarted -> Force Clonidine to start, but no Morphine",
    };
  } else if (priorScore >= 3 && currentScore >= 5) {
    return { 
      calculatedDose: 0.05,
      recommendation: 'Start 0.05 mg/kg q3', 
      recommendationCoded: MorphineRecommendation.Start,
      forceClonidineStart: false,
      note: "handleNotStarted -> Start Morphine",
    };
  } else {
    return { 
      calculatedDose: 0,
      recommendation: 'No Change', 
      recommendationCoded: MorphineRecommendation.Notstarted,
      forceClonidineStart: false,
      note: "handleNotStarted -> Don't start",
    };
  }
};

const handleStartedOrIncreased = (priorScore: number, currentScore: number, _hasConsistentPoorStooling: boolean, morphineDose: number): MorphineRecommendationResult => {
  if (shouldIncreaseDose(priorScore, currentScore)) {
    return {
      calculatedDose: fixFloatPrecision(morphineDose + (morphineDose * CHANGE_PERCENTAGE)),
      recommendation: `+${CHANGE_PERCENTAGE_DISPLAY}% mg/kg`, 
      recommendationCoded: MorphineRecommendation.Increase,
      forceClonidineStart: false,
      note: "handleStartedOrIncreased -> Increase",
    };
  } else {
    return { 
      calculatedDose: morphineDose,
      recommendation: "No Change", 
      recommendationCoded: MorphineRecommendation.Nochange,
      forceClonidineStart: false,
      note: "handleStartedOrIncreased -> No Change",
    };
  }
};

const handleUnchangedOrWeanedOrDiscontinued = (
  priorScore: number,
  currentScore: number,
  _hasConsistentPoorStooling: boolean,
  morphineDose: number,
): MorphineRecommendationResult => {

  if (shouldDecreaseDose(priorScore, currentScore)) {
    if (morphineDose > DISCONTINUE_DETERMINATION_MAX_DOSAGE) {
      return { 
        calculatedDose: fixFloatPrecision(morphineDose - (morphineDose * CHANGE_PERCENTAGE)),
        recommendation: `-${CHANGE_PERCENTAGE_DISPLAY}% mg/kg`, 
        recommendationCoded: MorphineRecommendation.Decrease,
        forceClonidineStart: false,
        note: "handleUnchangedOrWeanedOrDiscontinued -> Decrease",
      };
    } else {
      return { 
        calculatedDose: 0,
        recommendation: "Discontinue", 
        recommendationCoded: MorphineRecommendation.Discontinue,
        forceClonidineStart: false,
        note: "handleUnchangedOrWeanedOrDiscontinued -> Discontinue",
      };
    }
  } else if ((priorScore === 3 && currentScore === 3) || currentScore >= 5) {
    return { 
      calculatedDose: fixFloatPrecision(morphineDose + (morphineDose * CHANGE_PERCENTAGE)),
      recommendation: `+${CHANGE_PERCENTAGE_DISPLAY}% mg/kg`, 
      recommendationCoded: MorphineRecommendation.Increase,
      forceClonidineStart: false,
      note: "handleUnchangedOrWeanedOrDiscontinued -> Increase",
    };
  } else {
    return { 
      calculatedDose: morphineDose,
      recommendation: "No Change", 
      recommendationCoded: MorphineRecommendation.Nochange,
      forceClonidineStart: false,
      note: "handleUnchangedOrWeanedOrDiscontinued -> No Change",
    };
  }
};

const handleDefault = (
  _priorScore: number,
  _currentScore: number,
  _hasConsistentPoorStooling: boolean,
  morphineDose: number
): MorphineRecommendationResult => {
  return { 
    calculatedDose: morphineDose,
    recommendation: "No Change", 
    recommendationCoded: MorphineRecommendation.Nochange,
    forceClonidineStart: false,
    note: "handleDefault -> No Change",
  };
};

const handlers = {
  [MorphineRecommendation.Notstarted]: handleNotStarted,
  [MorphineRecommendation.Start]: handleStartedOrIncreased,
  [MorphineRecommendation.Increase]: handleStartedOrIncreased,
  [MorphineRecommendation.Nochange]: handleUnchangedOrWeanedOrDiscontinued,
  [MorphineRecommendation.Decrease]: handleUnchangedOrWeanedOrDiscontinued,
  [MorphineRecommendation.Discontinue]: handleUnchangedOrWeanedOrDiscontinued,
  default: handleDefault,
};

const fixFloatPrecision = (value: number): number => {
  return parseFloat(value.toPrecision(12));
};

export const getDosageAndClonidineRecommendation = (request: GetMorphineRecommendationRequest) : MorphineRecommendationResult => {
  const handler = handlers[request.priorMorphineRecommendation] || handlers['default'];
  const hasConsistentPoorStooling = request.priorAssessmentGISymptoms.poorStooling && request.currentAssessmentGISymptoms.poorStooling;
  return handler(request.priorEvaluationScore, request.currentEvaluationScore, hasConsistentPoorStooling, fixFloatPrecision(request.morphineDose));
};