import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';
import { useLiveQuery } from 'dexie-react-hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { db } from '../db';
import { ProgressBar } from 'components/ui';
import { AssessmentType, Drugs } from 'types';
import { Loader } from 'components/ui/Loader';
import { useAuth } from './AuthContextProvider';
import { BaseButton } from 'components/ui/buttons';
import { useTenancy } from './TenantContextProvider';
import { ErrorModal } from 'components/ui/modal/ErrorModal';
import { AlertIcon, LeftIcon, RightIcon } from 'assets/icons';
import { AssessmentDataRecord } from 'record/assessment-data.record';
import {
  getAssessment,
  postCheckDrugChanges,
  postClonidineTrialedOffByPatientId,
  getPatientInformation,
  getLastAssessmentResultsByPatientId,
  postAssessmentResult,
} from 'services/api';
import { Narcotics } from 'record/onboarding-result.record';
import { MessageModal } from 'components/ui/modal/MessageModal';
import { useNetworkCheck } from 'contexts/NetworkContextProvider';
import { PatientMedicalRecord } from 'record/patient-medical.record';
import { MorphineRecommendation } from 'algorithms/morphine-algorithm';
import { AssessmentResultRecord } from 'record/assessment-result.record';
import { ClonidineRecommendation } from 'algorithms/clonidine-algorithm';
import { AppRoutes, replaceRouteParamWithValue } from 'routes/app-routes';
import { DrugChangesResultRecord } from 'record/drug-changes-result.record';
import { AssessmentHeader } from 'components/assessment/common/AssessmentHeader';
import { MainAssessmentQuestionnaire } from 'components/assessment/MainAssessmentQuestionnaire';
import { CommonQuestionnaire, CommonQuestionSteps } from 'components/assessment/CommonQuestionnaire';
import { AssessProAlgoResult, executeAssessProAlgorithm } from 'algorithms/assesspro-algorithm';
import cn from 'classnames';

type AssessmentContextType = {
  currentStep: number;
  assessment: AssessmentType | null;
  assessmentData: AssessmentDataRecord;
  assessmentResult: AssessmentResultRecord | null;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setAssessmentData: React.Dispatch<React.SetStateAction<AssessmentDataRecord>>;
  setAssessmentResult: React.Dispatch<React.SetStateAction<AssessmentResultRecord>>;
};

type AssessmentProviderProps = {
  children: ReactNode;
};

type CommonAssessmentType = {
  handleNextStep: () => number | null;
};

type MainAssessmentType = {
  handleNextStep: () => number | null;
};

const LENGTH_COMMON_QUESTIONNAIRE = Object.keys(CommonQuestionSteps).length / 2;

const MAX_HOURS_GAP_ASSESSMENT = 6;
const MAIN_ASSESSMENT_START_STEP = LENGTH_COMMON_QUESTIONNAIRE + 1;

const AssessmentContext = createContext<AssessmentContextType | null>(null);

export const AssessmentProvider: React.FC<AssessmentProviderProps> = ({ children }) => {
  // Assessment Questions/AnswerOptions
  const [assessment, setAssessment] = useState<AssessmentType | null>(null);
  // Assessment Data for all workflows
  const [assessmentData, setAssessmentData] = useState<AssessmentDataRecord>(new AssessmentDataRecord());
  // Current Assessment Result (to be sent to API)
  const [assessmentResult, setAssessmentResult] = useState<AssessmentResultRecord>(new AssessmentResultRecord());
  const [patientMedicalData, setPatientMedicalData] = useState<PatientMedicalRecord | null>(null);

  const [currentStep, setCurrentStep] = useState<number>(CommonQuestionSteps.PatientName);
  const [startStep, setStartStep] = useState<number>(CommonQuestionSteps.PatientName);

  const [isSubmitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isOfflineWarningModalOpen, setOfflineWarningModalOpen] = useState(false);

  const assessmentFromDB = useLiveQuery(() => db.assessment.toArray());

  const navigate = useNavigate();

  const tenantKey = useTenancy();
  const { user } = useAuth();

  const { id: patientId } = useParams();
  const { state: routeState } = useLocation();

  useEffect(() => {
    if (routeState) {
      setAssessmentData({
        ...assessmentData,
        morphineDose: routeState.currentMorphineDose,
        clonidineDose: routeState.currentClonidineDose,
        clonidineFrequency: routeState.currentClonidineFrequency,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeState]);

  const { isOnline } = useNetworkCheck();

  const commonAssessment = React.createRef<CommonAssessmentType>();
  const mainAssessment = React.createRef<MainAssessmentType>();

  const questionnaireProgress = () => {
    if (!assessment) return 0;
    const lengthAssessment = LENGTH_COMMON_QUESTIONNAIRE + assessment.questions.length;

    return (currentStep / lengthAssessment) * 100;
  };

  const nextStep = (forceNextStep = false) => {
    if (forceNextStep) {
      setCurrentStep((current) => (isOnline ? current + 1 : CommonQuestionSteps.HasPriorAssessment));
      return;
    }

    const isOnCommonQuestionnaire = currentStep <= LENGTH_COMMON_QUESTIONNAIRE;
    const assessmentRef = isOnCommonQuestionnaire ? commonAssessment.current : mainAssessment.current;
    if (assessmentRef === null) return;

    const step = assessmentRef.handleNextStep();

    if (step === null) {
      if (isOnCommonQuestionnaire) setCurrentStep(MAIN_ASSESSMENT_START_STEP);
      else handleFinish();
    } else {
      setCurrentStep(step);
    }
  };

  const isNextStepDisabled = useCallback((): boolean => {
    switch (currentStep) {
      case CommonQuestionSteps.BirthWeight:
        return !assessmentData.birthWeightKg;
      case CommonQuestionSteps.BornDate:
        return !assessmentData.datetimeOfBirth;
      case CommonQuestionSteps.Transfer:
        return assessmentData.isTransferred === null;
      case CommonQuestionSteps.CurrentClonidineMorphineDose:
        return (
          assessmentData.hasEverHadClonidine === null || 
          assessmentData.hasEverHadMorphine === null ||
          (assessmentData.hasEverHadClonidine === true && assessmentData.clonidineDose === 0) ||
          (assessmentData.hasEverHadMorphine === true && assessmentData.morphineDose === 0)
        );
      case CommonQuestionSteps.LastKnowAssessment:
        return assessmentResult?.has_missing_assessment === null;
      case CommonQuestionSteps.ReasonForSkippedAssessment:
        return !assessmentResult?.missing_assessment_reason;
      case CommonQuestionSteps.HasPriorAssessment:
        return assessmentData.hasPriorAssessment === null;
      case CommonQuestionSteps.LastClonidineChange:
        return !assessmentData.changedClonidineDate;
      case CommonQuestionSteps.HasIncreasedClonidine:
        return assessmentData.clonidineIncreasedInLastSixHours === null;
      case CommonQuestionSteps.HasClonidineBeenTrialedOff:
        return assessmentData.hasClonidineBeenTrialedOff === null;
      case CommonQuestionSteps.LastMorphineChange:
        return !assessmentData.changedMorphineDate;
      case CommonQuestionSteps.LastMorphineRecommendation:
        return assessmentData.lastRecommendedMorphine === null;
      default:
        if (currentStep > LENGTH_COMMON_QUESTIONNAIRE) {
          const relativeStep = currentStep - MAIN_ASSESSMENT_START_STEP;
          const currentQuestion = assessment?.questions[relativeStep];
          const currentAnswer = assessmentResult?.answers.find((answer) => answer.question_id === currentQuestion?.id);
          return !currentAnswer;
        }
        return false;
    }
  }, [assessmentData, currentStep, assessment, assessmentResult]);

  const handleBackStep = () => {
    if (!assessment || !assessmentResult) return;

    const answers = assessmentResult.answers || [];
    if (currentStep <= LENGTH_COMMON_QUESTIONNAIRE) {
      setCurrentStep(currentStep - 1);
      return;
    }

    const currentQuestion = assessment.questions[currentStep - 1 - LENGTH_COMMON_QUESTIONNAIRE];
    const currentQuestionAnswer = answers.find((a) => a.question_id === currentQuestion?.id);

    if (!currentQuestionAnswer) {
      const previousQuestionIndex = assessment?.questions.findIndex(
        (q) => q.id === answers[answers.length - 1]?.question_id,
      );
      if (previousQuestionIndex !== -1) {
        setCurrentStep(MAIN_ASSESSMENT_START_STEP + previousQuestionIndex);
      } else {
        setCurrentStep(
          isOnline ? CommonQuestionSteps.LastKnowAssessment : CommonQuestionSteps.LastMorphineRecommendation,
        );
      }
      return;
    }

    const currentAnswerIndex = answers.findIndex((a) => a.question_id === currentQuestionAnswer.question_id);
    if (currentAnswerIndex === -1 || currentAnswerIndex === 0) {
      setCurrentStep(
        isOnline ? CommonQuestionSteps.LastKnowAssessment : CommonQuestionSteps.LastMorphineRecommendation,
      );
      return;
    }

    const previousQuestionIndex = assessment.questions.findIndex(
      (q) => q.id === answers[currentAnswerIndex - 1]?.question_id,
    );

    if (previousQuestionIndex !== -1) {
      setCurrentStep(MAIN_ASSESSMENT_START_STEP + previousQuestionIndex);
    }
  };

  const handleFinish = () => {
    if (!assessment) return;

    // Once "complete", the results are no longer mutable
    if (assessmentResult?.isComplete) return;

    // Run through algorithm to calculate the result
    let algoResult: AssessProAlgoResult;
    try {
      algoResult = executeAssessProAlgorithm(assessment, assessmentData, assessmentResult.answers);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
        setIsErrorModalOpen(true);
      } else {
        console.error('An error occurred:', error);
      }
      return;
    }

    // Update assessment result, triggering useEffect to post results
    setAssessmentResult((current) => {
      return {
        ...current,
        version: assessment?.version || 0,
        overall_score: algoResult.score,
        hours_old: algoResult.hoursSinceBirth || 0,
        has_ever_had_morphine: assessmentData.hasEverHadMorphine || false,
        has_ever_had_clonidine: assessmentData.hasEverHadClonidine || false,
        has_clonidine_been_trialed_off: false, // || algoResult.clonidineResult?.isRestart,
        has_taken_long_acting_opioids:
          assessmentData.narcoticIds?.includes(Narcotics.Methadone) ||
          assessmentData.narcoticIds?.includes(Narcotics['Bupernophine (Subutex/Suboxone)']) ||
          false,
        assessment_result_symptom:
          algoResult.symptomScores.map((symptom) => {
            return {
              symptom_id: symptom.symptom_id,
              score: symptom.score,
              is_poor: algoResult.activeSymptoms.includes(symptom.symptom_id),
            };
          }) || [],
        assessment_result_drug: [
          {
            drug_id: Drugs.Morphine,
            before_recommendation_dosage_mcg: assessmentData.morphineDose,
            before_recommendation_frequency: assessmentData.morphineDose > 0 ? 3 : 0,
            recommendation: algoResult.morphineResult.recommendation,
            recommendation_coded: MorphineRecommendation[algoResult.morphineResult.recommendationCoded],
            has_accepted_recommendation: false,
            decline_reasons: [],
            recommendation_dosage_mcg: algoResult.morphineResult.calculatedDose,
            recommendation_frequency: algoResult.morphineResult.calculatedDose > 0 ? 3 : 0,
            note: algoResult.morphineResult.note,
          },
          {
            drug_id: Drugs.Clonidine,
            before_recommendation_dosage_mcg: assessmentData.clonidineDose,
            before_recommendation_frequency: assessmentData.clonidineFrequency,
            recommendation: algoResult.clonidineResult.recommendation,
            recommendation_coded: ClonidineRecommendation[algoResult.clonidineResult.recommendationCoded],
            has_accepted_recommendation: false,
            decline_reasons: [],
            recommendation_dosage_mcg: algoResult.clonidineResult.calculatedDose,
            recommendation_frequency: algoResult.clonidineResult.frequency,
            note: algoResult.clonidineResult.note,
          },
        ],
        created: new Date(),
        isComplete: true, // Mark as complete. Triggers state changes.
      };
    });
  };

  const fetchAssessment = useCallback(async () => {
    const defaultAssessmentVersion = 1;
    const version = user?.tenants.find((t) => t.key === tenantKey)?.assessment_version ?? defaultAssessmentVersion;
    const { data }: AxiosResponse<AssessmentType> = await getAssessment(version);
    setAssessment(data);
  }, [setAssessment, user, tenantKey]);

  const offlineAssessment = useCallback(async () => {
    // Validate tenant key exists
    if (tenantKey === null) return;

    // Set offline data
    setAssessmentResult((current) => {
      return {
        ...current,
        offline: {
          tenant_key: tenantKey,
          created_by: null,
        },
      };
    });
  }, [tenantKey, setAssessmentResult]);

  const fetchPatientData = useCallback(async () => {
    if (patientId) {
      const { data }: AxiosResponse<PatientMedicalRecord> = await getPatientInformation(patientId);

      setPatientMedicalData(new PatientMedicalRecord(data));
      setAssessmentData((current) => {
        return {
          ...current,
          refId: data.ref_id || 0,
          patientId: data.ref_id,
          familyName: data.family_name,
          givenName: data.given_name,
          datetimeOfBirth: data.datetime_of_birth,
          hasEverHadClonidine: data.has_ever_had_clonidine,
          hasEverHadMorphine: data.has_ever_had_morphine,
          hasClonidineBeenTrialedOff: data.has_clonidine_been_trialed_off,
          hasTakenLongActingOpiods: data.has_taken_long_acting_opioids,
          narcoticIds: data.narcotic_ids,
          otherNarcotics: data.other_narcotics,
          birthWeightKg: data.birth_weight_kg,
        };
      });
      setAssessmentResult((current) => {
        return {
          ...current,
          patient_id: patientId,
        };
      });
    }
  }, [patientId, setAssessmentData]);

  const fetchClonidineTrialedOff = useCallback(
    async (clonidineDose: number) => {
      if (isOnline && patientId) {
        const { data }: AxiosResponse<{ result: boolean }> = await postClonidineTrialedOffByPatientId(
          patientId,
          clonidineDose,
        );

        setAssessmentData((current) => {
          return { ...current, hasClonidineBeenTrialedOff: data.result };
        });
      }
    },
    [patientId, setAssessmentData, isOnline],
  );

  const fetchCheckDrugChanges = useCallback(
    async (clonidineDose: number, clonidineFrequency: number, morphineDose: number) => {
      if (patientId) {
        const response: AxiosResponse<DrugChangesResultRecord> = await postCheckDrugChanges(
          patientId,
          clonidineDose,
          clonidineFrequency,
          morphineDose,
        );
        setAssessmentData((current) => {
          return {
            ...current,
            changedClonidineDate: response.data[Drugs.Clonidine]?.changed ?? null,
            changedMorphineDate: response.data[Drugs.Morphine]?.changed ?? null,
            clonidineIncreasedInLastSixHours: null, // Mark null so algo knows to calculate from data below:
            lastClonidineDoseChangeDose: response.data[Drugs.Clonidine]?.before_recommendation_dose ?? null,
            lastClonidineDoseChangeFrequency: response.data[Drugs.Clonidine]?.before_recommendation_frequency ?? null,
          };
        });
      }
    },
    [patientId, setAssessmentData],
  );

  const fetchLastAssessmentResult = useCallback(async () => {
    if (patientId) {
      const response: AxiosResponse<AssessmentResultRecord> = await getLastAssessmentResultsByPatientId(patientId);

      // No results found
      if (response.status === 404) return null;

      return new AssessmentResultRecord(response.data);
    }
  }, [patientId]);

  const fetchAndSetAssessment = useCallback(async () => {
    try {
      if (isOnline) {
        setSubmitting(true);
        await Promise.all([fetchAssessment(), fetchPatientData()]);
        const lastResult = await fetchLastAssessmentResult();

        if (lastResult) {
          const createdTime = dayjs(lastResult?.created);
          const currentTime = dayjs();
          const hoursDifference = currentTime.diff(createdTime, 'hour');
          const lastAssessmentClonidine = lastResult.assessment_result_drug.find((d) => d.drug_id === Drugs.Clonidine);
          const lastAssessmentMorphine = lastResult.assessment_result_drug.find((d) => d.drug_id === Drugs.Morphine);
          const lastAssessmentClonidineRecommendation =
            ClonidineRecommendation[
              lastAssessmentClonidine?.recommendation_coded as keyof typeof ClonidineRecommendation
            ];
          const lastAssessmentMorphineRecommendation =
            MorphineRecommendation[lastAssessmentMorphine?.recommendation_coded as keyof typeof MorphineRecommendation];
          const hasAssessmentGap = hoursDifference > MAX_HOURS_GAP_ASSESSMENT;

          setCurrentStep(hasAssessmentGap ? CommonQuestionSteps.LastKnowAssessment : MAIN_ASSESSMENT_START_STEP);
          setStartStep(hasAssessmentGap ? CommonQuestionSteps.LastKnowAssessment : MAIN_ASSESSMENT_START_STEP);

          setAssessmentData((current) => {
            return {
              ...current,
              isFirstAssessment: false,
              lastKnownAssessmentDate: lastResult.created,
              symptoms: lastResult.assessment_result_symptom.filter((s) => s.is_poor).map((s) => s.symptom_id),
              lastRecommendedMorphine: lastAssessmentMorphineRecommendation,
              isLastAssessmentRecommendationAnIncrease:
                lastAssessmentClonidineRecommendation === ClonidineRecommendation.Increase,
            };
          });

          await Promise.all([
            fetchClonidineTrialedOff(routeState.currentClonidineDose),
            fetchCheckDrugChanges(
              routeState.currentClonidineDose,
              routeState.currentClonidineFrequency,
              routeState.currentMorphineDose,
            ),
          ]);
        } else {
          setAssessmentData((current) => {
            return {
              ...current,
              isFirstAssessment: lastResult === null,
            };
          });
          setCurrentStep(CommonQuestionSteps.HasPriorAssessment);
          setStartStep(CommonQuestionSteps.HasPriorAssessment);
        }
      } else {
        await offlineAssessment();
        setAssessmentData((current) => {
          return {
            ...current,
            refId: Number(patientId),
          };
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsErrorModalOpen(true);
    } finally {
      setSubmitting(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    patientId,
    assessmentData,
    isOnline,
    offlineAssessment,
    setAssessmentData,
    setCurrentStep,
    setSubmitting,
    fetchAssessment,
    fetchPatientData,
    fetchLastAssessmentResult,
    fetchClonidineTrialedOff,
    fetchCheckDrugChanges,
    LENGTH_COMMON_QUESTIONNAIRE,
  ]);

  useEffect(() => {
    fetchAndSetAssessment();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  useEffect(() => {
    if (patientMedicalData && assessmentData.isFirstAssessment) {
      setAssessmentData((current) => {
        return {
          ...current,
          morphineDose: patientMedicalData?.morphine_dosage_at_the_previous_hospital_mg || 0,
          clonidineDose: patientMedicalData?.clonidine_dosage_at_the_previous_hospital_mcg || 0,
          clonidineFrequency: patientMedicalData?.clonidine_frequency_at_the_previous_hospital || 0,
        };
      });
    }
  }, [assessmentData.isFirstAssessment, patientMedicalData]);

  useEffect(() => {
    if (assessmentFromDB && assessment) {
      const isAssessmentFromDBEmpty = !assessmentFromDB?.length;
      const isVersionDifferent = assessmentFromDB?.[0]?.version !== assessment.version;

      if (isAssessmentFromDBEmpty || isVersionDifferent) {
        db.assessment.clear().then(() => {
          db.assessment.add({
            version: assessment.version,
            comfort_recommendations: assessment.comfort_recommendations,
            questions: assessment.questions,
          });
        });
      }
    }

    if (!isOnline && !assessment) {
      if (assessmentFromDB && assessmentFromDB.length) {
        setAssessment(assessmentFromDB[0]);
      }
    }
  }, [assessmentFromDB, assessment, isOnline]);

  useEffect(() => {
    if (!isOnline && assessmentFromDB !== undefined && !assessmentFromDB.length) {
      setOfflineWarningModalOpen(true);
    }
  }, [assessmentFromDB, isOnline]);

  useEffect(() => {
    if (assessmentResult?.isComplete) {
      if (isOnline) {
        console.log('Posting assessment result:', assessmentResult);
        // Post results
        postAssessmentResult(assessmentResult).then(() => {
          // Reset
          setAssessmentResult(new AssessmentResultRecord());
          setAssessmentData(new AssessmentDataRecord());
          // Route to patient page
          const route = replaceRouteParamWithValue(AppRoutes.CurrentPatient, 'id', patientId);
          navigate(route);
        });
      } else {
        db.assessmentResults.add({ ...assessmentResult, ref_id: patientId ?? '' }).then(() => {
          const route = replaceRouteParamWithValue(AppRoutes.CurrentPatient, 'id', patientId);
          navigate(route);
        });
      }
    }
  }, [assessmentResult, navigate, patientId, isOnline]);

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorMessage('');
  };

  const closeOfflineWarningModal = () => {
    setOfflineWarningModalOpen(false);
    navigate(AppRoutes.Login);
  };

  const contextValue = useMemo(
    () => ({
      currentStep,
      assessment,
      assessmentData,
      assessmentResult,
      setCurrentStep,
      setAssessmentData,
      setAssessmentResult,
    }),
    [assessment, assessmentData, setAssessmentData, assessmentResult, setAssessmentResult, currentStep, setCurrentStep],
  );

  if (isSubmitting) {
    return (
      <div className="flex items-center h-[calc(100vh-174px)]">
        <Loader />
      </div>
    );
  }

  return (
    <AssessmentContext.Provider value={contextValue}>
      <div className="py-6 px-10 h-[calc(100vh-174px)] overflow-y-auto">
        <div className="w-full max-w-[768px] mx-auto">
          <AssessmentHeader refId={assessmentData?.refId} isFirstAssessment={assessmentData.isFirstAssessment} />
          {currentStep <= LENGTH_COMMON_QUESTIONNAIRE && (
            <CommonQuestionnaire ref={commonAssessment} relativeStep={currentStep - 1} proceedToNextStep={nextStep} />
          )}
          {currentStep > LENGTH_COMMON_QUESTIONNAIRE && (
            <MainAssessmentQuestionnaire
              ref={mainAssessment}
              relativeStep={currentStep - MAIN_ASSESSMENT_START_STEP}
              proceedToNextStep={nextStep}
            />
          )}
        </div>
      </div>

      <div className="sticky bottom-0 px-10 py-6 flex justify-between items-center bg-white-300 dark:bg-blue-500 border-t-[1px] border-gray-200 dark:border-white-400/10">
        <BaseButton
          className={cn({ 'opacity-0 cursor-not-allowed pointer-events-none': startStep === currentStep })}
          disabled={currentStep === CommonQuestionSteps.PatientName}
          scheme="cta-secondary"
          size="xl"
          isIconOnLeftSide
          icon={LeftIcon}
          onClick={handleBackStep}
        >
          Back
        </BaseButton>
        <div className="w-full max-w-[160px]">
          <ProgressBar progress={questionnaireProgress()} />
        </div>
        <div className="flex gap-3 items-center">
          <BaseButton
            scheme="cta-primary"
            size="xl"
            icon={RightIcon}
            disabled={isNextStepDisabled()}
            onClick={() => nextStep()}
          >
            Next
          </BaseButton>
        </div>
      </div>

      {isErrorModalOpen && (
        <ErrorModal onCancelClick={closeErrorModal} onRetryClick={fetchAssessment} errorMessage={errorMessage} />
      )}
      {isOfflineWarningModalOpen && (
        <MessageModal
          icon={AlertIcon}
          iconProps={{ className: 'text-red-400' }}
          isShownCloseButton={false}
          successText="Back to login"
          cancelText="Cancel"
          title="Sorry, but the assessment requires the device to be online to receive basic assessment questions"
          description="Please check your device settings and try again."
          onSuccessClick={closeOfflineWarningModal}
        />
      )}
    </AssessmentContext.Provider>
  );
};

export const useAssessment = (): AssessmentContextType => {
  const context = useContext(AssessmentContext);
  if (!context) {
    throw new Error('useAssessment must be used within an AssessmentProvider');
  }
  return context;
};
