export enum Symptoms {
  Sleep = '1b6556b1-e497-45ff-a337-d1f7e340120a',
  Tremors = '31add209-7ee7-489d-a8ad-4b903ba2befc',
  Feeding = '0b89b6e9-b1d0-49ee-90bc-202cf99c7719',
  Stools = 'e89b6991-a070-4258-afc8-9d13b057b319',
  Crying = '5c61c7f5-2804-4fac-8c09-f1130963aeef',
  Temperature = '4d4efb53-2310-46aa-896c-fa5719d5482e',
  'Head Lag' = 'c404e192-3e85-47e2-83d1-944e738263a1',
  Reflex = '27917caf-bf25-4653-bec1-ec52fc8ada9c',
  Rate = '034066e9-077e-4260-b3b1-378c3e409f87',
  Retractions = '87fc8de1-c790-4b91-ad75-2efa02fb564f',
  'Nasal Flaring' = 'a085b368-a46d-4450-909a-d6045119e34c',
  'Nasal Stuffiness' = '2d63668e-2304-4921-8c5f-0559afade1fa',
  Sweating = 'a5478aad-f9ed-4316-aa4f-c46911cf4299',
  Sneezing = 'b9e56ec5-b396-4308-b9c4-60abe98d8399',
  Yawning = 'f7e02f8f-a28b-4a15-bcb0-0f2a65327b7e',
  Mottling = 'c69494a5-351a-41e2-ac90-27446e5ded21',
  Excoriation = '4818fe08-80d1-4f48-8bf7-27eaa4e30313',
  'Myoclonic Jerks' = '35036d50-d173-46d6-99e1-e4f3f57ac99a',
  Seizure = '793a1d4d-e38f-4682-923b-1af3d2085a5d',
  Rooting = 'f67cccf5-49c9-4df8-9b27-7bb8ba376de0',
  Emesis = 'a8d7a42f-db5e-4c66-844a-08d4f9ad1b65',
  'Projectile Vomiting' = '55b80971-dae0-4499-a985-e5943d8d1312',
}

export enum Drugs {
  Morphine = '890f1f6d-a3e4-46fc-8420-621421fd134c',
  Clonidine = '3c3b4d1f-ead1-4c67-895b-97a662101a97',
}

export enum PriorOpiates {
  Morphine = 'Morphine',
  Clonidine = 'Clonidine',
}

export enum ComfortMeasures {
  'CSI Comfort Measures' = 'b4656544-7507-4677-8fdb-0da661c9c14e',
  'Standard Comfort Measures' = 'e3e9e3a0-e332-4ad3-a3c9-ec0c0ecbee43',
}

export const TREATMENT_RECOMMENDATIONS = [
  { name: 'Morphine', id: Drugs.Morphine },
  { name: 'Clonidine', id: Drugs.Clonidine },
];

export const COMFORT_RECOMMENDATIONS = [
  { name: 'Standard Comfort Measures', id: ComfortMeasures['Standard Comfort Measures'] },
  { name: 'CSI Comfort Measures', id: ComfortMeasures['CSI Comfort Measures'] },
];

export const changeDirection = {
  increase: 'increase',
  decrease: 'decrease',
  noChange: 'noChange',
};

export const badStabilityLevelLowerBound = 4;
export const minAssessmentsForFirstSurvey = 5;

export type AnswerType = {
  id: string;
  question_id?: string;
  text: string;
  upper_threshold?: number;
  score: number;
  continue_series?: boolean;
};

export type QuestionType = {
  id: string;
  series_key?: string | undefined;
  symptom_id: string;
  text: string;
  subtext: string | null;
  media?: AssessmentMediaType | null;
  annotation?: {
    title: string;
    text: string;
  } | null;
  answer_type: string;
  lower_threshold?: number | undefined;
  upper_threshold?: number | undefined;
  answer_options: AnswerType[];
};

export type ComfortRecommendations = {
  symptom_id: string;
  text: string;
};

export type AssessmentType = {
  version: number;
  comfort_recommendations: ComfortRecommendations[];
  questions: QuestionType[];
};

export type AssessmentResultsType = {
  count: number;
  result: AssessmentResultType[];
};

export type AssessmentResultAnswerType = {
  question_id?: string;
  answer_id: string;
  answer_num?: number;
};

export type AssessmentMediaType = {
  type: string;
  asset: string;
};

export type MeasuresType = {
  type_id: string;
  has_accepted_recommendation: boolean | null;
  decline_reasons: string[];
};

export type AssessmentResultType = {
  id: string;
  version: number;
  created: string;
  overall_score: number;
  hours_old: number;
  assessment_result_symptom: AssessmentResultSymptom[];
  assessment_result_drug: AssessmentResultDrug[];
  comfortRecommendations: MeasuresType[];
};

export type AssessmentResultSymptom = {
  symptom_id: string;
  score: number;
};

export type AssessmentResultDrug = {
  drug_id: string;
  recommendation: string;
  recommendation_coded: string;
  recommendation_dosage_mcg: number;
  recommendation_frequency: number;
  before_recommendation_dosage_mcg: number;
  before_recommendation_frequency: number;
  has_accepted_recommendation: boolean | null;
  decline_reasons: string[] | null;
};

export type RecommendationAcceptanceType = {
  assessment_result_id: string;
  drug_id?: string;
  recommendation_type_id?: string;
  has_accepted_recommendation: boolean | null;
  decline_reasons: string[] | null;
  update_only_decline_reasons: boolean;
};
