import React from 'react';

import dayjs from 'dayjs';

import { DateTimeField } from 'components/ui/form-fields';
import { TIME_FORMAT_OPTIONS } from 'record/onboarding-result.record';
import { QuestionInfo } from 'components/assessment/common/QuestionInfo';

type BornDateStepProps = {
  bornDate: string | null;
  timeOption: string;
  setTimeOption: (value: string) => void;
  onChangeBornDate: (value: Date | null) => void;
};

export const BornDateStep: React.FC<BornDateStepProps> = ({
  bornDate,
  timeOption,
  setTimeOption,
  onChangeBornDate,
}) => {
  const timeOptions = Object.values(TIME_FORMAT_OPTIONS).map(({ label, value }) => ({ label, value }));
  const maxTime =
    !bornDate || dayjs().isSame(dayjs(bornDate), 'day') ? new Date() : new Date(new Date().setHours(23, 59, 59, 999));

  return (
    <div>
      <QuestionInfo
        title="When was the baby born?"
        options={timeOptions}
        selectedOption={timeOption}
        changeOption={setTimeOption}
      />

      <DateTimeField
        label="Date/Time"
        placeholderFormat={
          timeOption === TIME_FORMAT_OPTIONS.military.value
            ? TIME_FORMAT_OPTIONS.military.format
            : TIME_FORMAT_OPTIONS.standard.format
        }
        maxDate={dayjs().toDate()}
        minTime={new Date(new Date().setHours(0, 0, 0, 0))}
        maxTime={maxTime}
        value={bornDate ? new Date(bornDate) : null}
        onChange={(date) => onChangeBornDate(date)}
      />
    </div>
  );
};
