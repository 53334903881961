import React, { HTMLAttributes } from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';

import { BaseButton } from 'components/ui/buttons';

type AnswerButtonProps = {
  currentAnswer: boolean | null;
  onSelect: (value: boolean) => void;
  icon: React.ComponentType<HTMLAttributes<SVGElement>>;
  buttonText: string;
};

export const AnswerButton: React.FC<AnswerButtonProps> = ({ currentAnswer, onSelect, icon, buttonText }) => {
  return (
    <BaseButton
      className={cn(
        'w-full mb-3 !justify-end py-[20px] rounded-xl border',
        'hover:border-gray-800 dark:hover:border-white-400/20 hover:bg-white-300 dark:hover:bg-white-400/10',
        currentAnswer
          ? 'border-gray-800 dark:border-white-400/20 bg-white-400 dark:bg-white-400/20'
          : 'bg-white-400/80 dark:bg-gray-900 border-transparent',
      )}
      icon={icon}
      iconProps={{ className: cn({ 'fill-sea-serpent text-white-400': currentAnswer }) }}
      isIconOnLeftSide
      onClick={() => onSelect(true)}
      scheme="secondary"
    >
      <span>{parse(buttonText)}</span>
    </BaseButton>
  );
};
