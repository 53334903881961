import { useTenancy } from 'contexts/TenantContextProvider';
import { TenantType } from 'types/tenant';
import { OptionType, SelectField } from './form-fields';

type TenantSelectorProps = {
  tenants: TenantType[];
  onChange: (value: string | number | null) => void;
};

export const TenantSelector: React.FC<TenantSelectorProps> = ({ tenants, onChange }) => {
  const tenantKey = useTenancy();

  const options: OptionType[] =
    tenants.map((tenant) => ({
      value: tenant.key,
      label: tenant.name,
    })) || [];

  return (
    <SelectField
      name="tenantSelector"
      value={tenantKey}
      options={options}
      onChange={(value) => {
        if (typeof value === 'string') {
          onChange(value);
        }
      }}
    />
  );
};
