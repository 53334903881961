import React, { useMemo } from 'react';
import cn from 'classnames';

import { badStabilityLevelLowerBound, changeDirection } from 'types/assessment';
import { DecreaseIcon, IncreaseIcon, NoChangeIcon } from 'assets/icons';

type StabilityLevelProps = {
  level: number;
  levelDirection: string | null;
  isShortVersion?: boolean;
  isActive?: boolean;
};

export const StabilityLevel: React.FC<StabilityLevelProps> = ({
  level,
  levelDirection,
  isShortVersion = false,
  isActive = true,
}) => {
  const iconClass = `w-full ${isActive ? (level >= badStabilityLevelLowerBound ? 'text-red-400' : 'text-green-400') : 'text-silver-400'}`;
  const levelClass = `${isActive ? (level >= badStabilityLevelLowerBound ? 'text-red-400' : 'text-green-400') : 'text-silver-400'}`;

  const stabilityLevelInfo = useMemo(() => {
    switch (levelDirection) {
      case changeDirection.increase:
        return {
          text: 'Improves',
          description: 'Condition has improved.',
          class: `top-[10px] right-[11px]`,
          icon: <IncreaseIcon className={iconClass} />,
        };
      case changeDirection.decrease:
        return {
          text: 'Worsened',
          description: 'Condition has worsened.',
          class: `top-[7px] right-[11px]`,
          icon: <DecreaseIcon className={iconClass} />,
        };
      default:
        return {
          text: 'Stable',
          description: 'Condition is stable',
          class: `top-[7px] right-[15px]`,
          icon: <NoChangeIcon className={iconClass} />,
        };
    }
  }, [levelDirection, iconClass]);

  return (
    <div className="relative flex items-center gap-2.5">
      {isShortVersion ? (
        <div className="flex relative w-[56px]">
          <div
            className={cn(
              'flex justify-center items-center w-[50px] h-[50px] p-[9px] rounded-full border-4 border-solid',
              'border-white-400 dark:border-gray-900 text-white-400 text-center text-xl font-semibold leading-[17px] z-10 shadow-level',
              level >= badStabilityLevelLowerBound ? 'bg-red-400' : 'bg-green-400',
            )}
          >
            S{level}
          </div>
          <div
            className={cn(
              'absolute w-[17px] h-[17px] shadow-level',
              { 'top-[17px] right-0 rotate-45': levelDirection === changeDirection.noChange },
              { 'top-0 right-[7px] rotate-70': levelDirection === changeDirection.increase },
              { 'bottom-0 right-[7px] rotate-15': levelDirection === changeDirection.decrease },
              level >= badStabilityLevelLowerBound ? 'bg-red-400' : 'bg-green-400',
            )}
          />
        </div>
      ) : (
        <div className={cn('flex relative w-[76px]', { grayscale: !isActive })}>
          {stabilityLevelInfo.icon}
          <span
            className={cn(
              'absolute text-xs text-center font-semibold leading-[17px] w-[26px] h-[20px]',
              stabilityLevelInfo.class,
              levelClass,
            )}
          >
            S{level}
          </span>
        </div>
      )}

      <div className="flex flex-col">
        <p
          className={cn(
            'text-sm font-medium leading-[140%]',
            !isActive ? 'text-gray-800' : 'text-gray-900 dark:text-white-400',
          )}
        >
          {stabilityLevelInfo.text}
        </p>
        {stabilityLevelInfo.description && (
          <p className="text-sky dark:text-silver-400 text-xs font-medium leading-[17px]">
            {stabilityLevelInfo.description}
          </p>
        )}
      </div>
    </div>
  );
};
