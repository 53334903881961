import React from 'react';
import { MoonIcon, SunIcon } from 'assets/icons';
import Switch from 'react-switch';
import { useTheme } from 'contexts';

type ThemeSwitchProps = {
  isDarkMode: boolean;
};

export const ThemeSwitch: React.FC<ThemeSwitchProps> = ({ isDarkMode }) => {
  const { theme, toggleTheme } = useTheme();

  const isLightTheme = theme === 'light';
  return (
    <div className="relative flex justify-center items-center h-[44px] w-[86px] border border-gray-200 dark:border-sky rounded-full">
      <Switch
        className="theme-switch"
        height={42}
        width={84}
        offColor={'#202a37'}
        onColor={'#fff'}
        offHandleColor={'#4b5563'}
        onHandleColor="#fff"
        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
        checkedIcon={
          <div className="flex justify-center items-center w-full h-full">
            <MoonIcon className="[&>path]:stroke-gray-800" />
          </div>
        }
        uncheckedIcon={
          <div className="flex justify-center items-center w-full h-full">
            <SunIcon className="[&>g>path]:fill-silver-400 [&>g>path]:stroke-silver-400" />
          </div>
        }
        checkedHandleIcon={
          <div className="flex justify-center items-center w-full h-full">
            <SunIcon className="[&>g>path]:fill-indigo [&>g>path]:stroke-indigo" />
          </div>
        }
        uncheckedHandleIcon={
          <div className="flex justify-center items-center w-full h-full">
            <MoonIcon className="[&>path]:stroke-white-400" />
          </div>
        }
        onChange={toggleTheme}
        checked={isLightTheme}
      />
    </div>
  );
};
