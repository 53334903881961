import React, { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import Select, { components, OptionProps } from 'react-select';

import { useTheme } from 'contexts';
import { TickIcon } from 'assets/icons';

export type OptionType = {
  value: string | number | null;
  label: string | number;
  isDisabled?: boolean;
};

export type BaseSelectProps = {
  isLoading?: boolean;
  options: Array<OptionType>;
  onChange?: (newValue: string | number | null | OptionType[]) => void;
  name?: string;
  value: string | number | null | OptionType[];
  wrapperClassName?: string;
  labelClassName?: string;
  selectClassName?: string;
  schema?: 'primary' | 'secondary';
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  blankPlaceholder?: boolean;
  onClick?: () => void;
  isMulti?: boolean;
};

const CustomOption: React.FC<OptionProps<OptionType>> = ({ children, ...props }) => {
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';
  return (
    <components.Option {...props}>
      <div
        className={cn(
          'flex justify-between bg-transparent items-center px-3.5 py-2 rounded-md text-xs font-medium leading-[17px] h-9 cursor-pointer',
          {
            'text-indigo font-semibold': props.isSelected,
          },
          isDarkMode ? 'text-white-400 hover:bg-blue-900' : 'text-gray-500 hover:bg-mint',
        )}
      >
        {children}
        {props.isSelected && <TickIcon />}
      </div>
    </components.Option>
  );
};

export const SelectField: React.FC<BaseSelectProps> = ({ schema = 'primary', ...props }) => {
  const [portal, setPortal] = useState<typeof window.document.body>();
  const { theme } = useTheme();
  const isDarkMode = theme === 'dark';

  const placeholder = useMemo(() => {
    if (props.blankPlaceholder) return '';

    if (props.placeholder) return props.placeholder;

    return 'Select...';
  }, [props.placeholder, props?.blankPlaceholder]);

  useEffect(() => {
    setPortal(window.document.body);
  }, []);

  const schemaStyles = {
    primary: {
      borderRadius: '500px',
    },
    secondary: {
      borderRadius: '6px',
    },
  };

  return (
    <label
      className={cn(
        props.labelClassName,
        'w-full relative text-blue-500 text-xs font-medium leading-[17px] dark:text-white-400',
        {
          'cursor-not-allowed': props.disabled,
        },
      )}
    >
      {props.label}
      <Select
        value={
          props.isMulti ? (props.value as OptionType[]) : props.options.find((option) => option.value === props.value)
        }
        options={props.options}
        name={props.name}
        isMulti={props.isMulti}
        menuPortalTarget={portal}
        className={cn(
          props.selectClassName,
          'w-full text-gray-500 text-sm bg-transparent font-medium leading-6 rounded-full',
        )}
        maxMenuHeight={200}
        onChange={(e) => {
          props.onChange && e && props.onChange(props.isMulti ? (e as OptionType[]) : (e as OptionType).value);
        }}
        placeholder={placeholder}
        isDisabled={props.disabled}
        isSearchable={false}
        isOptionDisabled={(option) => 'isDisabled' in option && Boolean(option.isDisabled)}
        components={{
          Option: CustomOption,
        }}
        closeMenuOnSelect={!props.isMulti}
        styles={{
          indicatorSeparator: () => ({
            display: 'none',
          }),
          control: (base, state) => ({
            ...base,
            borderRadius: schemaStyles[schema].borderRadius,
            minHeight: schema === 'secondary' ? '44px' : base.minHeight,
            backgroundColor: state.isFocused ? '#e5e7eb' : isDarkMode ? 'transparent' : base.backgroundColor,
            borderColor: schema === 'secondary' ? '#d1d5db' : 'transparent',
            cursor: 'pointer',
            boxShadow: state.isFocused ? 'none' : base.boxShadow,
            outline: 'none',
            ':hover': {
              backgroundColor: isDarkMode ? '#4b5563' : '#f3f4f6',
              borderColor: 'transparent',
            },
          }),
          multiValue: (base) => ({ ...base, borderRadius: '6px' }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          singleValue: (base, state) => ({
            ...base,
            minWidth: '60px',
            color: isDarkMode ? '#ffffff' : '#4b5563',
          }),
          menu: (base) => ({
            ...base,
            padding: '6px',
            borderRadius: '8px',
            border: `1px solid ${isDarkMode ? '#6b7280' : '#d1d5db'}`,
            background: isDarkMode ? '#4b5563' : '#ffffff',
            boxShadow: '0px 6px 10px -1px rgba(6, 25, 56, 0.07)',
          }),
          option: (base, state) => ({
            ...base,
            padding: '0',
            outline: 'none',
            backgroundColor: 'transparent',
            ':active': {
              backgroundColor: 'transparent',
            },
          }),
        }}
      />
    </label>
  );
};
