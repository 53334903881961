import React from 'react';

import cn from 'classnames';

import { BaseButton } from 'components/ui/buttons';
import { InputField, OptionType, SelectField } from 'components/ui/form-fields';
import { PriorOpiates } from 'types/assessment';

type DrugInputFieldProps = {
  drugName: string;
  onChanges: (value: string, status: boolean) => void;
  error?: string | null;
  isDisabled?: boolean | null;
  isHadEverDrug?: boolean | null;
  options?: OptionType[];
  selectedOption?: number | null;
  onChangeFrequency?: (value: string | number | null) => void;
  onDoseChange: (drugName: string, dose: number | null) => void;
};

export const DrugInputField: React.FC<DrugInputFieldProps> = ({
  drugName,
  onChanges,
  isDisabled,
  isHadEverDrug,
  error,
  onChangeFrequency,
  options,
  selectedOption,
  onDoseChange,
}) => {
  const [isShowInput, setShowInput] = React.useState<boolean | null>(isHadEverDrug || null);
  const drugDoseSuffix = drugName === PriorOpiates.Morphine ? 'mg/kg' : 'mcg/kg';

  return (
    <div
      className={cn(
        'mb-3 bg-white-400/80 dark:bg-gray-900 py-[22px] rounded-xl border dark:hover:bg-white-400/10 dark:hover:border-white-400/20',
        isShowInput
          ? 'border-gray-800 bg-white-400 dark:bg-white-400/20 dark:border-white-400/20'
          : 'border-transparent',
        { 'opacity-70 pointer-events-none': isDisabled },
      )}
    >
      <div className="flex pl-5 pr-3 justify-between items-center">
        <p className="flex text-gray-900 dark:text-white-400 text-base font-semibold leading-6">{drugName}</p>

        <div className="flex justify-end gap-2">
          <BaseButton
            className={cn({ '!bg-green-200 !dark:hover:bg-green-400/10': isHadEverDrug === true })}
            size="sm"
            scheme="stroke"
            onClick={() => {
              setShowInput(true);
              onChanges(drugName, true);
            }}
          >
            YES
          </BaseButton>
          <BaseButton
            className={cn({ '!bg-green-200 !dark:hover:bg-green-400/10': isHadEverDrug === false })}
            size="sm"
            scheme="stroke"
            onClick={() => {
              onChanges(drugName, false);
              setShowInput(false);
            }}
          >
            NO
          </BaseButton>
        </div>
      </div>

      {isShowInput && (
        <div className="flex flex-col">
          <div className="flex justify-between items-center pl-5 pr-3 pt-5 mt-4 border-t border-silver-400">
            <p className="w-[310px] text-gray-900 dark:text-silver-400 text-sm font-medium leading-[140%]">
              What was the patient’s dose of <span className="lowercase">{drugName}</span> at their previous hospital?
            </p>
            <div className="max-w-[264px] w-1/2">
              <InputField
                name={drugName.toLowerCase()}
                type="number"
                placeholder="Enter dose"
                onChange={(event) => onDoseChange(drugName, event.target.value ? Number(event.target.value) : null)}
                suffix={drugDoseSuffix}
                errorMessage={error || ''}
              />
            </div>
          </div>
          {options?.length && (
            <div className="flex justify-between items-center pl-5 pr-3 pt-5">
              <p className="w-[310px] text-gray-900 dark:text-silver-400 text-sm font-medium leading-[140%]">
                What was the patient’s frequency confirming dose of <span className="lowercase">{drugName}</span>?
              </p>
              <div className="max-w-[264px] w-1/2">
                <SelectField
                  options={options}
                  value={selectedOption || null}
                  onChange={(value) => {
                    if (typeof value === 'string' || typeof value === 'number') {
                      onChangeFrequency && onChangeFrequency(value);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
