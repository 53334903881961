import React from 'react';

import { AlertIcon } from 'assets/icons';
import { BaseModal } from 'components/ui';
import { BaseButton } from 'components/ui/buttons';
import { CheckboxField } from 'components/ui/form-fields';

type MessageModalProps = {
  onSuccessClick?: () => void;
  onCancelClick?: () => void;
  onCloseClick?: () => void;
  isExposedOpioid: boolean;
  onChangesExposedOpioid: (value: boolean) => void;
};

export const SubstanceModal: React.FC<MessageModalProps> = ({
  onSuccessClick,
  onCancelClick,
  onCloseClick,
  isExposedOpioid,
  onChangesExposedOpioid,
}) => {
  return (
    <BaseModal isShownCloseButton onCloseClick={onCloseClick}>
      <div className="max-w-[500px] px-12 flex flex-col items-center">
        <AlertIcon className="mb-6 [&>g>path]:fill-silver-400" />
        <h2 className="text-center mb-4 text-gray-600 dark:text-white-400 text-[19px] font-semibold leading-7">
          <span>Please note AssessPro is only for opiate exposed infants.</span>
        </h2>
        <p className="mb-8 text-gray-600 dark:text-gray-200 text-sm font-normal leading-[140%]">
          If your infant has not been opiate exposed in utero, but was opiate exposed in the hospital, please select 'no
          exposure' and continue. If they have had no opiate exposure, please contact ordering provider to confirm
          standing order.
        </p>

        <div className="flex items-center gap-3 bg-mint/50 px-3 py-2 rounded-lg mb-8">
          <CheckboxField
            label="Infant was exposed to opioids post delivery"
            name="substance"
            checked={isExposedOpioid}
            onChange={(event) => onChangesExposedOpioid(event.target.checked)}
          />
        </div>

        <div className="flex gap-4 w-full justify-center">
          <BaseButton scheme="secondary" className="w-full" onClick={onCancelClick}>
            Cancel
          </BaseButton>
          <BaseButton scheme="primary" className="w-full" onClick={onSuccessClick}>
            Continue
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
};
