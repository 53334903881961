import { useTenancy } from 'contexts/TenantContextProvider';

export const useTenantRedirect = () => {
  const tenantKey = useTenancy();

  const redirectToTenant = (newTenantKey: string) => {
    if (tenantKey === newTenantKey) return;

    const domain = window.location.host.split('.').slice(1).join('.');
    const urlToRedirect = `https://${newTenantKey}.${domain}/roster`;
    window.location.href = urlToRedirect;
  };

  return redirectToTenant;
};
