import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

type TenancyContextType = string | null;
type TenancyProviderProps = {
  children: ReactNode;
};

export const TenancyContext = createContext<TenancyContextType>(null);

export const useTenancy = (): TenancyContextType => useContext(TenancyContext);

export const TenancyProvider: React.FC<TenancyProviderProps> = ({ children }) => {
  const [tenantKey, setTenantKey] = useState<TenancyContextType>(null);

  const isTenantKeyValid = tenantKey !== null && tenantKey !== '' && tenantKey.length > 0;

  useEffect(() => {
    // Extract tenant key from the domain
    const domain = window.location.hostname;
    const parts = domain.split('.');
    const tenantKeyFromDomain = parts[0];

    setTenantKey(tenantKeyFromDomain);
  }, []);

  return <TenancyContext.Provider value={tenantKey}>{isTenantKeyValid && children}</TenancyContext.Provider>;
};
