import React from 'react';
import cn from 'classnames';

import { BaseModal } from 'components/ui';
import { BaseButton } from 'components/ui/buttons';
import { CheckIcon, CloseCircleIcon } from 'assets/icons';
import stopAssessmentImage from 'assets/images/stop_assessment.png';

type StopAssessmentModalProps = {
  isContinueAssessment: boolean;
  onContinue: (value?: boolean) => void;
  onDiscontinue: () => void;
};

export const StopAssessmentModal: React.FC<StopAssessmentModalProps> = ({
  onContinue,
  onDiscontinue,
  isContinueAssessment,
}) => {
  return (
    <BaseModal isShownCloseButton onCloseClick={() => onContinue()}>
      <div className="flex flex-col mt-4 items-center max-w-[500px] text-center">
        <img src={stopAssessmentImage} alt="stop-assessment" className="pb-4 h-auto max-w-[180px]" />
        <h2 className="mb-3 px-10 text-silver-800 dark:text-white-400 text-[19px] font-semibold leading-7">
          You may have reached criteria to&nbsp;discontinue assessments
        </h2>
        <div className="flex flex-col items-start text-left gap-4 self-stretch border border-gray-200 bg-white-400 dark:bg-gray-900 px-8 py-4 rounded-lg border-solid">
          <p className="mb-3 text-gray-900 dark:text-gray-200 text-sm font-semibold leading-[130%]">
            Discontinuing assessments are recommended if all of the following criteria are met:
          </p>
          <ul className="flex flex-col gap-1.5 items-start text-gray-900 dark:text-white-400 text-xs font-medium leading-[17px]">
            <li className="flex gap-1.5">
              <span className="flex justify-center items-center min-w-[16px] h-[16px] text-white-400 rounded-full bg-green-400">
                <CheckIcon className="w-3 h-3" />
              </span>
              Infant has met the minimal observation time based on exposure (3-5 days).
            </li>
            <li className="flex gap-1.5">
              <span className="flex justify-center items-center min-w-[16px] h-[16px] text-white-400 rounded-full bg-green-400">
                <CheckIcon className="w-3 h-3" />
              </span>
              Infant has been off Morphine for at least 24 hrs OR was not started on morphine.
            </li>
            <li className="flex gap-1.5">
              <span className="flex justify-center items-center min-w-[16px] h-[16px] text-white-400 rounded-full bg-green-400">
                <CheckIcon className="w-3 h-3" />
              </span>
              Infant has been off clonidine for at least 24 hrs OR has been on stable home dose for at least 24 hrs.
            </li>
            <li className="flex gap-1.5">
              <span className="flex justify-center items-center min-w-[16px] h-[16px] text-white-400 rounded-full bg-green-400">
                <CheckIcon className="w-3 h-3" />
              </span>
              Infants symptoms have been stable and within acceptable range for at least 24hr.
            </li>
          </ul>
        </div>

        <div
          className={cn('flex gap-4 mt-8', isContinueAssessment ? 'w-full justify-between' : 'flex-col justify-center')}
        >
          <BaseButton
            className={isContinueAssessment ? 'w-full' : ''}
            scheme={isContinueAssessment ? 'secondary' : 'cta-primary'}
            size={isContinueAssessment ? 'md' : 'xl'}
            isIconOnLeftSide
            icon={isContinueAssessment ? undefined : CloseCircleIcon}
            onClick={onDiscontinue}
          >
            Discontinue assessments
          </BaseButton>
          {isContinueAssessment ? (
            <BaseButton
              className={isContinueAssessment ? 'w-full' : ''}
              onClick={() => {
                onContinue(true);
              }}
            >
              Start new assessment
            </BaseButton>
          ) : (
            <BaseButton scheme="transparent" onClick={() => onContinue()}>
              Continue assessments
            </BaseButton>
          )}
        </div>
      </div>
    </BaseModal>
  );
};
