import {
  ClonidineRecommendation,
  ClonidineRecommendationResult,
  getClonidineRecommendation,
} from 'algorithms/clonidine-algorithm';
import {
  MorphineRecommendation,
  MorphineRecommendationResult,
  getDosageAndClonidineRecommendation,
} from 'algorithms/morphine-algorithm';
import { getComfortMeasures } from 'algorithms/comfort-measures-algorithm';
import { calculateScoreForSymptoms } from 'algorithms/symptom-scores-algorithm';
import { determineEvaluationScore, determineActiveSymptoms } from 'algorithms/determine-evaluation-score-algorithm';
import { AssessmentResultAnswerType, AssessmentType, Symptoms } from 'types/assessment';
import { Narcotics } from 'record/onboarding-result.record';
import { SymptomScore } from 'algorithms/types/symptom-score';
import { AssessmentDataRecord } from 'record/assessment-data.record';

export type AssessProAlgoResult = {
  score: number;
  symptomScores: SymptomScore[];
  activeSymptoms: string[];
  hoursSinceBirth: number;
  morphineResult: MorphineRecommendationResult;
  clonidineResult: ClonidineRecommendationResult;
  comfortRecommendations: string[] | null;
};

const hoursSinceDate = (date: Date): number => {
  return Math.floor(Math.abs(new Date().getTime() - date.getTime()) / (60 * 60 * 1000));
};

const fixFloatPrecision = (value: number): number => {
  return parseFloat(value.toPrecision(12));
};

export const executeAssessProAlgorithm = (
  assessment: AssessmentType,
  assessmentData: AssessmentDataRecord,
  answers: AssessmentResultAnswerType[],
): AssessProAlgoResult => {
  if (!assessment || !answers || answers.length === 0 || !assessmentData.datetimeOfBirth) {
    throw new Error('Missing data to run algorithm');
  }

  const symptomScores = calculateScoreForSymptoms(answers, assessment.questions);

  const activeSymptoms = determineActiveSymptoms(symptomScores);

  const score = determineEvaluationScore(activeSymptoms);

  const hoursSinceBirth = hoursSinceDate(new Date(assessmentData.datetimeOfBirth));

  if (!assessmentData.isFirstAssessment) {
    const priorActiveSymptoms = assessmentData.symptoms;
    const priorScore = determineEvaluationScore(priorActiveSymptoms);

    // Calculate if clonidine dose has increased in the last six hours
    if (assessmentData.clonidineIncreasedInLastSixHours === null) {
      // Check that we have enough data to calculate clonidine increase
      if (
        assessmentData.lastClonidineDoseChangeDose === null ||
        assessmentData.lastClonidineDoseChangeFrequency === null
      ) {
        assessmentData.clonidineIncreasedInLastSixHours = false;
      } else {
        const lastClonidineDoseChangeDoseValue = fixFloatPrecision(
          assessmentData.lastClonidineDoseChangeDose / assessmentData.lastClonidineDoseChangeFrequency,
        );
        const currentClonidineDoseChangeDoseValue = fixFloatPrecision(
          assessmentData.clonidineDose / assessmentData.clonidineFrequency,
        );
        assessmentData.clonidineIncreasedInLastSixHours =
          lastClonidineDoseChangeDoseValue < currentClonidineDoseChangeDoseValue;
      }
    }

    // Check that we have enough data to calculate morphine and clonidine recommendations
    if (
      !priorScore ||
      !assessmentData.datetimeOfBirth ||
      assessmentData.hasEverHadClonidine === null ||
      assessmentData.hasEverHadMorphine === null ||
      (assessmentData.hasEverHadClonidine === true && assessmentData.hasClonidineBeenTrialedOff === null) ||
      (assessmentData.hasEverHadMorphine === true && assessmentData.lastRecommendedMorphine === null)
    ) {
      throw new Error('Not first assessment and missing prior assessment details.');
    }

    const morphineResult = getDosageAndClonidineRecommendation({
      morphineDose: assessmentData.morphineDose,
      currentAssessmentGISymptoms: {
        poorFeeding: activeSymptoms.includes(Symptoms.Feeding),
        poorStooling: activeSymptoms.includes(Symptoms.Stools),
      },
      currentEvaluationScore: score,
      priorAssessmentGISymptoms: {
        poorFeeding: priorActiveSymptoms.includes(Symptoms.Feeding),
        poorStooling: priorActiveSymptoms.includes(Symptoms.Stools),
      },
      priorEvaluationScore: priorScore,
      priorMorphineRecommendation: assessmentData.lastRecommendedMorphine || MorphineRecommendation.Notstarted,
    });

    const clonidineResult = getClonidineRecommendation({
      morphineDose: assessmentData.morphineDose,
      clonidineDose: assessmentData.clonidineDose,
      clonidineFrequency: assessmentData.clonidineFrequency,
      currentAssessmentGISymptoms: {
        poorFeeding: activeSymptoms.includes(Symptoms.Feeding),
        poorStooling: activeSymptoms.includes(Symptoms.Stools),
      },
      hasEverTakenClonidine: assessmentData.hasEverHadClonidine,
      hasEverTakenMorphine: assessmentData.hasEverHadMorphine,
      hasTrialedOffClonidine: assessmentData.hasClonidineBeenTrialedOff || false,
      hoursSinceBirth: hoursSinceBirth,
      inuteroOpiates: {
        isTakingBuprenorphine:
          assessmentData.narcoticIds?.includes(Narcotics['Bupernophine (Subutex/Suboxone)']) ?? false,
        isTakingMethadone: assessmentData.narcoticIds?.includes(Narcotics.Methadone) ?? false,
        isTakingOther:
          (assessmentData.otherNarcotics?.length ?? 0) > 0 ||
          (assessmentData.narcoticIds?.filter(
            (n) => n !== Narcotics.Methadone && n !== Narcotics['Bupernophine (Subutex/Suboxone)'],
          ).length ?? 0) > 0,
      },
      hoursSinceLastClonidineChange:
        assessmentData.changedClonidineDate === null
          ? null
          : hoursSinceDate(new Date(assessmentData.changedClonidineDate)),
      hoursSinceLastMorphineChange:
        assessmentData.changedMorphineDate === null
          ? null
          : hoursSinceDate(new Date(assessmentData.changedMorphineDate)),
      isIncreaseComparedToSixHoursAgo: assessmentData.clonidineIncreasedInLastSixHours,
      isLastAssessmentRecommendationAnIncrease: assessmentData.isLastAssessmentRecommendationAnIncrease || false,
      priorAssessmentGISymptoms: {
        poorFeeding: priorActiveSymptoms.includes(Symptoms.Feeding),
        poorStooling: priorActiveSymptoms.includes(Symptoms.Stools),
      },
      morphineRecommendationResult: {
        forceClonidineStart: morphineResult.forceClonidineStart,
        recommendationCoded: morphineResult.recommendationCoded,
      },
    });

    const comfortRecommendations = getComfortMeasures(symptomScores, assessment.comfort_recommendations);

    return {
      score,
      symptomScores,
      activeSymptoms,
      hoursSinceBirth,
      morphineResult: morphineResult,
      clonidineResult: clonidineResult,
      comfortRecommendations: comfortRecommendations,
    };
  } else {
    // First Assessment
    return {
      score,
      symptomScores,
      activeSymptoms,
      hoursSinceBirth,
      morphineResult: {
        recommendation: assessmentData.morphineDose > 0 ? 'No Change' : 'Not Started',
        recommendationCoded:
          assessmentData.morphineDose > 0 ? MorphineRecommendation.Nochange : MorphineRecommendation.Notstarted,
        forceClonidineStart: false,
        calculatedDose: assessmentData.morphineDose,
        note: 'First Assessment',
      },
      clonidineResult: {
        recommendation: assessmentData.clonidineDose > 0 ? 'No Change' : 'Not Started',
        recommendationCoded:
          assessmentData.clonidineDose > 0 ? ClonidineRecommendation.NoChange : ClonidineRecommendation.NoStarted,
        calculatedDose: assessmentData.clonidineDose,
        frequency: assessmentData.clonidineFrequency,
        isRestart: false,
        note: 'First Assessment',
      },
      comfortRecommendations: null,
    };
  }
};
