import React from 'react';
import cn from 'classnames';

type RadioProps = {
  label?: string;
  errorMessage?: string;
  isWrapped?: boolean;
};

export const RadioField: React.FC<RadioProps & React.ComponentPropsWithoutRef<'input'>> = ({
  label,
  errorMessage,
  isWrapped,
  ...props
}) => (
  <label className={cn(' group')}>
    <input type="radio" className="peer sr-only" {...props} />
    <div
      className={cn(
        'cursor-pointer gap-1 items-center relative transition-all duration-200',
        isWrapped
          ? 'inline-flex justify-center border-silver-400 dark:border-sky px-5 py-2 border rounded-md border-solid peer-checked:bg-mint/60 dark:peer-checked:bg-sea-serpent/50 dark:peer-checked:[&>span+span]:text-white-400 peer-checked:border-sea-serpent peer-checked:shadow-checked-radio'
          : 'flex',
        props.disabled
          ? 'peer-checked:[&>span]:before:border-white-100 peer-checked:[&>span]:bg-silver-400'
          : 'peer-checked:[&>span]:border-transparent peer-checked:[&>span]:before:border-sea-serpent',
      )}
    >
      <span
        className={cn(
          'block w-6 h-6 rounded-full border group-hover:bg-white-300 group-hover:border-gray-800 dark:group-hover:border-sky',
          'dark:group-hover:bg-gray-900 group-focus:border-silver-400 group-focus:border-[2px] before:content-[""] before:block before:w-[22px]',
          'before:h-[22px] before:top-0.5 before:left-0.5 before:rounded-full before:border-transparent before:border-[8px]',
          errorMessage
            ? 'border-red-400'
            : props.disabled
              ? 'border-gray-200 cursor-not-allowed pointer-events-none'
              : 'border-silver-400 dark:border-sky',
        )}
      />
      {label && (
        <span
          className={cn(
            'ml-3 text-sm font-medium leading-[140%]',
            props.disabled ? 'text-gray-800' : 'text-gray-500 dark:text-silver-400',
          )}
        >
          {label}
        </span>
      )}
    </div>
  </label>
);
