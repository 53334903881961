import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { toPng } from 'html-to-image';
import { Line } from 'react-chartjs-2';
import { badStabilityLevelLowerBound, changeDirection } from 'types/assessment';
import { LevelIndicator } from 'components/assessment-details/LevelIndicator';
import { Chart as ChartJS, CategoryScale, LineElement, LinearScale, PointElement } from 'chart.js';
import { DARK_MODE_CLASS, useTheme } from 'contexts/ThemeContextProvider';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

type StabilityChartProps = {
  levelDirection: string;
  stabilityLevels: number[];
  currentLevelIndex: number;
  onSelectResult: (result: any) => void;
};

const STYLE_POSITION = {
  S1: '27px',
  S2: '50px',
  S3: '70px',
  S4: '94px',
  S5: '114px',
  S6: '134px',
};

export const StabilityChart: React.FC<StabilityChartProps> = ({
  levelDirection,
  stabilityLevels,
  currentLevelIndex,
  onSelectResult,
}) => {
  const { theme } = useTheme();

  const [currentPointStyle, setCurrentPointStyle] = useState<String | HTMLImageElement>('circle');
  const levelRef = useRef(null);
  // Add the following state variables to indent the chart
  const supplementedLevels = useMemo(() => [stabilityLevels[0], ...stabilityLevels], [stabilityLevels]);
  const supplementedCurrentLevelIndex = useMemo(() => currentLevelIndex + 1, [currentLevelIndex]);

  const handleClick = (e: any, element: any) => {
    if (element.length) {
      const currentPoint = element[0];
      if (currentPoint.index - 1 !== currentLevelIndex) onSelectResult(currentPoint.index - 1);
    }
  };

  const options = {
    responsive: true,
    onClick: (e: any, element: any) => handleClick(e, element),
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
        border: {
          color: 'transparent',
        },
        ticks: {
          display: false,
        },
      },
      y: {
        min: -1,
        max: 8,
        reverse: true,
        border: {
          dash: [2, 5],
          color: 'transparent',
        },
        grid: {
          color: `${theme === DARK_MODE_CLASS ? '#3a4351' : '#e5e7eb'}`,
        },
        ticks: {
          stepSize: 1,
          color: `${theme === DARK_MODE_CLASS ? '#d1d5db' : '#4b5563'}`,
          font: {
            family: 'Montserrat',
            size: 11,
          },
          callback: (value: any) => {
            return value >= 1 && value <= 6 ? 'S' + value : null;
          },
        },
      },
    },
  };

  const htmlToImageConvert = useCallback(async () => {
    if (!levelRef.current) return;

    try {
      const dataUrl = await toPng(levelRef.current, { cacheBust: false });
      const img = document.createElement('img');
      img.src = dataUrl;
      img.width = 60;
      img.height = 50;
      setCurrentPointStyle(img);
    } catch (err) {
      console.error(err);
    }
  }, [levelRef]);

  useEffect(() => {
    htmlToImageConvert();
  }, [currentLevelIndex, htmlToImageConvert, theme]);

  const determineColor = (index: number) => {
    if (index === 0) {
      return 'transparent';
    } else if (index <= supplementedCurrentLevelIndex) {
      return stabilityLevels[currentLevelIndex] >= badStabilityLevelLowerBound ? '#df3b57' : '#8dc63f';
    } else {
      return '#d1d5db';
    }
  };

  const data = {
    labels: Array.from({ length: 12 }, (_, i) => i + 1),
    datasets: [
      {
        data: supplementedLevels,
        segment: {
          borderColor: (ctx: any) => {
            return ctx.p0DataIndex < supplementedCurrentLevelIndex && ctx.p1DataIndex <= supplementedCurrentLevelIndex
              ? stabilityLevels[currentLevelIndex] >= badStabilityLevelLowerBound
                ? '#df3b57'
                : '#8dc63f'
              : '#d1d5db';
          },
        },
        spanGaps: true,
        pointBackgroundColor: supplementedLevels.map((_, index) => determineColor(index)),
        pointBorderColor: supplementedLevels.map((_, index) => determineColor(index)),
        pointStyle: supplementedLevels.map((_, i) =>
          i === supplementedCurrentLevelIndex ? currentPointStyle : 'circle',
        ),
      },
    ],
  };

  const stabilityLevelInfo = useMemo(() => {
    switch (levelDirection) {
      case changeDirection.increase:
        return {
          text: 'Improves',
          description: 'Condition has improved.',
        };
      case changeDirection.decrease:
        return {
          text: 'Worsened',
          description: 'Condition has worsened.',
        };
      default:
        return {
          text: 'Stable',
          description: 'Condition is stable',
        };
    }
  }, [levelDirection]);

  const renderLevelDescription = () => {
    if (supplementedCurrentLevelIndex !== supplementedLevels.length - 1) return null;

    return (
      <div
        className={cn('flex flex-col pl-5 absolute w-[260px]')}
        style={{
          top: STYLE_POSITION[`S${supplementedLevels[supplementedLevels.length - 1]}` as keyof typeof STYLE_POSITION],
          left: `${supplementedLevels.length * 8.3}%`,
        }}
      >
        <p className={cn('text-sm font-medium leading-[140%] text-gray-900 dark:text-white-400')}>
          {stabilityLevelInfo.text}
        </p>
        {stabilityLevelInfo.description && (
          <p className="text-sky dark:text-silver-400 text-xs font-medium leading-[17px]">
            {stabilityLevelInfo.description}
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="relative w-full h-[190px]">
        <h2 className="absolute top-0 text-gray-900 dark:text-white-400 text-base font-semibold leading-7">
          Stability Level
        </h2>
        <Line options={options} data={data} width={'100%'} />
        {renderLevelDescription()}
      </div>

      <div className="sr-only">
        <LevelIndicator
          ref={levelRef}
          level={stabilityLevels[currentLevelIndex]}
          levelDirection={levelDirection}
          isLast={supplementedCurrentLevelIndex === supplementedLevels.length - 1}
        />
      </div>
    </div>
  );
};
