import React from 'react';

import cn from 'classnames';

import { BaseButton } from 'components/ui/buttons';
import { EditIcon, WarningIcon } from 'assets/icons';
import { Narcotics } from 'record/onboarding-result.record';
import { AssessmentResultType, Drugs } from 'types/assessment';
import { FREQUENCY_OPTIONS } from 'record/assessment-data.record';
import { InputField, SelectField } from 'components/ui/form-fields';
import { PatientMedicalRecord } from 'record/patient-medical.record';
import { EditSummaryInfoModal } from 'components/assessment-details/EditSummaryInfoModal';
import { DosesDataType } from 'pages/AssessmentDetails';

type DoseFormProps = {
  onCancelClick: () => void;
  onSubmit: (dosesData: DosesDataType) => void;
  refetchData: () => void;
  patientData: PatientMedicalRecord;
  lastResult: AssessmentResultType;
};

const narcoticsMap: { [key: string]: string } = {
  [Narcotics.Methadone]: 'Methadone',
  [Narcotics.Fentanyl]: 'Fentanyl',
  [Narcotics.Heroin]: 'Heroin',
  [Narcotics.Benzodiazepines]: 'Benzodiazepines',
  [Narcotics['Prescription pain pills']]: 'Prescription pain pills',
  [Narcotics['Bupernophine (Subutex/Suboxone)']]: 'Bupernophine (Subutex/Suboxone)',
};

export const DoseForm: React.FC<DoseFormProps> = ({
  onCancelClick,
  patientData,
  lastResult,
  onSubmit,
  refetchData,
}) => {
  const [isShowEditForm, setShowEditForm] = React.useState(false);
  const [currentMorphineDose, setCurrentMorphineDose] = React.useState<string>('');
  const [currentClonidineDose, setCurrentClonidineDose] = React.useState<string>('');
  const [currentClonidineFrequency, setCurrentClonidineFrequency] = React.useState<number | null>(null);

  const morphineDoseInfo = lastResult.assessment_result_drug.find((drug) => drug.drug_id === Drugs.Morphine);
  const clonidineDoseInfo = lastResult.assessment_result_drug.find((drug) => drug.drug_id === Drugs.Clonidine);

  const narcoticsList = patientData.narcotic_ids?.map((id) => narcoticsMap[id]);

  const renderWarning = () => {
    return (
      <div className="mt-2 p-3 gap-2 flex border bg-red-100 rounded-lg border-solid border-red-200">
        <WarningIcon className="text-red-400" />
        <div>
          <h3 className="text-gray-900 text-sm font-semibold leading-[140%]">This dosage is not what we expected.</h3>
          <p className="text-gray-500 text-xs font-medium leading-[17px]">
            Accurate dosage is critical for effective recommendations.
            <span className="block">Please double check and confirm dosage.</span>
          </p>
        </div>
      </div>
    );
  };

  const isDisabled = () => {
    return currentMorphineDose === '' && currentClonidineDose === '';
  };

  return (
    <form className="w-full flex flex-col mt-4 bg-white-400 dark:bg-gray-900 text-left">
      <div className="flex justify-between items-start border border-silver-400 p-3 rounded-lg border-solid">
        <div className="w-full max-w-[80%] text-xs leading-[17px]">
          {narcoticsList?.length && (
            <div className="flex mb-1.5">
              <p className="text-gray-500 font-medium min-w-[100px] w-[35%]">Exposure Meds:</p>
              <ul className="text-gray-900 font-semibold ">
                {narcoticsList?.map((narcotic) => <li key={narcotic}>{narcotic}</li>)}
              </ul>
            </div>
          )}
          <div className="flex  mb-1.5">
            <p className="text-gray-500 font-medium min-w-[100px] w-[35%]">Hour of life:</p>
            <p className="text-gray-900 font-semibold">{patientData.getAge()}</p>
          </div>
          <div className="flex">
            <p className="text-gray-500 font-medium min-w-[100px] w-[35%]">Birth Weight:</p>
            <p className="text-gray-900 font-semibold">{patientData.birth_weight_kg}</p>
          </div>
        </div>
        <BaseButton
          className="!rounded-full"
          scheme="secondary"
          icon={EditIcon}
          isIconOnLeftSide
          iconProps={{ className: 'text-sky' }}
          onClick={() => setShowEditForm(true)}
        >
          Edit
        </BaseButton>
      </div>

      <div className="mt-5">
        <InputField
          className={cn({
            '!text-red-400':
              morphineDoseInfo && morphineDoseInfo.recommendation_dosage_mcg < Number(currentMorphineDose),
          })}
          name="morphine"
          label="Current Morphine Dose"
          type="number"
          placeholder="Dose"
          suffix="mg/kg"
          value={currentMorphineDose?.toString()}
          onChange={(event) => {
            const dose = event.target.value;

            if (Number(dose) >= 0) {
              setCurrentMorphineDose(dose);
            }
          }}
        />
        {morphineDoseInfo &&
          morphineDoseInfo.recommendation_dosage_mcg < Number(currentMorphineDose) &&
          renderWarning()}
      </div>

      <div className="mt-5">
        <InputField
          className={cn('mb-5', {
            '!text-red-400':
              clonidineDoseInfo && clonidineDoseInfo.recommendation_dosage_mcg < Number(currentMorphineDose),
          })}
          name="clonidine"
          label="Current Clonidine Dose"
          type="number"
          placeholder="Dose"
          suffix="mcg/kg"
          value={currentClonidineDose}
          onChange={(event) => {
            const dose = event.target.value;

            if (Number(dose) >= 0) {
              setCurrentClonidineDose(dose);
            }
          }}
        />

        {clonidineDoseInfo &&
          clonidineDoseInfo.recommendation_dosage_mcg < Number(currentClonidineDose) &&
          renderWarning()}

        <SelectField
          label="Current Clonidine Frequency"
          options={FREQUENCY_OPTIONS}
          schema="secondary"
          value={currentClonidineFrequency || null}
          onChange={(value) => {
            if (typeof value === 'string' || typeof value === 'number') {
              setCurrentClonidineFrequency(value as number);
            }
          }}
        />
      </div>

      <div className="flex w-full justify-end mt-5 gap-4">
        <BaseButton scheme="secondary" onClick={onCancelClick}>
          Cancel
        </BaseButton>
        <BaseButton
          onClick={() =>
            onSubmit({
              currentClonidineDose: Number(currentClonidineDose),
              currentMorphineDose: Number(currentMorphineDose),
              currentClonidineFrequency: Number(currentClonidineFrequency),
            })
          }
          scheme="primary"
          disabled={isDisabled()}
        >
          Confirm
        </BaseButton>
      </div>

      {isShowEditForm && (
        <EditSummaryInfoModal
          patientData={patientData}
          onCloseClick={() => setShowEditForm(false)}
          refetch={refetchData}
        />
      )}
    </form>
  );
};
