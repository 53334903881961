const KG_TO_LBS = 2.20462;
const OZ_IN_LB = 16;

export const convertKgToLbsOz = (kg: number) => {
  const totalLbs = kg * KG_TO_LBS;
  const lbs = Math.floor(totalLbs);
  const oz = (totalLbs - lbs) * OZ_IN_LB;
  return { lbs, oz };
};

export const convertLbsOzToKg = (lbs: number, oz: number) => {
  const totalLbs = lbs + oz / OZ_IN_LB;
  return totalLbs / KG_TO_LBS;
};
