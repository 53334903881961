import React, { useState } from 'react';

import cn from 'classnames';

import { RemoveCircleIcon } from 'assets/icons';
import { BaseButton } from 'components/ui/buttons';
import { Narcotics } from 'record/onboarding-result.record';
import { CheckboxField, InputField } from 'components/ui/form-fields';
import { QuestionInfo } from 'components/assessment/common/QuestionInfo';
import { SubstanceModal } from 'components/assessment/steps/SubstanceModal';
import { SubstanceCheckbox } from 'components/assessment/common/SubstanceCheckbox';
import { FormikProps } from 'formik';
import { OnboardingValidateSchemaType } from 'components/assessment/OnboardingQuestionnaire';

type SubstanceExposureStepProps = {
  narcoticIds: string[] | null;
  otherNarcotic: string[] | null;
  opioidsPostDelivery: boolean;
  onChangeNarcotics: (narcotics: string[]) => void;
  onChangeOpioidsPostDelivery: (otherNarcotic: boolean) => void;
  nextStep?: () => void;
  formik: FormikProps<OnboardingValidateSchemaType>;
};
export const SubstanceExposureStep: React.FC<SubstanceExposureStepProps> = ({
  narcoticIds,
  otherNarcotic,
  opioidsPostDelivery,
  onChangeNarcotics,
  onChangeOpioidsPostDelivery,
  nextStep,
  formik,
}) => {
  const [isShownMessageModal, setIsShownMessageModal] = useState(false);
  const [isOtherNarcotic, setOtherNarcotic] = useState<boolean>(otherNarcotic ? otherNarcotic?.length > 0 : false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const narcoticId = event.target.value;
    if (narcoticIds?.includes(narcoticId)) {
      onChangeNarcotics(narcoticIds.filter((id) => id !== narcoticId));
    } else {
      if (opioidsPostDelivery) {
        onChangeOpioidsPostDelivery(false);
      }
      onChangeNarcotics([...(narcoticIds || []), narcoticId]);
    }
  };

  const handleSetOtherNarcotic = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (opioidsPostDelivery) {
      onChangeOpioidsPostDelivery(false);
    }
    formik.handleChange('otherNarcotic')(event);
  };

  const handleNoOpiateExposure = () => {
    onChangeNarcotics([]);
    formik.setFieldValue('otherNarcotic', '');
    setOtherNarcotic(false);
    setIsShownMessageModal(false);
    nextStep && nextStep();
  };

  const handleChangesOtherNarcotic = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    formik.setFieldValue('otherNarcotic', isChecked ? '' : null);
    setOtherNarcotic(event.target.checked);
  };

  const substanceList = [
    Narcotics['Bupernophine (Subutex/Suboxone)'],
    Narcotics.Methadone,
    Narcotics['Prescription pain pills'],
    Narcotics.Benzodiazepines,
    Narcotics.Heroin,
    Narcotics.Fentanyl,
  ];

  const getKeyOfNarcoticsByValue = (value: string) => {
    const key = Object.keys(Narcotics).find((key) => Narcotics[key as keyof typeof Narcotics] === value);
    return key ? key : '';
  };

  const cancelChangesExposedOpioid = () => {
    onChangeOpioidsPostDelivery(false);
    setIsShownMessageModal(false);
  };

  return (
    <div>
      <QuestionInfo title="Please select each known substance the patient was exposed to in utero" />
      <div>
        {substanceList.map((substance) => {
          return (
            <SubstanceCheckbox
              key={substance}
              narcoticId={substance}
              narcoticName={getKeyOfNarcoticsByValue(substance)}
              substances={narcoticIds}
              handleCheckboxChange={handleCheckboxChange}
            />
          );
        })}

        <div
          className={cn(
            'flex h-[70px] justify-between items-center gap-4 !py-[10px]',
            'mb-3 bg-white-400/80 dark:bg-gray-900 pl-5 pr-3 py-[22px] rounded-xl border dark:hover:bg-white-400/10 dark:hover:border-white-400/20',
            isOtherNarcotic
              ? 'border-gray-800 bg-white-400 dark:bg-white-400/20 dark:border-white-400/20'
              : 'border-transparent',
          )}
        >
          <div className="w-1/2">
            <CheckboxField
              label="Other short-acting opiates"
              name="substance"
              checked={isOtherNarcotic}
              onChange={handleChangesOtherNarcotic}
            />
          </div>
          <div className="w-1/2">
            <InputField
              className="!h-8"
              name="otherNarcotic"
              value={formik.values.otherNarcotic || ''}
              disabled={!isOtherNarcotic}
              onChange={handleSetOtherNarcotic}
              onBlur={formik.handleBlur}
              placeholder="Input Name"
              errorMessage={
                formik.touched.otherNarcotic && formik.errors.otherNarcotic ? formik.errors.otherNarcotic : undefined
              }
            />
          </div>
        </div>

        <BaseButton
          className={cn(
            'w-full !justify-end py-[20px] rounded-xl border border-solid hover:border-gray-800 hover:bg-white-300',
            narcoticIds?.length === 0 && !isOtherNarcotic
              ? 'border-gray-800 dark:border-white-400/20 bg-white-400 dark:bg-white-400/20'
              : 'bg-white-400/80 dark:bg-gray-900 border-transparent',
          )}
          icon={RemoveCircleIcon}
          iconProps={{
            className: cn({ 'fill-sea-serpent text-white-400': narcoticIds?.length === 0 && !isOtherNarcotic }),
          }}
          isIconOnLeftSide
          onClick={() => setIsShownMessageModal(true)}
          scheme="secondary"
        >
          No opiate exposure
        </BaseButton>
      </div>

      {isShownMessageModal && (
        <SubstanceModal
          onSuccessClick={handleNoOpiateExposure}
          onCloseClick={() => setIsShownMessageModal(false)}
          onCancelClick={cancelChangesExposedOpioid}
          onChangesExposedOpioid={onChangeOpioidsPostDelivery}
          isExposedOpioid={opioidsPostDelivery}
        />
      )}
    </div>
  );
};
