import cn from 'classnames';
import React, { HTMLAttributes } from 'react';
import { disabledClassName } from './BaseButton';

type IconButtonScheme = 'primary' | 'secondary' | 'transparent' | 'offline';
type IconButtonProps = {
  icon?: React.ComponentType<HTMLAttributes<SVGElement>>;
  iconProps?: React.HTMLAttributes<SVGAElement>;
  size?: IconButtonSize;
  scheme?: IconButtonScheme;
  onClick?: () => void;
  className?: string;
};
type IconButtonSize = 'sm' | 'md';

const iconButtonSchemeClassMap: Record<IconButtonScheme, string> = {
  primary:
    ' bg-white-300 dark:bg-gray-500 rounded-full border border-transparent hover:border-gray-800 dark:hover:border-sky border-solid active:bg-gray-200 dark:active:bg-sky dark:active:border-sky focus:shadow-focus-icon-button dark:focus:shadow-focus-icon-button active:bg-sea-serpent dark:active:bg-sea-serpent/50',
  secondary:
    'flex items-start  gap-2.5 border border-gray-200 dark:border-gray-500 rounded-lg border-solid hover:border-gray-800 dark:hover:border-sky active:bg-gray-200 dark:active:bg-sky focus:shadow-focus-icon-button dark:focus:shadow-focus-icon-button active:bg-sea-serpent dark:active:bg-sea-serpent/50',
  transparent:
    'flex items-start gap-2.5 border border-transparent dark:border-gray-500 rounded-lg border-solid hover:border-gray-800 dark:hover:border-sky active:bg-gray-200 dark:active:bg-sky focus:shadow-focus-icon-button dark:focus:shadow-focus-icon-button active:bg-sea-serpent dark:active:bg-sea-serpent/50',
  offline: 'bg-red-400 rounded-full border border-transparent',
};

const iconButtonSizeClassMap: Record<IconButtonSize, string> = {
  sm: 'w-[32px] h-[32px] p-1',
  md: 'w-[42px] h-[42px] p-3',
};

export const IconButton: React.FC<IconButtonProps & React.ComponentPropsWithoutRef<'button'>> = ({
  className,
  iconProps,
  scheme,
  size,
  icon: Icon,
  ...props
}) => {
  const typeClass = iconButtonSchemeClassMap[scheme || 'primary'];
  const sizeClass = iconButtonSizeClassMap[size || 'md'];

  return (
    <button
      className={cn(
        className,
        sizeClass,
        typeClass,
        disabledClassName,
        'flex items-center justify-center border-solid transition ease-in-out',
      )}
      {...props}
    >
      {Icon ? <Icon {...iconProps} className={cn('w-[18px]', iconProps?.className)} /> : null}
    </button>
  );
};
