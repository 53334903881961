import React from 'react';
import dayjs from 'dayjs';

import { InfoIcon } from 'assets/icons';
import { dateTimeFormats } from 'types/date-time-formats';

type StopAssessmentDateProps = {
  date: string | null;
};

export const StopAssessmentDate: React.FC<StopAssessmentDateProps> = ({ date }) => {
  return (
    <div className="flex items-center gap-1 mb-4 text-gray-500 dark:text-silver-400 text-xs leading-[17px]">
      <span className="text-red-400">
        <InfoIcon />
      </span>
      Assessments were stopped at
      <span className="text-gray-900 dark:text-white-400 font-semibold">
        {dayjs(date).format(dateTimeFormats.stopAssessment)}
      </span>
    </div>
  );
};
