import { MorphineRecommendation } from './morphine-algorithm';
import { GISymptoms } from './types/gi-symptoms';

export enum ClonidineRecommendation {
  StartQ3,
  StartQ6,
  Increase,
  Decrease,
  Discontinue,
  Discharge,
  NoChange,
  NoStarted
}

export type InuteroOpiate = {
  isTakingBuprenorphine: boolean;
  isTakingMethadone: boolean;
  isTakingOther: boolean;
};

export type GetClonidineRecommendationRequest = {
  clonidineDose: number;
  clonidineFrequency: number;
  morphineRecommendationResult: { recommendationCoded: MorphineRecommendation; forceClonidineStart: boolean };
  morphineDose: number;
  priorAssessmentGISymptoms: GISymptoms;
  currentAssessmentGISymptoms: GISymptoms;
  hasEverTakenClonidine: boolean;
  hasEverTakenMorphine: boolean;
  inuteroOpiates: InuteroOpiate;
  hoursSinceBirth: number;
  hoursSinceLastClonidineChange: number | null; // Hours since last change in clonidine dose
  hoursSinceLastMorphineChange: number | null; // Hours since last change in morphine dose
  isIncreaseComparedToSixHoursAgo: boolean; // Has the dose increased compared to six hours ago
  isLastAssessmentRecommendationAnIncrease: boolean; // Is the last assessment recommendation an increase
  hasTrialedOffClonidine: boolean; // Has the patient trialed off clonidine
};

type GetClonidineRecommendationRequestComputed = GetClonidineRecommendationRequest & {
  isTakingClonidine: boolean;
  isTakingMorphine: boolean;
  hasConsistentPoorGI: boolean;
};

export type ClonidineRecommendationResult = {
  calculatedDose: number;
  frequency: number;
  recommendation: string;
  recommendationCoded: ClonidineRecommendation;
  note: string;
  isRestart: boolean;
};

const checkStartOrIncrease = (request: GetClonidineRecommendationRequestComputed): ClonidineRecommendationResult => {
  if (request.isTakingClonidine) return checkTakingClonidinePath(request);
  return checkNotTakingClonidinePath(request);
};

const MORPHINE_ALGO_RESULTS_AFFECTING_CLONIDINE = [MorphineRecommendation.Start, MorphineRecommendation.Increase];
const WAIT_HOURS_BEFORE_DISCHARGE = 24;
const WAIT_HOURS_BEFORE_DISCONTINUE = 18;
const WAIT_HOURS_BEFORE_DECREASE_DISCONTINUE_DISCHARGE = 6;
const MORPHINE_DOSE_MAX_FOR_CLONIDINE_DISCONTINUE = 0.02;
const CLONIDINE_MAX_DOSE = 2.0;
const WAIT_HOURS_OBSERVATION_BEFORE_DISCHARGE_METHADONE = 120; // 5 days
const WAIT_HOURS_OBSERVATION_BEFORE_DISCHARGE_BUPRENORPHINE = 96; // 4 days
const WAIT_HOURS_OBSERVATION_BEFORE_DISCHARGE_OTHER = 72; // 3 days

const checkMorphineAlgoAffectingClonidine = (
  forceClonidine: boolean,
  morphineResult: MorphineRecommendation,
): boolean => forceClonidine || MORPHINE_ALGO_RESULTS_AFFECTING_CLONIDINE.includes(morphineResult);

export const checkForConsistentPoorGI = (priorGISymptoms: GISymptoms, currentGISymptoms: GISymptoms): boolean => {
  return (
    (priorGISymptoms.poorFeeding && currentGISymptoms.poorFeeding) ||
    (priorGISymptoms.poorStooling && currentGISymptoms.poorStooling)
  );
};

const checkTakingClonidinePath = (
  request: GetClonidineRecommendationRequestComputed,
): ClonidineRecommendationResult => {
  // Increase
  if (
    checkMorphineAlgoAffectingClonidine(
      request.morphineRecommendationResult.forceClonidineStart,
      request.morphineRecommendationResult.recommendationCoded,
    )
  )
    return checkIncrease(
      request.isIncreaseComparedToSixHoursAgo,
      request.isLastAssessmentRecommendationAnIncrease,
      request.hasEverTakenClonidine,
      request.clonidineDose,
      request.clonidineFrequency,
    );
  if (request.hasConsistentPoorGI)
    return checkIncrease(
      request.isIncreaseComparedToSixHoursAgo,
      request.isLastAssessmentRecommendationAnIncrease,
      request.hasEverTakenClonidine,
      request.clonidineDose,
      request.clonidineFrequency,
    );
  // Decrease/Discontinue/Discharge
  return checkDecreaseDiscontinueDischarge(request);
};

const checkDecreaseDiscontinueDischarge = (
  request: GetClonidineRecommendationRequestComputed,
): ClonidineRecommendationResult => {
  // Wait longer
  if (request.hoursSinceLastClonidineChange !== null && request.hoursSinceLastClonidineChange < WAIT_HOURS_BEFORE_DECREASE_DISCONTINUE_DISCHARGE)
    return handleNoChange(
      `checkDecreaseDiscontinueDischarge -> Too soon. Did not continue until wait period elapses.`,
      request.clonidineDose,
      request.clonidineFrequency,
    );

  // Discharge
  if (request.clonidineDose === 1.0 && request.clonidineFrequency === 6)
    return checkDischarge(
      request.isTakingMorphine,
      request.clonidineDose,
      request.clonidineFrequency,
      request.hoursSinceLastClonidineChange,
      request.hoursSinceLastMorphineChange,
    );
  // Decrease
  if (request.clonidineDose > 1.0 && request.clonidineFrequency === 3)
    return handleDecrease(
      `checkDecreaseDiscontinueDischarge -> Met criteria to decrease dosage.`,
      request.clonidineDose,
    );
  // Discontinue
  if (request.clonidineDose <= 1.0 && request.clonidineFrequency === 3)
    if (request.hasTrialedOffClonidine)
      return handleStart(
        `checkDecreaseDiscontinueDischarge -> Due to past trialed off of Clonidine: Clonidine dose at 1.0mcg/kg q3, decreasing to 1.0mcg/kg q6`,
        request.hasEverTakenClonidine,
        6,
      );
    else
      return checkDiscontinue(
        request.hoursSinceLastClonidineChange,
        request.morphineDose,
        request.clonidineDose,
        request.clonidineFrequency,
      );

  return handleNoChange(
    `checkDecreaseDiscontinueDischarge -> No change.`,
    request.clonidineDose,
    request.clonidineFrequency,
  );
};

const checkDiscontinue = (
  hoursSinceLastClonidineChange: number | null,
  morphineDose: number,
  clonidineDose: number,
  clonidineFrequency: number,
): ClonidineRecommendationResult => {
  if (hoursSinceLastClonidineChange !== null && hoursSinceLastClonidineChange < WAIT_HOURS_BEFORE_DISCONTINUE)
    return handleNoChange(
      `checkDiscontinue -> Waiting ${WAIT_HOURS_BEFORE_DISCONTINUE} hours before discontinue. Hours since last change: ${hoursSinceLastClonidineChange}`,
      clonidineDose,
      clonidineFrequency,
    );
  if (morphineDose > MORPHINE_DOSE_MAX_FOR_CLONIDINE_DISCONTINUE)
    return handleNoChange(
      `checkDiscontinue -> Waiting for Morphine dose to drop below ${MORPHINE_DOSE_MAX_FOR_CLONIDINE_DISCONTINUE}.`,
      clonidineDose,
      clonidineFrequency,
    );
  return handleDiscontinue('discontinue -> Met criteria to discontinue.');
};

const checkIncrease = (
  isIncreaseComparedToSixHoursAgo: boolean,
  isLastAssessmentRecommendationAnIncrease: boolean,
  hasEverTakenClonidine: boolean,
  clonidineDose: number,
  clonidineFrequency: number,
): ClonidineRecommendationResult => {
  if (isIncreaseComparedToSixHoursAgo)
    return handleNoChange(
      `checkIncrease -> Too soon. Did not increase due to recent increase compared to 6 hours ago.`,
      clonidineDose,
      clonidineFrequency,
    );
  if (isLastAssessmentRecommendationAnIncrease)
    return handleNoChange(
      `checkIncrease -> Too soon. Did not increase due to last assessment recommendation being an increase.`,
      clonidineDose,
      clonidineFrequency,
    );
  if (clonidineDose === 1.0 && clonidineFrequency === 6)
    return handleStart(
      `checkIncrease -> Clonidine dose at 1.0mcg/kg q6, increasing to 1.0mcg/kg q3`,
      hasEverTakenClonidine,
      3,
    );
  if (clonidineDose >= CLONIDINE_MAX_DOSE)
    return handleNoChange(`checkIncrease -> Maximum dose reached.`, clonidineDose, clonidineFrequency);
  return handleIncrease(`checkIncrease -> Met criteria to increase dosage.`, clonidineDose);
};

const checkNotTakingClonidinePath = (
  request: GetClonidineRecommendationRequestComputed,
): ClonidineRecommendationResult => {
  // Checking for Start
  const checkStartResult = checkStart(
    request.hasEverTakenClonidine,
    request.morphineRecommendationResult.recommendationCoded,
    request.morphineRecommendationResult.forceClonidineStart,
  );
  if (checkStartResult) return checkStartResult;

  // Check Restart
  if (request.hasEverTakenClonidine) {
    const checkRestartResult = checkRestart(request.hasEverTakenClonidine, request.hasConsistentPoorGI);
    if (checkRestartResult) return checkRestartResult;
    // Restart not needed, check Discharge
    return checkDischarge(
      request.isTakingMorphine,
      request.clonidineDose,
      request.clonidineFrequency,
      request.hoursSinceLastClonidineChange,
      request.hoursSinceLastMorphineChange,
    );
  }

  // Check Discharge
  if (request.hasEverTakenMorphine)
    return checkDischarge(
      request.isTakingMorphine,
      request.clonidineDose,
      request.clonidineFrequency,
      request.hoursSinceLastClonidineChange,
      request.hoursSinceLastMorphineChange,
    );

  // Check Observation Discharge
  return checkObservationDischarge(
    request.inuteroOpiates,
    request.hoursSinceBirth,
    request.clonidineDose,
    request.clonidineFrequency,
  );
};

const checkStart = (
  hasEverTakenClonidine: boolean,
  morphineRecommendationResult: MorphineRecommendation,
  forceClonidineStart: boolean,
): ClonidineRecommendationResult | null => {
  if (checkMorphineAlgoAffectingClonidine(forceClonidineStart, morphineRecommendationResult))
    return handleStart(
      `checkStart -> Start due to Morphine recommendation '${morphineRecommendationResult}'.`,
      hasEverTakenClonidine,
      3,
    );
  if (forceClonidineStart) return handleStart('checkStart -> Force start.', hasEverTakenClonidine, 3);
  return null; // No start needed
};

const checkRestart = (
  hasEverTakenClonidine: boolean,
  hasConsistentPoorGI: boolean,
): ClonidineRecommendationResult | null => {
  if (hasConsistentPoorGI)
    return handleStart(`checkRestart -> Restarting Clonidine after having poor GI symptoms.`, hasEverTakenClonidine, 3);
  return null; // No restart needed
};

const checkDischarge = (
  isTakingMorphine: boolean,
  clonidineDose: number,
  clonidineFrequency: number,
  hoursSinceLastClonidineChange: number | null,
  hoursSinceLastMorphineChange: number | null,
): ClonidineRecommendationResult => {
  if (!isTakingMorphine)
    if (
      (hoursSinceLastClonidineChange === null || hoursSinceLastClonidineChange >= WAIT_HOURS_BEFORE_DISCHARGE) &&
      (hoursSinceLastMorphineChange === null || hoursSinceLastMorphineChange >= WAIT_HOURS_BEFORE_DISCHARGE)
    )
      return handleDischarge(
        `checkDischarge -> Waited ${WAIT_HOURS_BEFORE_DISCHARGE} hours before discharge. Hours since last change: Clonidine=${hoursSinceLastClonidineChange} Morphine=${hoursSinceLastMorphineChange}`,
      );
    else
      return handleNoChange(
        `checkDischarge -> Waiting ${WAIT_HOURS_BEFORE_DISCHARGE} hours before discharge. Hours since last change: Clonidine=${hoursSinceLastClonidineChange} Morphine=${hoursSinceLastMorphineChange}`,
        clonidineDose,
        clonidineFrequency,
      );
  return handleNoChange(
    'checkDischarge -> Still taking Morphine. Waiting for Morphine to discontinue.',
    clonidineDose,
    clonidineFrequency,
  );
};

const checkObservationDischarge = (
  inuteroOpiates: InuteroOpiate,
  hoursSinceBirth: number,
  clonidineDose: number,
  clonidineFrequency: number,
): ClonidineRecommendationResult => {
  if (inuteroOpiates.isTakingMethadone)
    return checkObservationDischargeOrWait(
      hoursSinceBirth,
      WAIT_HOURS_OBSERVATION_BEFORE_DISCHARGE_METHADONE,
      'Methadone',
      clonidineDose,
      clonidineFrequency,
    );
  else if (inuteroOpiates.isTakingBuprenorphine)
    return checkObservationDischargeOrWait(
      hoursSinceBirth,
      WAIT_HOURS_OBSERVATION_BEFORE_DISCHARGE_BUPRENORPHINE,
      'Buprenorphine',
      clonidineDose,
      clonidineFrequency,
    );
  else
    return checkObservationDischargeOrWait(
      hoursSinceBirth,
      WAIT_HOURS_OBSERVATION_BEFORE_DISCHARGE_OTHER,
      'Other',
      clonidineDose,
      clonidineFrequency,
    );
};

const checkObservationDischargeOrWait = (
  hoursSinceBirth: number,
  waitInHours: number,
  inuteroOpiateDrugName: string,
  clonidineDose: number,
  clonidineFrequency: number,
): ClonidineRecommendationResult => {
  if (hoursSinceBirth >= waitInHours)
    return handleDischarge(
      `checkObservationDischarge -> Discharging after waiting ${waitInHours} hours after birth. Drug: ${inuteroOpiateDrugName}. Hours since birth: ${hoursSinceBirth}.`,
    );
  else
    return handleNoChange(
      `checkObservationDischarge -> Waiting for ${waitInHours} hours since birth. Drug: ${inuteroOpiateDrugName}. Hours since birth: ${hoursSinceBirth}.`,
      clonidineDose,
      clonidineFrequency,
    );
};

const handleIncrease = (note: string, clonidineDose: number): ClonidineRecommendationResult => {
  return {
    calculatedDose: clonidineDose + 0.5,
    frequency: 3,
    recommendation: '+0.5mcg/kg q3',
    recommendationCoded: ClonidineRecommendation.Increase,
    isRestart: false,
    note,
  };
};

const handleDiscontinue = (note: string): ClonidineRecommendationResult => {
  return {
    calculatedDose: 0,
    frequency: 0,
    recommendation: 'Discontinue',
    recommendationCoded: ClonidineRecommendation.Discontinue,
    isRestart: false,
    note,
  };
};

const handleDecrease = (note: string, clonidineDose: number): ClonidineRecommendationResult => {
  return {
    calculatedDose: clonidineDose - 0.5,
    frequency: 3,
    recommendation: '-0.5mcg/kg q3',
    recommendationCoded: ClonidineRecommendation.Decrease,
    isRestart: false,
    note,
  };
};

const handleDischarge = (note: string): ClonidineRecommendationResult => {
  return {
    calculatedDose: 0.0,
    frequency: 0,
    recommendation: 'Discharge',
    recommendationCoded: ClonidineRecommendation.Discharge,
    isRestart: false,
    note,
  };
};

const handleNoChange = (
  note: string,
  clonidineDose: number,
  clonidineFrequency: number,
): ClonidineRecommendationResult => {
  return {
    calculatedDose: clonidineDose,
    frequency: clonidineFrequency,
    recommendation: 'No Change',
    recommendationCoded: ClonidineRecommendation.NoChange,
    isRestart: false,
    note,
  };
};

const handleStart = (
  note: string,
  hasEverTakenClonidine: boolean,
  frequency: number,
): ClonidineRecommendationResult => {
  return {
    calculatedDose: 1.0,
    frequency,
    recommendation: `Start 1.0mcg/kg q${frequency}`,
    recommendationCoded: frequency === 6 ? ClonidineRecommendation.StartQ6 : ClonidineRecommendation.StartQ3,
    isRestart: hasEverTakenClonidine,
    note,
  };
};

const fixFloatPrecision = (value: number): number => {
  return parseFloat(value.toPrecision(12));
};

export const getClonidineRecommendation = (
  request: GetClonidineRecommendationRequest,
): ClonidineRecommendationResult => {
  // Compute request
  const computedRequest = request as GetClonidineRecommendationRequestComputed;
  computedRequest.clonidineDose = fixFloatPrecision(request.clonidineDose);
  computedRequest.morphineDose = fixFloatPrecision(request.morphineDose);
  computedRequest.isTakingClonidine = request.clonidineDose > 0;
  if (computedRequest.isTakingClonidine) computedRequest.hasEverTakenClonidine = true;
  computedRequest.isTakingMorphine = request.morphineDose > 0;
  if (computedRequest.isTakingMorphine) computedRequest.hasEverTakenMorphine = true;
  computedRequest.hasConsistentPoorGI = checkForConsistentPoorGI(
    request.priorAssessmentGISymptoms,
    request.currentAssessmentGISymptoms,
  );

  return checkStartOrIncrease(computedRequest);
};
