import React from 'react';

import { PrintIcon } from 'assets/icons';
import { BaseModal } from 'components/ui';
import { BaseButton } from 'components/ui/buttons';
import qrImage from 'assets/images/demo_qr_big.png';

type QRModalProps = {
  onCancelClick: () => void;
  onPrintClick: () => void;
  refId: number;
  age: string;
};

export const QRModal: React.FC<QRModalProps> = ({ onCancelClick, onPrintClick, refId, age }) => {
  return (
    <BaseModal isShownCloseButton onCloseClick={onCancelClick}>
      <div className="flex flex-col mt-4 items-center w-[460px] text-center">
        <div className="mb-4 p-4 w-[190px] h-[190px] bg-demo-qr-wrapper bg-no-repeat bg-contain">
          <img className="w-full" src={qrImage} alt="QR Code" />
        </div>

        <h2 className="text-gray-900 text-[28px] font-semibold leading-[normal]">ID: {refId}</h2>
        <p className="text-gray-500 dark:text-silver-400 text-sm font-medium leading-[140%]">{age}</p>

        <div className="mt-8 flex flex-col items-center gap-4">
          <BaseButton scheme="cta-primary" size="md" icon={PrintIcon} isIconOnLeftSide onClick={onPrintClick}>
            Print QR code
          </BaseButton>
          <BaseButton scheme="transparent" onClick={onCancelClick}>
            Cancel
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
};
